import {
  ADD_CONTACT
} from '../constants/contacts'

export function addContact (contact, callback) {
  return {
    type: ADD_CONTACT,
    contact,
    callback
  }
}
