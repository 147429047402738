const jdp = require('jsondiffpatch')

const diffpatcher = jdp.create()
const shallowClonePatchFilter = (context) => {
  if (context.nested) {
    if (context.delta._t === 'a') {
      // Array
      context.left = [
        ...context.left
      ]

      if (context.parent) {
        context.parent.left[context.childName] = context.left
      }
    } else if (!context.delta._t) {
      // Object
      context.result = context.left = {
        ...context.left
      }

      if (context.parent) {
        context.parent.left[context.childName] = context.left
      }
    }
  }
}

shallowClonePatchFilter.filterName = 'shallowClone'
diffpatcher.processor.pipes.patch.before('objects', shallowClonePatchFilter)

export default diffpatcher
