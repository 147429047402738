import { makeStyles } from '@material-ui/core/styles'

import Tooltip from '@material-ui/core/Tooltip'

import React from 'react'

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14)
  }
}))

const StyledTooltip = props => {
  const classes = useStyles()
  return (
    <Tooltip
      {...props}
      classes={props.classes ? {
        ...props.classes,
        tooltip: classes.tooltip
      } : {
        tooltip: classes.tooltip
      }}
    >
      {props.children}
    </Tooltip>
  )
}

StyledTooltip.propTypes = Tooltip.propTypes

export default StyledTooltip
