import PropTypes from 'prop-types'
import React, { useCallback, useRef } from 'react'

import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'

import MDCButton from '../../../MDCComponents/Button'
import MDCSelect from '../../../MDCComponents/Select'

import Tooltip from '../../../MUIComponents/Tooltip'

const ConfigureModal = ({ children, close, height, isOpen, saveConfiguration, settings, title }) => {
  const desktopSize = useRef(settings.desktopSize)
  const onDesktopSizeChange = event => {
    desktopSize.current = parseInt(event.target.value)
  }

  const tabletSize = useRef(settings.tabletSize)
  const onTabletSizeChange = event => {
    tabletSize.current = parseInt(event.target.value)
  }

  const phoneSize = useRef(settings.phoneSize)
  const onPhoneSizeChange = event => {
    phoneSize.current = parseInt(event.target.value)
  }

  const save = useCallback(() => {
    let changed = false
    const newSettings = {
      ...settings
    }

    if (settings.desktopSize !== desktopSize.current) {
      changed = true
      newSettings.desktopSize = desktopSize.current
    }

    if (settings.tabletSize !== tabletSize.current) {
      changed = true
      newSettings.tabletSize = tabletSize.current
    }

    if (settings.phoneSize !== phoneSize.current) {
      changed = true
      newSettings.phoneSize = phoneSize.current
    }

    saveConfiguration(newSettings, changed)
  }, [saveConfiguration, settings])

  return (
    <Dialog
      onClose={close}
      open={isOpen}
      PaperProps={{
        style: {
          width: '100%',
          maxHeight: '100%',
          height: height || '320px'
        }
      }}
    >
      <Tooltip title='Close'>
        <div style={{ position: 'absolute', top: '8px', right: '8px', zIndex: 1, cursor: 'pointer' }} onClick={close}><CloseIcon /></div>
      </Tooltip>
      <div className='panel' style={{ willChange: 'transform', display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        <h2>Configure {title} Widget</h2>
        <div className='panelContent' style={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div style={{ flex: 1, paddingTop: '1em' }}>
            {children &&
              <h6 className='mdc-typography--subtitle2'>General Settings</h6>}
            <MDCSelect
              label='Widget Desktop Size'
              value={String(settings.desktopSize)}
              options={[
                ['12', 'Fullwidth'],
                ['9', '3/4 Width'],
                ['8', '2/3 Width'],
                ['6', '1/2 Width'],
                ['4', '1/3 Width'],
                ['3', '1/4 Width']
              ]}
              onChange={onDesktopSizeChange}
              style={{ width: '100%' }}
            />
            <br />
            <br />
            <MDCSelect
              label='Widget Tablet Size'
              value={String(settings.tabletSize)}
              options={[
                ['8', 'Fullwidth'],
                ['6', '3/4 Width'],
                ['4', '1/2 Width'],
                ['2', '1/4 Width']
              ]}
              onChange={onTabletSizeChange}
              style={{ width: '100%' }}
            />
            <br />
            <br />
            <MDCSelect
              label='Widget Phone Size'
              value={String(settings.phoneSize)}
              options={[
                ['4', 'Fullwidth'],
                ['3', '3/4 Width'],
                ['2', '1/2 Width'],
                ['1', '1/4 Width']
              ]}
              onChange={onPhoneSizeChange}
              style={{ width: '100%' }}
            />
            {children &&
              <>
                <br />
                <br />
                <h6 className='mdc-typography--subtitle2'>Advanced Settings</h6>
                {children}
              </>}
          </div>
          <div className='panelBottomActions'>
            <div className='panelBottomIcons'>
              <MDCButton label='Save' onClick={save} />
              &nbsp;
              &nbsp;
              <MDCButton label='Cancel' onClick={close} />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

ConfigureModal.propTypes = {
  children: PropTypes.node,
  close: PropTypes.func.isRequired,
  height: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  settings: PropTypes.shape(),
  title: PropTypes.string.isRequired
}

export default ConfigureModal
