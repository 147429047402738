import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchApplication } from '../actions/application'
import { fetchReportData } from '../actions/reports'

import { selectAccessee } from '../selectors/application'
import { makeReportDataSelector } from '../selectors/reports'

import { appFontStyle } from '../utilities/constants'
import { getContactSearchCriteriaBySubscriptionType } from '../utilities/methods'

export const REPORT_ID = 'contactsByStatus'

const contactsByStatusInnerGroups = ['activeGroup', 'notActiveGroup']
const contactsByStatusOuterGroups = [
  {
    typeId: 'activeGroup',
    methods: ['seamlessSignupForm', 'publicSignupForm', 'userForm', 'imported', 'api']
  },
  {
    typeId: 'notActiveGroup',
    methods: ['deactivated', 'bounced', 'unsubscribed', 'complained']
  }
]

const contactsByStatusColors = {
  activeGroup: '#57B63D', // moderate lime green (slightly darker than userForm)
  notActiveGroup: '#777777', // dark gray
  seamlessSignupForm: '#417932', // dark moderate lime green
  publicSignupForm: '#3DA223', // dark lime green
  userForm: '#75D45B', // moderate lime green
  imported: '#9BDB8B', // very soft lime green
  api: '#B4FFA0', // pale lime green
  deactivated: '#A47D7C', // dark grayish red
  bounced: '#3D96AE', // dark moderate cyan (teal, dark blue green)
  unsubscribed: '#DB843D', // bright orange
  complained: '#AA4643' // dark moderate red
}

const contactsByStatusDisplayNameBySubscriptionType = {
  seamlessSignupForm: 'Sign Up Form',
  publicSignupForm: 'Public Form',
  api: 'API',
  imported: 'Imported',
  userForm: 'User Form',
  bounced: 'Bounced',
  unsubscribed: 'Unsubscribed',
  complained: 'Complained',
  deactivated: 'Manually Deactivated',
  subscribedSubGroup: 'Subscribed',
  addedSubGroup: 'Added',
  bouncedSubGroup: 'Bounced',
  unsubscribedSubGroup: 'Unsubscribed',
  deactivatedSubGroup: 'Deactivated',
  activeGroup: 'Active',
  notActiveGroup: 'Deactivated'
}

const useContactsByStatusChart = ({ dialog, settings: { desktopSize, tabletSize, phoneSize }, userId }) => {
  const dispatch = useDispatch()

  const accessee = useSelector(selectAccessee)
  useEffect(() => {
    if (!accessee) {
      dispatch(fetchApplication())
    }
  }, [accessee, dispatch])

  const selectContactsByStatusReportData = useMemo(makeReportDataSelector)
  const contactsByStatusReportData = useSelector(state => selectContactsByStatusReportData(state, {
    report: REPORT_ID,
    userId
  }))

  useEffect(() => {
    if (!contactsByStatusReportData) {
      dispatch(fetchReportData(REPORT_ID))
    }
  }, [contactsByStatusReportData, dispatch])

  // MDC Init
  const refreshEl = useRef(null)
  const expandEl = useRef(null)
  const printEl = useRef(null)
  const downloadEl = useRef(null)
  useEffect(() => {
    if (refreshEl.current) {
      const el = refreshEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })
  useEffect(() => {
    if (expandEl.current) {
      const el = expandEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })
  useEffect(() => {
    if (printEl.current) {
      const el = printEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })
  useEffect(() => {
    if (downloadEl.current) {
      const el = downloadEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const containerEl = useRef(null)
  const chartInstance = useRef(null)
  const [isExpandedChartModalOpen, setExpandedChartModalOpen] = useState(false)

  const contactsByStatusChartOptions = useMemo(() => {
    if (accessee && contactsByStatusReportData) {
      const { data, numContacts } = contactsByStatusReportData
      const outerData = []
      const innerData = []
      let x = 0
      contactsByStatusInnerGroups.forEach(typeId => {
        const y = data[typeId] || 0
        const color = contactsByStatusColors[typeId]
        innerData.push({
          x: x++,
          y: y,
          name: contactsByStatusDisplayNameBySubscriptionType[typeId],
          type: typeId,
          color: color
        })
      })

      contactsByStatusOuterGroups.forEach(settings => {
        const tmpOuterData = []
        settings.methods.forEach(method => {
          const y = data[method] || 0
          const color = contactsByStatusColors[method]
          tmpOuterData.push({
            x: x++,
            y: y,
            name: contactsByStatusDisplayNameBySubscriptionType[method],
            type: settings.typeId,
            color: color
          })
        })
        tmpOuterData.sort((a, b) => b.y - a.y)
        Array.prototype.push.apply(outerData, tmpOuterData)
      })
      const totalContacts = innerData.reduce((x, y) => x + y.y, 0)

      const chart = {
        backgroundColor: 'rgba(0,0,0,0)',
        type: 'pie',
        events: {
          exportData: event => {
            event.dataRows[0][0] = 'Status or Method'
            event.dataRows[0][1] = 'Contacts'
            event.dataRows[0][2] = 'Percent of Total'
            for (let i = 1; i < event.dataRows.length; i++) {
              if (event.dataRows[i][1] === undefined) {
                event.dataRows[i][1] = event.dataRows[i][2]
                event.dataRows[i][2] = (event.dataRows[i][1] * 100 / totalContacts).toFixed(2) + '%'
              } else {
                event.dataRows[i].push((event.dataRows[i][1] * 100 / totalContacts).toFixed(2) + '%')
              }
            }
          }
        }
      }

      const pie = {
        borderColor: '#d4d4d4',
        cursor: 'pointer',
        size: 300,
        point: {
          events: {
            click: function (event) {
              const criteria = getContactSearchCriteriaBySubscriptionType(this.options.type)
              const link = document.createElement('a')
              link.setAttribute('href', `/view_members.jsp?criteria=${encodeURIComponent(JSON.stringify(criteria))}&returnToPrevious=${encodeURIComponent(global.location.href)}`)
              link.style.display = 'none'
              document.body.appendChild(link)
              link.click()
              link.remove()
            }
          }
        },
        states: {
          hover: {
            brightness: 1
          }
        }
      }

      var legend = {
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#d4d4d4',
        enabled: true,
        itemStyle: {
          ...appFontStyle,
          fontWeight: 500
        },
        layout: 'vertical',
        labelFormatter: function () {
          return `<span style="display:inline-block; min-width: 154px;">${this.name}</span> <span style="display:inline-block; min-width: 50px;">${(this.y * 100 / this.series.yData.reduce((x, y) => x + y, 0)).toFixed(1)}%</span> (${this.y})`
        },
        useHTML: true,
        verticalAlign: 'bottom',
        itemMarginTop: 6,
        itemMarginBottom: 6
      }

      if (!dialog) {
        chart.height = 481
        pie.size = 240
        pie.center = ['50%', 100]
        legend.maxHeight = 148
      }

      return numContacts > 0 ? {
        chart,
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false,
          filename: `ContactsByStatusAndMethod_${moment().tz(accessee.timeZone).format('YYYY-MM-DD_HHmmss')}`,
          buttons: {
            exportButton: {
              enabled: false
            },
            printButton: {
              enabled: false
            }
          }
        },
        legend,
        plotOptions: {
          pie
        },
        series: [{
          id: 'inner',
          size: '75%',
          data: innerData,
          dataLabels: {
            borderColor: 'black',
            borderWidth: 1,
            borderRadius: 4,
            backgroundColor: 'rgba(255,255,255,.5)',
            distance: -40,
            formatter: function () {
              return `${this.point.name}<br><span style="font-size: 14px;">(${this.y})</span>`
            },
            style: {
              ...appFontStyle,
              fontWeight: 500
            }
          }
        }, {
          id: 'outer',
          size: '100%',
          innerSize: '75%',
          data: outerData,
          showInLegend: true,
          dataLabels: {
            enabled: false,
            formatter: function () {
              return `${this.point.name}<br>${this.percentage.toFixed(1)}%<br>(${this.y})`
            },
            style: {
              ...appFontStyle,
              fontWeight: 500
            }
          },
          shadow: true
        }],
        title: {
          cursor: 'pointer',
          text: innerData.length ? `<span class="members_by_status_and_method_report_panel_title" style="cursor:pointer">${data.activeGroup || 0} Active Contacts</span>` : '0 Contacts',
          style: {
            ...appFontStyle,
            fontSize: '1.2rem',
            fontWeight: 500
          }
        },
        tooltip: {
          formatter: function () {
            return `<span style="font-weight:500">${this.point.name}</span><br><br>${this.percentage.toFixed(1)}% (${this.y} Contact${this.y !== 1 ? 's' : ''})<br><br>Click chart to view contacts`
          },
          style: {
            ...appFontStyle
          },
          useHTML: true
        }
      } : {
        chart: {
          backgroundColor: 'rgba(0,0,0,0)',
          type: 'pie'
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        series: [{
          data: [['No contacts', 1]]
        }],
        title: {
          text: 'No data yet',
          style: {
            ...appFontStyle,
            fontSize: '1.2rem',
            fontWeight: 500
          }
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false
            }
          }
        }
      }
    }
  }, [dialog, accessee, contactsByStatusReportData])

  useEffect(() => {
    if (contactsByStatusChartOptions) {
      const contactsByStatusChart = chartInstance.current = global.Highcharts.chart(containerEl.current, contactsByStatusChartOptions)

      return () => {
        contactsByStatusChart.destroy()
      }
    } else {
      chartInstance.current = null
    }
  }, [contactsByStatusChartOptions, chartInstance, containerEl])

  useEffect(() => {
    const contactsByStatusChart = chartInstance.current
    if (contactsByStatusChart) {
      contactsByStatusChart.reflow()
    }
  }, [desktopSize, tabletSize, phoneSize])

  const expandChart = useCallback(event => {
    if (event) {
      event.preventDefault()
    }

    setExpandedChartModalOpen(true)
  }, [setExpandedChartModalOpen])

  const printChart = event => {
    if (event) {
      event.preventDefault()
    }

    if (!chartInstance.current) {
      return false
    }

    if (dialog) {
      chartInstance.current.setSize(780, null, false)
      chartInstance.current.print()
      chartInstance.current.setSize(chartInstance.current.chartWidth, chartInstance.current.chartHeight, false)
    } else {
      chartInstance.current.print()
    }
  }

  const downloadChart = event => {
    if (event) {
      event.preventDefault()
    }

    if (!chartInstance.current) {
      return false
    }

    const exportOptions = {
      url: '/highcharts-export.jsp'
    }

    const exportChartOptions = {
      chart: {
        backgroundColor: 'white'
      }
    }

    if (dialog) {
      exportChartOptions.chart.width = 650
    }

    switch (event.currentTarget.dataset.format) {
      case 'csv':
        chartInstance.current.downloadCSV()
        break
      case 'pdf':
        exportOptions.type = 'application/pdf'
        chartInstance.current.exportChart(exportOptions, exportChartOptions)
        break
      case 'png':
      default:
        exportOptions.type = 'image/png'
        chartInstance.current.exportChart(exportOptions, exportChartOptions)
        break
    }
  }

  const refreshData = useCallback(event => {
    if (event) {
      event.preventDefault()
    }

    dispatch(fetchReportData(REPORT_ID))
  }, [dispatch])

  const closeExpandedChartModal = useCallback(() => {
    setExpandedChartModalOpen(false)
  }, [setExpandedChartModalOpen])

  return [containerEl, {
    accessee,
    chartInstance,
    closeExpandedChartModal,
    contactsByStatusChartOptions,
    contactsByStatusReportData,
    downloadChart,
    downloadEl,
    expandChart,
    expandEl,
    isExpandedChartModalOpen,
    printChart,
    printEl,
    refreshData,
    refreshEl
  }]
}

export default useContactsByStatusChart
