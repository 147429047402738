import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { fetchApplication } from '../actions/application'
import { fetchReportData } from '../actions/reports'

import { selectAccessee, selectAccesseeId, selectAccesseeTimeZone, selectWebSiteURL } from '../selectors/application'
import { makeReportDataSelector } from '../selectors/reports'

import MDCCircularProgress from './MDCComponents/CircularProgress'

import Calendar from './UtilityComponents/Calendar'

export const REPORT_ID = 'scheduledSendingEvents'

const messages = {
  event: 'Scheduled Sending',
  noEventsInRange: 'There are no scheduled sendings in this range.'
}

const onSelectEvent = (event, e) => {
  const { rawData: { timestamp, campaignId, issueNumber, variationIndex } } = event
  if (moment(timestamp).isSameOrBefore(moment())) {
    const params = {
      reportKey: {
        campaignId,
        issueNumber,
        sendingType: 'normalScheduled'
      }
    }
    if (variationIndex !== undefined) {
      params.reportKey.variationIndex = variationIndex
    }

    global.location.href = `/reports.jsp#${JSON.stringify(params)}`
  } else {
    // TODO: Implement scrolling to the specific queue item!
    global.location.href = '/queue_manager.jsp'
  }
}

const ScheduledSendingsCalendar = ({ showTitle, usePageloadProgress }) => {
  const dispatch = useDispatch()

  const accessee = useSelector(selectAccessee)
  const accesseeId = useSelector(selectAccesseeId)
  const accesseeTimeZone = useSelector(selectAccesseeTimeZone)
  const webSiteURL = useSelector(selectWebSiteURL)

  useEffect(() => {
    if (!accessee) {
      dispatch(fetchApplication())
    }
  }, [accessee, dispatch])

  const selectScheduledSendingEventsReportData = useMemo(makeReportDataSelector)
  const scheduledSendingEventsData = useSelector(state => selectScheduledSendingEventsReportData(state, {
    report: REPORT_ID
  }))

  useEffect(() => {
    dispatch(fetchReportData(REPORT_ID))
  }, [dispatch])

  const events = useMemo(() => accesseeTimeZone && scheduledSendingEventsData ? scheduledSendingEventsData.map(event => {
    const { timestamp, campaignName, issueNumber, variationIndex } = event
    const rawData = {
      ...event
    }

    let title = campaignName
    if (issueNumber !== undefined) {
      title += ` (Issue: ${issueNumber}`
      if (variationIndex !== undefined && variationIndex >= 0) {
        title += `, Variation: ${String.fromCharCode(65 + variationIndex)}`
      }
      title += ')'
    }

    const startTime = moment(timestamp).tz(accesseeTimeZone)
    return {
      start: startTime.toDate(),
      end: startTime.add(1, 'hour').toDate(),
      title,
      rawData
    }
  }) : null, [accesseeTimeZone, scheduledSendingEventsData])

  useEffect(() => {
    if (usePageloadProgress) {
      if (!events) {
        global.$('#fd_pageload_progress').removeClass('mdc-linear-progress--closed')
      } else {
        global.$('#fd_pageload_progress').addClass('mdc-linear-progress--closed')
      }
    }
  }, [events, usePageloadProgress])

  return (
    <div>
      {
        !usePageloadProgress
          ? !events ? (
            <div className='mdc-layout-grid'>
              <div className='mdc-layout-grid__inner'>
                <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-12'>
                  <div className='panel'>
                    <div className='panelContent' style={{ paddingTop: '1rem' }}>
                      <MDCCircularProgress>Loading scheduled sendings...</MDCCircularProgress>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
            : null
          : null
      }
      {events &&
        <div className='mdc-layout-grid'>
          <div className='mdc-layout-grid__inner'>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-12'>
              <div className='panel'>
                {showTitle && <h2>Scheduled Sendings</h2>}
                <div className='panelContent' style={{ paddingTop: '1rem' }}>
                  <Calendar
                    events={events}
                    messages={messages}
                    onSelectEvent={onSelectEvent}
                    timeZone={accesseeTimeZone}
                  />
                  <div style={{ paddingTop: '1rem' }}>Note: You may upload this calendar to your scheduling software using <a href={`${webSiteURL}calendars/scheduledSendings/${accesseeId}.ics`}>this iCalendar url</a>.</div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </div>
  )
}

ScheduledSendingsCalendar.propTypes = {
  showTitle: PropTypes.bool,
  usePageloadProgress: PropTypes.bool
}

export default ScheduledSendingsCalendar
