import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Calendar, DateLocalizer, momentLocalizer } from 'react-big-calendar'
import { formats } from 'react-big-calendar/lib/localizers/moment'

import 'react-big-calendar/lib/css/react-big-calendar.css'

import { makeStyles } from '@material-ui/core/styles'

import AgendaEvent from './AgendaEvent'
import EventWrapper from './EventWrapper'
import Toolbar from './Toolbar'

import { SelectEventProvider } from './SelectEventContext'

const culture = global.navigator.languages ? global.navigator.languages[0] : global.navigator.userLanguage || global.navigator.language

const standardLocalizer = momentLocalizer(moment)

const locale = function (m, c) {
  return c ? m.locale(c) : m
}

const firstOfWeek = function (culture) {
  const data = culture ? moment.localeData(culture) : moment.localeData()
  return data ? data.firstDayOfWeek() : 0
}

const defaultComponents = {
  agenda: {
    event: AgendaEvent
  },
  eventWrapper: EventWrapper,
  toolbar: Toolbar
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .rbc-off-range': {
      color: '#999999'
    },
    '& .rbc-off-range-bg': {
      background: '#e6e6e6'
    },
    '& .rbc-header': {
      borderBottomColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-header + .rbc-header': {
      borderLeftColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-rtl .rbc-header + .rbc-header': {
      borderRightColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-today': {
      color: theme.palette.getContrastText('#eaf6ff'),
      backgroundColor: '#eaf6ff'
    },
    '& .rbc-event': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: theme.shape.borderRadius
    },
    '& .rbc-event.rbc-selected': {
      color: theme.palette.getContrastText(theme.palette.secondary.dark),
      backgroundColor: theme.palette.secondary.dark
    },
    '& .rbc-selected-cell': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    '& .rbc-show-more': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    '& .rbc-month-view': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: theme.shape.borderRadius
    },
    '& .rbc-month-row + .rbc-month-row': {
      borderTopColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-day-bg + .rbc-day-bg': {
      borderLeftColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-rtl .rbc-day-bg + .rbc-day-bg': {
      borderRightColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-overlay': {
      borderColor: '#e5e5e5',
      backgroundColor: theme.palette.background.paper
    },
    '& .rbc-overlay-header': {
      borderBottomColor: '#e5e5e5'
    },
    '& .rbc-agenda-view table.rbc-agenda-table': {
      borderColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-agenda-view > table.rbc-agenda-table': {
      borderBottomWidth: '0px',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius
    },
    '& .rbc-agenda-view .rbc-agenda-content table.rbc-agenda-table': {
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius
    },
    '& .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td': {
      borderLeftColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td': {
      borderRightColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr': {
      borderTopColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-agenda-view table.rbc-agenda-table thead > tr > th': {
      // borderBottomColor: 'rgba(0, 0, 0, 0.23)',
      borderBottomWidth: '0px'
    },
    '& .rbc-timeslot-group': {
      borderBottomColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-day-slot .rbc-event': {
      borderColor: '#265985'
    },
    '& .rbc-day-slot .rbc-time-slot': {
      borderTopColor: '#f7f7f7'
    },
    '& .rbc-time-view-resources .rbc-time-gutter': {
      backgroundColor: theme.palette.background.paper,
      borderRightColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-time-view-resources .rbc-time-header-gutter': {
      backgroundColor: theme.palette.background.paper,
      borderRightColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-slot-selection': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: theme.palette.background.paper
    },
    '& .rbc-time-view': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderBottomWidth: '0px',
      borderRadius: theme.shape.borderRadius
    },
    '& .rbc-time-view .rbc-allday-cell + .rbc-allday-cell': {
      borderLeftColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-time-header.rbc-overflowing': {
      borderRightColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-rtl .rbc-time-header.rbc-overflowing': {
      borderLeftColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-time-header > .rbc-row:first-child': {
      borderBottomColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-time-header > .rbc-row.rbc-row-resource': {
      borderBottomColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-time-header-content': {
      borderLeftColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-rtl .rbc-time-header-content': {
      borderRightColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-time-header-content > .rbc-row.rbc-row-resource': {
      borderBottomColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-time-content': {
      borderTopWidth: '1px',
      borderTopColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-time-content > * + * > *': {
      borderLeftColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-rtl .rbc-time-content > * + * > *': {
      borderRightColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .rbc-current-time-indicator': {
      backgroundColor: '#0f9d58'
    }
  }
}))

const StyledCalendar = props => {
  const { timeZone, components, onSelectEvent, ...other } = props

  const classes = useStyles()

  const effectiveComponents = useMemo(() => {
    if (components) {
      if (components.agenda) {
        return {
          ...defaultComponents,
          ...components,
          agenda: {
            ...defaultComponents.agenda,
            ...components.agenda
          }
        }
      }

      return {
        ...defaultComponents,
        ...components
      }
    }

    return {
      ...defaultComponents
    }
  }, [components])

  const localizer = useMemo(() => {
    if (timeZone) {
      return new DateLocalizer({
        formats,
        firstOfWeek,
        format: function (value, _format, culture) {
          return locale(moment(value), culture).tz(timeZone).format(_format)
        }
      })
    }

    return standardLocalizer
  }, [timeZone])

  return (
    <div className={classes.root}>
      <SelectEventProvider value={onSelectEvent}>
        <Calendar
          localizer={localizer}
          culture={culture}
          components={effectiveComponents}
          tooltipAccessor={null}
          onSelectEvent={onSelectEvent}
          {...other}
        />
      </SelectEventProvider>
    </div>
  )
}

StyledCalendar.propTypes = {
  timeZone: PropTypes.string,
  components: PropTypes.object,
  onSelectEvent: PropTypes.func
}

export default StyledCalendar
