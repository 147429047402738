import { createSelector } from 'reselect'

export const selectReports = state => state.reports

export const selectReportsData = state => state.reports ? state.reports.reportsData : null

export const selectFetchingReports = state => state.reports ? state.reports.fetchingReports : null

export const makeReportDataSelector = () => createSelector(
  [selectReportsData, (_, { report }) => report, (_, { userId }) => userId || 'self'],
  (reportsData, report, userId) => {
    if (reportsData) {
      const key = `${userId}|${report}`
      if (reportsData[key]) {
        return reportsData[key]
      }
    }

    return null
  }
)

export const makeFetchingReportSelector = () => createSelector(
  [selectFetchingReports, (_, { report }) => report, (_, { userId }) => userId],
  (fetchingReports, report, userId) => fetchingReports ? !!fetchingReports[`${userId}|${report}`] : false
)
