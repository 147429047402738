import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import { makeStyles } from '@material-ui/core/styles'

import { navigate } from 'react-big-calendar/lib/utils/constants'

const { TODAY, PREVIOUS, NEXT } = navigate

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    fontSize: '1em'
  },
  label: {
    flexGrow: 1,
    padding: '0 10px',
    textAlign: 'center',
    marginBottom: 0
  },
  toggleButton: {
    color: theme.palette.text.primary,
    '&.Mui-selected': {
      color: theme.palette.text.primary
    },
    height: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '5px 16px'
  }
}))

const Toolbar = ({ localizer: { messages }, label, onNavigate, onView, views, view }) => {
  const classes = useStyles()
  const { toggleButton } = classes

  const navigateToday = useCallback(() => {
    onNavigate(TODAY)
  }, [onNavigate])

  const navigatePrevious = useCallback(() => {
    onNavigate(PREVIOUS)
  }, [onNavigate])

  const navigateNext = useCallback(() => {
    onNavigate(NEXT)
  }, [onNavigate])

  const handleChange = useCallback((event, value) => {
    onView(value)
  }, [onView])

  const renderViewName = useCallback(name => {
    const label = messages[name]
    return (
      <ToggleButton
        key={name}
        value={name}
        className={toggleButton}
        arial-label={label}
      >
        {label}
      </ToggleButton>
    )
  }, [messages, toggleButton])

  const viewsGroup = useMemo(() => {
    if (views.length > 1) {
      return views.map(renderViewName)
    }

    return null
  }, [renderViewName, views])

  const { today, previous, next } = messages
  return (
    <div className={classes.root}>
      <ButtonGroup>
        <Button onClick={navigateToday}>
          {today}
        </Button>
        <Button onClick={navigatePrevious}>
          {previous}
        </Button>
        <Button onClick={navigateNext}>
          {next}
        </Button>
      </ButtonGroup>

      <h6 className={`mdc-typography--headline6 ${classes.label}`}>{label}</h6>

      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleChange}
      >
        {viewsGroup}
      </ToggleButtonGroup>
    </div>
  )
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.shape({
    messages: PropTypes.object
  }),
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired
}

export default Toolbar
