import PropTypes from 'prop-types'
import React from 'react'

import Tooltip from '../../MUIComponents/Tooltip'

const EventWrapper = ({ accessors: { title }, children, event }) => {
  return (
    <Tooltip title={title(event)}>
      {children}
    </Tooltip>
  )
}

EventWrapper.propTypes = {
  accessors: PropTypes.shape({
    title: PropTypes.func.isRequired
  }),
  children: PropTypes.node,
  event: PropTypes.shape()
}

export default EventWrapper
