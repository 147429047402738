import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const MDCRipple = props => {
  const { tagName, children, ...rest } = props
  const rootEl = useRef(null)

  useEffect(() => {
    if (rootEl.current) {
      const el = rootEl.current
      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)

      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  }, [])

  const Tag = tagName
  return (
    <Tag
      ref={rootEl}
      {...rest}
    >
      {children}
    </Tag>
  )
}

MDCRipple.propTypes = {
  tagName: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default MDCRipple
