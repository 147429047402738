import {
  FETCHING_LISTS,
  SET_LISTS
} from '../constants/lists'

export const initialState = {
  data: null,
  fetching: false
}

export default function listsReducer (state = initialState, action) {
  switch (action.type) {
    case FETCHING_LISTS:
      return {
        ...state,
        fetching: true
      }
    case SET_LISTS:
      return {
        ...state,
        fetching: false,
        data: action.lists
      }
    default:
      return state
  }
}
