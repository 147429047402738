import r2 from 'r2'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { WEBSOCKET_DATA_RECEIVED } from '../constants/websocket'
import { FETCH_VERIFIED_EMAIL_ADDRESSES, VERIFY_EMAIL_ADDRESS } from '../constants/verifiedEmailAddresses'
import { fetchingVerifiedEmailAddresses, setVerifiedEmailAddresses, verifyingEmailAddress } from '../actions/verifiedEmailAddresses'
import { selectAccesseeId } from '../selectors/application'
import { selectFetchingVerifiedEmailAddresses, selectVerifyingEmailAddress } from '../selectors/verifiedEmailAddresses'

function * verifyEmailAddress (action) {
  const isVerifyingEmailAddress = yield select(selectVerifyingEmailAddress)
  if (!isVerifyingEmailAddress) {
    yield put(verifyingEmailAddress(true))
    const userId = yield select(selectAccesseeId)
    if (userId) {
      try {
        const payload = yield (yield call(r2, {
          url: '/service_user.jsp',
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          },
          body: `action=emailAddressRequestVerification&userId=${encodeURIComponent(userId)}&emailAddress=${encodeURIComponent(action.emailAddress)}`
        })).json

        if (payload) {
          if (payload.success) {
            global.appShowSnackbarMessage('Check your inbox for an email from us!', { wide: true })
          } else {
            let message = payload.message || 'System error.'
            switch ((payload.responseCode && payload.responseCode.id) || null) {
              case 4:
                message = 'Authentication required.'
                break
              default:
                break
            }
            throw new Error(message)
          }
        }
      } catch (e) {
        global.appShowSnackbarMessage(e.message, { wide: true })
      }
    }

    yield put(verifyingEmailAddress(false))
  }
}

function * fetchVerifiedEmailAddresses (action) {
  const isFetchingVerifiedEmailAddresses = yield select(selectFetchingVerifiedEmailAddresses)

  if (!isFetchingVerifiedEmailAddresses) {
    yield put(fetchingVerifiedEmailAddresses())
    const userId = yield select(selectAccesseeId)
    if (userId) {
      try {
        const payload = yield (yield call(r2, {
          url: '/service_user.jsp',
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          },
          body: `action=emailAddressVerifiedList&userId=${encodeURIComponent(userId)}`
        })).json

        if (payload) {
          if (payload.verifiedEmailAddresses) {
            yield put(setVerifiedEmailAddresses(payload.verifiedEmailAddresses))
          } else {
            let message = payload.message || 'System error.'
            switch ((payload.responseCode && payload.responseCode.id) || null) {
              case 4:
                message = 'Authentication required.'
                break
              default:
                break
            }
            throw new Error(message)
          }
        }
      } catch (e) {
        yield put(setVerifiedEmailAddresses(null))
      }
    } else {
      yield put(setVerifiedEmailAddresses(null))
    }
  }
}

function * maybeUpdateVerifiedEmailAddresses (action) {
  switch (action.dataKey) {
    case 'verifiedEmailAddresses':
      yield put(setVerifiedEmailAddresses(action.payload.verifiedEmailAddresses))
      break
    default:
      break
  }
}

function * verifiedEmailAddressesSaga () {
  if (global.WebSocket) {
    yield takeEvery(WEBSOCKET_DATA_RECEIVED, maybeUpdateVerifiedEmailAddresses)
  } else {
    yield takeEvery(FETCH_VERIFIED_EMAIL_ADDRESSES, fetchVerifiedEmailAddresses)
  }

  yield takeEvery(VERIFY_EMAIL_ADDRESS, verifyEmailAddress)
}

export default verifiedEmailAddressesSaga
