import r2 from 'r2'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { FETCH_LISTS } from '../constants/lists'
import { fetchingLists, setLists } from '../actions/lists'
import { selectFetchingLists } from '../selectors/lists'

function * fetchLists (action) {
  const isFetchingLists = yield select(selectFetchingLists)

  if (!isFetchingLists) {
    yield put(fetchingLists())
    try {
      const payload = yield (yield call(r2, {
        url: '/api/v2.jsp',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: 'params=' + encodeURIComponent(JSON.stringify({
          action: 'Category_List',
          includeMemberCount: true
        }))
      })).json

      if (payload) {
        if (payload.categories) {
          yield put(setLists(payload.categories))
        } else {
          let message = payload.message || 'System error.'
          switch (payload.responseCode.id) {
            case 4:
              message = 'Authentication required.'
              break
            default:
              break
          }
          throw new Error(message)
        }
      }
    } catch (e) {
      yield put(setLists(null))
    }
  }
}

function * listsSaga () {
  yield takeEvery(FETCH_LISTS, fetchLists)
}

export default listsSaga
