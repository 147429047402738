import r2 from 'r2'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { WEBSOCKET_DATA_RECEIVED } from '../constants/websocket'
import { FETCH_CUSTOM_FIELDS } from '../constants/customFields'
import { fetchingCustomFields, setCustomFields } from '../actions/customFields'
import { selectFetchingCustomFields } from '../selectors/customFields'

function * fetchCustomFields (action) {
  const isFetchingCustomFields = yield select(selectFetchingCustomFields)

  if (!isFetchingCustomFields) {
    yield put(fetchingCustomFields())
    try {
      const payload = yield (yield call(r2, {
        url: '/api/v2.jsp',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: 'params=' + encodeURIComponent(JSON.stringify({
          action: 'CustomField_List'
        }))
      })).json

      if (payload) {
        if (payload.customFields) {
          yield put(setCustomFields(payload.customFields))
        } else {
          let message = payload.message || 'System error.'
          switch (payload.responseCode.id) {
            case 4:
              message = 'Authentication required.'
              break
            default:
              break
          }
          throw new Error(message)
        }
      }
    } catch (e) {
      yield put(setCustomFields(null))
    }
  }
}

function * maybeUpdateCustomFields (action) {
  switch (action.dataKey) {
    case 'customFields':
      yield put(setCustomFields(action.payload.customFields))
      break
    default:
      break
  }
}

function * customFieldsSaga () {
  if (global.WebSocket) {
    yield takeEvery(WEBSOCKET_DATA_RECEIVED, maybeUpdateCustomFields)
  } else {
    yield takeEvery(FETCH_CUSTOM_FIELDS, fetchCustomFields)
  }
}

export default customFieldsSaga
