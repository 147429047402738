import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'
import { DragHandleProvider } from './DragHandleContext'
import SortableDropContext from './SortableDropContext'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

let emptyImage

const getEmptyImage = () => {
  if (!emptyImage) {
    emptyImage = new global.Image()
    emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
  }

  return emptyImage
}

const getStyles = (isDragging) => {
  if (isDragging) {
    return {
      opacity: 0.33
    }
  } else {
    return {
      opacity: 1
    }
  }
}

const Draggable = ({ children, className, end, hasHandle, itemContent, itemId, itemType, type }) => {
  const drop = useContext(SortableDropContext)
  const item = {
    type: itemType || type,
    content: itemContent || DragIndicatorIcon
  }

  if (itemId) {
    item.id = itemId
  }

  const [{ isDragging }, drag, preview] = useDrag({
    item,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    end
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  })

  if (hasHandle) {
    return (
      <div ref={drop} className={className} style={getStyles(isDragging)}>
        <DragHandleProvider value={drag}>
          {children}
        </DragHandleProvider>
      </div>
    )
  }

  return (
    <div ref={drop ? (node) => drag(drop(node)) : drag} className={className} style={getStyles(isDragging)}>
      {children}
    </div>
  )
}

Draggable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  end: PropTypes.func,
  hasHandle: PropTypes.bool,
  itemContent: PropTypes.shape(),
  itemId: PropTypes.string,
  itemType: PropTypes.string,
  type: PropTypes.string.isRequired
}

export default Draggable
