import PropTypes from 'prop-types'
import React, { memo, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react'

import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import SearchIcon from '@material-ui/icons/Search'
import SettingsIcon from '@material-ui/icons/Settings'

import ConfigureModal from './utilities/ConfigureModal'

import MDCButton from '../../MDCComponents/Button'
import MDCTextField from '../../MDCComponents/TextField'

import Tooltip from '../../MUIComponents/Tooltip'

import DragHandleContext from '../../UtilityComponents/DragHandleContext'

import WidgetEventHandlerContext from './utilities/WidgetEventHandlerContext'

const initialState = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phone: '',
  mobilePhone: '',
  fax: ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setField': {
      const { field, value } = action
      if (state[field] !== value) {
        return {
          ...state,
          [field]: value
        }
      }
      return state
    }
    default:
      return state
  }
}

const navigateToSearchPage = event => {
  if (event) {
    event.preventDefault()
  }

  global.location.href = '/memberSearch.jsp'

  return false
}

const SearchContactsWidget = props => {
  const { children, id, settings, title } = props

  const { current: widgetEventHandler } = useContext(WidgetEventHandlerContext)

  const drag = useContext(DragHandleContext)

  const removeEl = useRef(null)
  useEffect(() => {
    if (removeEl.current) {
      const el = removeEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const configureEl = useRef(null)
  useEffect(() => {
    if (configureEl.current) {
      const el = configureEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const searchEl = useRef(null)
  useEffect(() => {
    if (searchEl.current) {
      const el = searchEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const [{
    firstName,
    lastName,
    emailAddress,
    phone,
    mobilePhone,
    fax
  }, reactDispatch] = useReducer(reducer, initialState)

  const handleChange = name => ({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: name,
      value
    })
  }

  const searchContacts = useCallback(event => {
    if (event) {
      event.preventDefault()
    }

    let hasCriteria = false
    const criteria = {
      search: true,
      nameStartsWith: true
    }

    if (firstName) {
      criteria.firstName = firstName
      hasCriteria = true
    }
    if (lastName) {
      criteria.lastName = lastName
      hasCriteria = true
    }
    if (emailAddress) {
      criteria.emailAddress = emailAddress
      hasCriteria = true
    }
    if (phone) {
      criteria.phone = phone
      hasCriteria = true
    }
    if (mobilePhone) {
      criteria.mobilePhone = mobilePhone
      hasCriteria = true
    }
    if (fax) {
      criteria.fax = fax
      hasCriteria = true
    }

    if (!hasCriteria) {
      global.appShowSnackbarMessage('Please specify at least one search criteria.')
      return
    }

    const link = document.createElement('a')
    link.setAttribute('href', `/view_members.jsp?criteria=${encodeURIComponent(JSON.stringify(criteria))}`)
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    link.remove()
  }, [
    firstName,
    lastName,
    emailAddress,
    phone,
    mobilePhone,
    fax
  ])

  const [isConfigureModalOpen, setConfigureModalOpen] = useState(false)
  const closeConfigureModal = useCallback(() => setConfigureModalOpen(false), [setConfigureModalOpen])
  const saveConfiguration = useCallback((newSettings, changed) => {
    if (changed) {
      widgetEventHandler({
        type: 'configure',
        id,
        settings: newSettings
      })
    }

    setConfigureModalOpen(false)
  }, [id, setConfigureModalOpen, widgetEventHandler])

  const onConfigure = useCallback(() => {
    setConfigureModalOpen(true)
  }, [setConfigureModalOpen])

  const onRemove = useCallback(() => {
    widgetEventHandler({
      type: 'remove',
      id
    })
  }, [id, widgetEventHandler])

  return (
    <div className='panel' style={{ willChange: 'transform' }}>
      <h2>{title}</h2>
      <div className='panelContent' style={{ minHeight: '548px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div className='panelActions'>
          <Tooltip title='Move Widget'>
            <span ref={drag} style={{ cursor: 'move' }}><DragIndicatorIcon /></span>
          </Tooltip>
        </div>
        {children}
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <MDCTextField value={firstName} label='First Name' onChange={handleChange('firstName')} style={{ margin: '1em 0 0' }} />
          <MDCTextField value={lastName} label='Last Name' onChange={handleChange('lastName')} style={{ margin: '1em 0 0' }} />
          <MDCTextField value={emailAddress} label='Email Address' onChange={handleChange('emailAddress')} style={{ margin: '1em 0 0' }}>Use * to wildcard match</MDCTextField>
          <MDCTextField value={phone} label='Phone' onChange={handleChange('phone')} style={{ margin: '1em 0 0' }} />
          <MDCTextField value={mobilePhone} label='Mobile' onChange={handleChange('mobilePhone')} style={{ margin: '1em 0 0' }} />
          <MDCTextField value={fax} label='Fax' onChange={handleChange('fax')} style={{ margin: '1em 0 0' }} />
        </div>
        <div className='panelBottomActions' style={{ flex: 0 }}>
          <div className='panelBottomButtons'>
            <MDCButton label='Search' onClick={searchContacts} />
          </div>
          <div className='panelBottomIcons'>
            <Tooltip title='Advanced Search'>
              <button ref={searchEl} className='mdc-icon-button' onClick={navigateToSearchPage}><SearchIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
            <Tooltip title='Configure Widget'>
              <button ref={configureEl} className='mdc-icon-button' onClick={onConfigure}><SettingsIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
            <Tooltip title='Remove Widget'>
              <button ref={removeEl} className='mdc-icon-button' onClick={onRemove}><DeleteIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
          </div>
        </div>
      </div>
      <ConfigureModal
        close={closeConfigureModal}
        isOpen={isConfigureModalOpen}
        saveConfiguration={saveConfiguration}
        settings={settings}
        title={title}
      />
    </div>
  )
}

SearchContactsWidget.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  settings: PropTypes.shape(),
  title: PropTypes.string
}

const MemoizedSearchContactsWidget = memo(SearchContactsWidget)

export default MemoizedSearchContactsWidget
