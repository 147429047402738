import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import MDCButton from './MDCComponents/Button'
import MDCTextField from './MDCComponents/TextField'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '66.67%',
    flexShrink: 0
  },
  expansionPanelDetails: {
    flexWrap: 'wrap'
  },
  fullWidth: {
    width: '100%'
  }
}))

const CodeYourOwn = ({ callback }) => {
  const classes = useStyles()

  const [values, setValues] = useState({
    expanded: null,
    code: '',
    upload: null,
    url: '',
    processing: false
  })

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  const handleExpandedChange = panel => (event, isExpanded) => {
    setValues({ ...values, expanded: isExpanded ? panel : null })
  }

  const handleFileChange = name => event => {
    setValues({ ...values, [name]: event.target.files[0] || null })
  }

  const executeProcessing = useCallback(
    function () {
      if (values.processing || typeof callback !== 'function') return

      setValues({ ...values, processing: true })

      const finished = function () {
        setValues({ ...values, processing: false })
      }

      switch (values.expanded) {
        case 'codePanel':
          callback(null, {
            type: 'code',
            data: values.code,
            onComplete: finished
          })
          break
        case 'uploadPanel':
          callback(null, {
            type: 'upload',
            data: values.upload,
            onComplete: finished
          })
          break
        case 'urlPanel':
          callback(null, {
            type: 'url',
            data: values.url,
            onComplete: finished
          })
          break
        default:
          finished()
          break
      }
    },
    [callback, values]
  )

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={values.expanded === 'codePanel'}
        onChange={handleExpandedChange('codePanel')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='codePanelbh-content'
          id='codePanelbh-header'
        >
          <Typography className={classes.heading}>
            Paste in code
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <MDCTextField value={values.code} label='HTML' onChange={handleChange('code')} rows={8} />
          <MDCButton disabled={values.processing} label='Upload' onClick={executeProcessing} type='raised' style={{ marginTop: '1em' }} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={values.expanded === 'uploadPanel'}
        onChange={handleExpandedChange('uploadPanel')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='uploadPanelbh-content'
          id='uploadPanelbh-header'
        >
          <Typography className={classes.heading}>
            Upload a file
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <div className={classes.fullWidth}>
            <label htmlFor='uploadFile-upload'>
              <button
                type='button'
                className='mdc-button mdc-button--raised fd-file-upload-button'
              >
                <i className='material-icons mdc-button__icon' aria-hidden='true'>
                  file_upload
                </i>
                <span className='mdc-button__label'>Choose File</span>
              </button>
              <span> </span>
              <span id='uploadFile-upload-file'>No file chosen</span>
            </label>
            <input
              id='uploadFile-upload'
              name='uploadFile'
              type='file'
              aria-hidden='true'
              className='fd-file-upload-input'
              onChange={handleFileChange('upload')}
            />
          </div>
          <MDCButton disabled={values.processing} label='Upload' onClick={executeProcessing} type='raised' style={{ marginTop: '1em' }} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={values.expanded === 'urlPanel'}
        onChange={handleExpandedChange('urlPanel')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='urlPanelbh-content'
          id='urlPanelbh-header'
        >
          <Typography className={classes.heading}>
            Import from URL
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <MDCTextField value={values.url} label='URL' onChange={handleChange('url')} />
          <MDCButton disabled={values.processing} label='Import' onClick={executeProcessing} type='raised' style={{ marginTop: '1em' }} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

CodeYourOwn.propTypes = {
  callback: PropTypes.func
}

export default CodeYourOwn
