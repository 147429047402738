import PropTypes from 'prop-types'
import React from 'react'

export default function MDCCircularProgress ({ children }) {
  const CircularProgress = () => (
    <div
      className='mdc-circular-progress mdc-circular-progress--indeterminate'
      style={{
        left: 0,
        height: '36px',
        width: '36px',
        alignSelf: 'center'
      }}
    >
      <svg className='mdc-circular-progress__circle'>
        <circle className='mdc-circular-progress__path' cx='50%' cy='50%' r='41.667%' />
      </svg>
    </div>
  )

  if (children) {
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <CircularProgress />
        <span style={{ marginLeft: '8px' }}>{children}</span>
      </div>
    )
  }

  return (
    <CircularProgress />
  )
}

MDCCircularProgress.propTypes = {
  children: PropTypes.node
}
