import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import ArchiveIcon from '@material-ui/icons/Archive'
import AssessmentIcon from '@material-ui/icons/Assessment'
import BallotIcon from '@material-ui/icons/Ballot'
import BarChartIcon from '@material-ui/icons/BarChart'
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CodeIcon from '@material-ui/icons/Code'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import ContactsIcon from '@material-ui/icons/Contacts'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DescriptionIcon from '@material-ui/icons/Description'
import EmailIcon from '@material-ui/icons/Email'
import EventIcon from '@material-ui/icons/Event'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PublishIcon from '@material-ui/icons/Publish'
import RequestDemoIcon from '@material-ui/icons/ContactPhone'
import InboxIcon from '@material-ui/icons/Inbox'
import FeedbackIcon from '@material-ui/icons/Feedback'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
// import ForumIcon from '@material-ui/icons/Forum'
import SpeakerNotes from '@material-ui/icons/SpeakerNotes'
import GavelIcon from '@material-ui/icons/Gavel'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import HelpIcon from '@material-ui/icons/Help'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ListAltIcon from '@material-ui/icons/ListAlt'
// import LoopIcon from '@material-ui/icons/Loop'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import NotificationsIcon from '@material-ui/icons/Notifications'
import PeopleIcon from '@material-ui/icons/People'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import RateReviewIcon from '@material-ui/icons/RateReview'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import SearchIcon from '@material-ui/icons/Search'
import StorageIcon from '@material-ui/icons/Storage'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import SettingsIcon from '@material-ui/icons/Settings'
import ReceiptIcon from '@material-ui/icons/Receipt'
// import UpdateIcon from '@material-ui/icons/Update'
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import CardGiftcard from '@material-ui/icons/CardGiftcard'
import SaveAlt from '@material-ui/icons/SaveAlt'
import GetAppIcon from '@material-ui/icons/GetApp'

// import PresentToAll from '@material-ui/icons/PresentToAll'
import TrendingUp from '@material-ui/icons/TrendingUp'
import SyncAlt from '@material-ui/icons/SyncAlt'
// import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle'

// import ImportIcon from '../icons/Import'
import SavedSearchIcon from '../icons/SavedSearch'

import { fetchApplication } from '../actions/application'

import MDCCircularProgress from './MDCComponents/CircularProgress'
import MDCRipple from './MDCComponents/Ripple'
import Tooltip from './MUIComponents/Tooltip'

import {
  selectAccessee,
  selectAccesseeAccountMenuEnabled,
  selectAccesseeAnniversaryCampaignsEnabled,
  selectAccesseeBillingId,
  selectAccesseeBillingPaid,
  selectAccesseeBirthdayCampaignsEnabled,
  selectAccesseeCampaignSharing,
  selectAccesseeCampaignsMenuEnabled,
  selectAccesseeParentUserId,
  selectAccesseeReportsMenuEnabled,
  selectAccesseeSubUsers,
  selectAccesseeSystemTemplateLibraryEnabled,
  selectAccesseeTemplateUploadEnabled,
  selectAccesseeUserType,
  selectAccesseeWelcomeCampaignsEnabled,
  selectAccessorAccountMenuEnabled,
  selectAccessorAnniversaryCampaignsEnabled,
  selectAccessorBirthdayCampaignsEnabled,
  selectAccessorCampaignsMenuEnabled,
  selectAccessorReportsMenuEnabled,
  selectAccessorTemplateUploadEnabled,
  selectAccessorWelcomeCampaignsEnabled,
  selectBuildNumber,
  selectCampaignSharingEnabled,
  selectHasExportEnabled,
  selectHasContactsMenuEnabled,
  selectHasAccountMenuEnabled,
  selectHasReportMenuEnabled,
  selectI18N,
  selectIsAbuseMonitoringEnabled,
  selectIsFireDrum,
  selectIsHelmsBriscoe,
  selectIsIloyal,
  selectKnowledgeBaseWebSiteURL,
  selectMenuType,
  selectMenuItem,
  selectShowResellerChangeLog,
  selectShowUserChangeLog,
  selectSubMenuItem,
  selectSystemEmailFromAddress,
  selectSystemTemplateLibraryEnabled,
  selectUserFeedbackEnabled,
  selectWebSiteURL
} from '../selectors/application'

const openImageAndFileLibrary = () => {
  if (typeof global.loadmoxie === 'function') {
    global.loadmoxie()
  }
}

const openFeedback = () => {
  if (global._urq) {
    global._urq.push(['Feedback_Open'])
  }
}

const onPermanentSubHeaderClick = (e) => {
  const subHeaderEl = e.currentTarget
  const $subHeaderEl = global.$(subHeaderEl)
  if ($subHeaderEl.hasClass('expanded')) {
    $subHeaderEl
      .removeClass('fd-rotate')
      .removeClass('expanded')
      .next()
      .next()
      .slideUp(() => {
        $subHeaderEl.removeClass('fd-expanded-nav expanded')
      })
  } else {
    $subHeaderEl
      .addClass('fd-rotate')
      .addClass('expanded')
      .next()
      .next()
      .slideDown(() => {
        $subHeaderEl.addClass('fd-expanded-nav expanded')
      })
    const $otherSubHeaderEls = $subHeaderEl
      .parent()
      .find('.expanded')
      .not(subHeaderEl)
      .removeClass('fd-rotate')
      .removeClass('expanded')
    $otherSubHeaderEls
      .next()
      .next()
      .slideUp(() => {
        $otherSubHeaderEls.removeClass('fd-expanded-nav expanded')
      })
  }
}

const MainMenu = ({ onClickMadeInUsa, type }) => {
  const dispatch = useDispatch()

  const accessee = useSelector(selectAccessee)
  const accesseeAccountMenuEnabled = useSelector(selectAccesseeAccountMenuEnabled)
  const accesseeAnniversaryCampaignsEnabled = useSelector(selectAccesseeAnniversaryCampaignsEnabled)
  const accesseeBillingId = useSelector(selectAccesseeBillingId)
  const accesseeBillingPaid = useSelector(selectAccesseeBillingPaid)
  const accesseeBirthdayCampaignsEnabled = useSelector(selectAccesseeBirthdayCampaignsEnabled)
  const accesseeCampaignSharing = useSelector(selectAccesseeCampaignSharing)
  const accesseeCampaignsMenuEnabled = useSelector(selectAccesseeCampaignsMenuEnabled)
  const accesseeParentUserId = useSelector(selectAccesseeParentUserId)
  const accesseeReportsMenuEnabled = useSelector(selectAccesseeReportsMenuEnabled)
  const accesseeSubUsers = useSelector(selectAccesseeSubUsers)
  const accesseeSystemTemplateLibraryEnabled = useSelector(selectAccesseeSystemTemplateLibraryEnabled)
  const accesseeTemplateUploadEnabled = useSelector(selectAccesseeTemplateUploadEnabled)
  const accesseeUserType = useSelector(selectAccesseeUserType)
  const accesseeWelcomeCampaignsEnabled = useSelector(selectAccesseeWelcomeCampaignsEnabled)

  const accessorAccountMenuEnabled = useSelector(selectAccessorAccountMenuEnabled)
  const accessorAnniversaryCampaignsEnabled = useSelector(selectAccessorAnniversaryCampaignsEnabled)
  const accessorBirthdayCampaignsEnabled = useSelector(selectAccessorBirthdayCampaignsEnabled)
  const accessorCampaignsMenuEnabled = useSelector(selectAccessorCampaignsMenuEnabled)
  const accessorReportsMenuEnabled = useSelector(selectAccessorReportsMenuEnabled)
  const accessorTemplateUploadEnabled = useSelector(selectAccessorTemplateUploadEnabled)
  const accessorWelcomeCampaignsEnabled = useSelector(selectAccessorWelcomeCampaignsEnabled)

  const buildNumber = useSelector(selectBuildNumber)
  const campaignSharingEnabled = useSelector(selectCampaignSharingEnabled)
  const hasExportEnabled = useSelector(selectHasExportEnabled)
  const hasContactMenuEnabled = useSelector(selectHasContactsMenuEnabled)
  const hasAccountMenuEnabled = useSelector(selectHasAccountMenuEnabled)
  const hasReportMenuEnabled = useSelector(selectHasReportMenuEnabled)
  const i18n = useSelector(selectI18N)
  const isAbuseMonitoringEnabled = useSelector(selectIsAbuseMonitoringEnabled)
  const isFireDrum = useSelector(selectIsFireDrum)
  const isHelmsBriscoe = useSelector(selectIsHelmsBriscoe)
  const isIloyal = useSelector(selectIsIloyal)
  const knowledgeBaseWebSiteURL = useSelector(selectKnowledgeBaseWebSiteURL)
  const menuType = useSelector(selectMenuType)
  const menuItem = useSelector(selectMenuItem)
  const showResellerChangeLog = useSelector(selectShowResellerChangeLog)
  const showUserChangeLog = useSelector(selectShowUserChangeLog)
  const subMenuItem = useSelector(selectSubMenuItem)
  const systemEmailFromAddress = useSelector(selectSystemEmailFromAddress)
  const systemTemplateLibraryEnabled = useSelector(selectSystemTemplateLibraryEnabled)
  const userFeedbackEnabled = useSelector(selectUserFeedbackEnabled)
  const webSiteURL = useSelector(selectWebSiteURL)

  useEffect(() => {
    if (!accessee) {
      dispatch(fetchApplication())
    }
  }, [accessee, dispatch])

  const renderSubMenuItems = useCallback(({ id, url, onClick, Icon, label, target }) => (
    <MDCRipple
      key={id}
      tagName='a'
      className={`mdc-list-item${id === subMenuItem ? ' mdc-list-item--activated' : ''}`}
      href={url}
      onClick={onClick}
      target={target || '_self'}
    >
      <Icon className='mdc-list-item__graphic' />
      {label}
    </MDCRipple>
  ), [subMenuItem])

  let menuItems = null
  switch (menuType) {
    case 'blank':
    case 'signup':
    case 'none':
      break
    case 'login':
      if (accessee === null) {
        menuItems = (
          <nav id='fd_top_nav_menu' className='mdc-list'>
            <MDCRipple
              tagName='a'
              className='mdc-list-item mdc-list-item--activated'
              href={`${webSiteURL}login.jsp`}
            >
              <ExitToAppIcon className='mdc-list-item__graphic' />
              Login
            </MDCRipple>
          </nav>
        )
        break
      }
    default: // eslint-disable-line no-fallthrough
      if (accessee === null) {
        menuItems = (
          <div style={{ padding: '1rem' }}>
            <MDCCircularProgress>Menu Loading</MDCCircularProgress>
          </div>
        )
        break
      }

      switch (accesseeUserType) {
        case 'superAdmin':
          menuItems = (
            <>
              <h3 className='mdc-list-group__subheader'>
                <a href={`${webSiteURL}super_admin_configure_account.jsp`}>My Account</a>
              </h3>
              <h3 className='mdc-list-group__subheader'>
                <a href={`${webSiteURL}super_admin.jsp`}>Resellers</a>
              </h3>
              <h3 className='mdc-list-group__subheader'>
                <a href={`${webSiteURL}super_admin_reports.jsp`}>Reports</a>
              </h3>
              <h3 className='mdc-list-group__subheader'>
                <a href={`${webSiteURL}queue_manager.jsp`}>Sending Queue</a>
              </h3>
              <h3 className='mdc-list-group__subheader'>
                <a href={`${webSiteURL}super_admin_notifications.jsp`}>Notifications</a>
              </h3>
            </>
          )
          break
        case 'admin': {
          const subMenuItemsUsers = [
            {
              id: 'Admin Add User',
              url: `${webSiteURL}user.jsp?add#add`,
              Icon: PersonAddIcon,
              label: 'Add User'
            },
            {
              id: 'Admin Search Contacts',
              url: `${webSiteURL}memberSearch.jsp`,
              Icon: SearchIcon,
              label: 'Search Contacts'
            }
          ]

          if (systemTemplateLibraryEnabled && accesseeSystemTemplateLibraryEnabled) {
            subMenuItemsUsers.push({
              id: 'System Template Library',
              url: `${webSiteURL}templates.jsp`,
              Icon: LibraryBooksIcon,
              label: i18n['menu.systemTemplateLibrary']
            })
          }

          subMenuItemsUsers.push({
            id: 'System Image Library',
            onClick: openImageAndFileLibrary,
            Icon: PhotoLibraryIcon,
            label: i18n['menu.systemFileLibrary']
          })

          const subMenuItemsReports = [
            {
              id: 'Admin Activity Report',
              url: `${webSiteURL}report_activity.jsp`,
              Icon: AssessmentIcon,
              label: 'User Activity'
            },
            {
              id: 'Admin Contact Engagement Report',
              url: `${webSiteURL}report_member_engagement.jsp`,
              Icon: RecentActorsIcon,
              label: 'Contact Engagement'
            }
          ]

          if (isFireDrum) {
            subMenuItemsReports.push({
              id: 'Admin Custom Reports',
              url: `${webSiteURL}report_custom_list.jsp`,
              Icon: MultilineChartIcon,
              label: 'Custom Reports'
            })
          }

          const subMenuItemsMailQueue = [
            {
              id: 'Scheduled Sendings',
              url: `${webSiteURL}scheduled_sendings.jsp`,
              Icon: EventIcon,
              label: 'Scheduled Sendings'
            }
          ]

          const subMenuItemsAccountReps = [
            {
              id: 'Admin Add Account Rep',
              url: `${webSiteURL}reseller_admin_add_account_rep.jsp`,
              Icon: PersonAddIcon,
              label: 'Add Account Rep'
            }
          ]

          const subMenuItemsNotifications = [
            {
              id: 'System Notifications',
              url: `${webSiteURL}reseller_admin_system_notification_list.jsp`,
              Icon: NotificationImportantIcon,
              label: 'System Notifications'
            }
          ]

          menuItems = (
            <>
              <h3 className={`mdc-list-group__subheader${menuItem === 'Users' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                {type === 'permanent' &&
                  <MDCRipple
                    tagName='span'
                    className='mdc-ripple-surface'
                  />}
                Users
              </h3>
              <hr className='mdc-list-divider' />
              <nav id='fd_users_nav_menu' className='mdc-list'>
                <MDCRipple
                  tagName='a'
                  className={`mdc-list-item${!subMenuItem && menuItem === 'Users' ? ' mdc-list-item--activated' : ''}`}
                  href={`${webSiteURL}users.jsp`}
                  target='_self'
                >
                  <PeopleIcon className='mdc-list-item__graphic' />
                  View Users
                </MDCRipple>
                {subMenuItemsUsers.map(renderSubMenuItems)}
              </nav>
              <hr className='mdc-list-divider' />
              <h3 className={`mdc-list-group__subheader${menuItem === 'Reports' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                {type === 'permanent' &&
                  <MDCRipple
                    tagName='span'
                    className='mdc-ripple-surface'
                  />}
                Reports
              </h3>
              <hr className='mdc-list-divider' />
              <nav id='fd_reports_nav_menu' className='mdc-list'>
                {subMenuItemsReports.map(renderSubMenuItems)}
              </nav>
              <hr className='mdc-list-divider' />
              <h3 className={`mdc-list-group__subheader${menuItem === 'Sending Queue' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                {type === 'permanent' &&
                  <MDCRipple
                    tagName='span'
                    className='mdc-ripple-surface'
                  />}
                Mail Queue
              </h3>
              <hr className='mdc-list-divider' />
              <nav id='fd_mailqueue_nav_menu'>
                <MDCRipple
                  tagName='a'
                  className={`mdc-list-item${!subMenuItem && menuItem === 'Sending Queue' ? ' mdc-list-item--activated' : ''}`}
                  href={`${webSiteURL}queue_manager.jsp`}
                  target='_self'
                >
                  <StorageIcon className='mdc-list-item__graphic' />
                  View Queue
                </MDCRipple>
                {subMenuItemsMailQueue.map(renderSubMenuItems)}
              </nav>
              <hr className='mdc-list-divider' />
              <h3 className={`mdc-list-group__subheader${menuItem === 'Notifications' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                {type === 'permanent' &&
                  <MDCRipple
                    tagName='span'
                    className='mdc-ripple-surface'
                  />}
                Notifications
              </h3>
              <hr className='mdc-list-divider' />
              <nav id='fd_notifications_nav_menu' className='mdc-list'>
                <MDCRipple
                  tagName='a'
                  className={`mdc-list-item${!subMenuItem && menuItem === 'Notifications' ? ' mdc-list-item--activated' : ''}`}
                  href={`${webSiteURL}reseller_admin_notifications.jsp`}
                  target='_self'
                >
                  <NotificationsIcon className='mdc-list-item__graphic' />
                  Admin Notifications
                </MDCRipple>
                {subMenuItemsNotifications.map(renderSubMenuItems)}
              </nav>
              <hr className='mdc-list-divider' />
              <h3 className={`mdc-list-group__subheader${menuItem === 'Account Reps' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                {type === 'permanent' &&
                  <MDCRipple
                    tagName='span'
                    className='mdc-ripple-surface'
                  />}
                Account Reps
              </h3>
              <hr className='mdc-list-divider' />
              <nav id='fd_account_reps_nav_menu' className='mdc-list'>
                <MDCRipple
                  tagName='a'
                  className={`mdc-list-item${!subMenuItem && menuItem === 'Account Reps' ? ' mdc-list-item--activated' : ''}`}
                  href={`${webSiteURL}reseller_admin_account_rep.jsp`}
                  target='_self'
                >
                  <SupervisedUserCircleIcon className='mdc-list-item__graphic' />
                  View Account Reps
                </MDCRipple>
                {subMenuItemsAccountReps.map(renderSubMenuItems)}
              </nav>
            </>
          )
          break
        }
        case 'user': {
          const subMenuItemsCampaigns = [
            {
              id: 'Archived Email Campaigns',
              url: `${webSiteURL}campaigns.jsp?archived=true`,
              Icon: ArchiveIcon,
              label: 'Archived'
            }
          ]

          if (accesseeBirthdayCampaignsEnabled || accesseeAnniversaryCampaignsEnabled || accesseeWelcomeCampaignsEnabled || accessorBirthdayCampaignsEnabled || accessorAnniversaryCampaignsEnabled || accessorWelcomeCampaignsEnabled) {
            subMenuItemsCampaigns.push(accesseeBillingPaid ? {
              id: 'User E-Cards',
              url: `${webSiteURL}campaigns.jsp?ecards=true`,
              Icon: CardGiftcard,
              label: 'E-Cards'
            } : {
              id: 'User E-Cards',
              url: `${webSiteURL}client_admin_billing.jsp?function=upgrade&feature=E-Cards`,
              Icon: AddShoppingCartIcon,
              label: 'E-Cards'
            })
          }

          if (!isHelmsBriscoe) {
            subMenuItemsCampaigns.push({
              id: 'User Referrals',
              url: `${webSiteURL}campaigns.jsp?referral=true`,
              Icon: RecordVoiceOverIcon,
              label: 'Referrals'
            })
          }

          subMenuItemsCampaigns.push({
            id: 'Email Queue',
            url: `${webSiteURL}user_queue_manager.jsp`,
            Icon: StorageIcon,
            label: 'Email Queue'
          }, {
            id: 'Scheduled Sendings',
            url: `${webSiteURL}scheduled_sendings.jsp`,
            Icon: EventIcon,
            label: 'Scheduled Sendings'
          })

          const subMenuItemsMembers = [
            {
              id: 'View Contacts',
              url: `${webSiteURL}view_members.jsp?returnToPrevious=&criteria=${encodeURIComponent('{"statusList":["Y"]}')}&init=true`,
              Icon: ContactsIcon,
              label: 'View Contacts'
            },
            {
              id: 'User Add Contacts',
              url: `${webSiteURL}member_add_multiple.jsp`,
              Icon: GroupAddIcon,
              label: 'Add Contacts'
            },
            {
              id: 'User Lists',
              url: `${webSiteURL}categories.jsp`,
              Icon: ListAltIcon,
              label: i18n['category.label.plural']
            },
            {
              id: 'User Saved Searches',
              url: `${webSiteURL}list_manager.jsp`,
              Icon: SavedSearchIcon,
              label: 'Saved Searches'
            },
            {
              id: 'User Advanced Contact Search',
              url: `${webSiteURL}memberSearch.jsp`,
              Icon: SearchIcon,
              label: 'Search'
            },
            accesseeBillingPaid || accesseeBillingId === 0 ? {
              id: 'User Custom Fields',
              url: `${webSiteURL}customFields.jsp`,
              Icon: BallotIcon,
              label: 'Custom Fields'
            } : {
              id: 'User Custom Fields',
              url: `${webSiteURL}client_admin_billing.jsp?function=upgrade&amp;feature=${encodeURIComponent('Custom Fields')}`,
              Icon: AddShoppingCartIcon,
              label: 'Custom Fields'
            },
            {
              id: 'User Import Contacts',
              url: `${webSiteURL}member_import.jsp`,
              Icon: SaveAlt,
              label: 'Import'
            }
          ]
          if (hasExportEnabled) {
            subMenuItemsMembers.push(accesseeBillingPaid || accesseeBillingId === 0 ? {
              id: 'User Export Contacts',
              url: `${webSiteURL}client_admin_export_members.jsp`,
              Icon: GetAppIcon,
              label: 'Export'
            } : {
              id: 'User Export Contacts',
              url: `${webSiteURL}client_admin_billing.jsp?function=upgrade&feature=${encodeURIComponent('Export Members')}`,
              Icon: TrendingUp,
              label: 'Export'
            })
          }

          const subMenuItemsSubUsers = []
          if (campaignSharingEnabled && accesseeCampaignSharing && accesseeSubUsers && accesseeSubUsers.length) {
            subMenuItemsSubUsers.push({
              id: 'User Assign Shared Email Campaigns',
              url: `${webSiteURL}client_admin_assign_campaigns.jsp`,
              Icon: FolderSharedIcon,
              label: 'Shared Email Campaigns'
            })
          }

          const subMenuItemsReports = [
            {
              id: 'User Monthly Activity Reports',
              url: `${webSiteURL}report_monthly_activity.jsp`,
              Icon: AssessmentIcon,
              label: 'Monthly Activity'
            },
            {
              id: 'User Contact Report',
              url: `${webSiteURL}report_member_activity.jsp`,
              Icon: RecentActorsIcon,
              label: 'Contact Activity'
            }
          ]
          if (isAbuseMonitoringEnabled) {
            subMenuItemsReports.push({
              id: 'User Complaints',
              url: `${webSiteURL}complaints.jsp`,
              Icon: GavelIcon,
              label: 'Complaints'
            })
          }

          const subMenuItemsMyAccount = [
            {
              id: 'User Settings',
              url: `${webSiteURL}user.jsp`,
              Icon: SettingsIcon,
              label: 'Settings'
            }
          ]

          if (accesseeBillingId !== 0) {
            subMenuItemsMyAccount.push({
              id: 'User Billing Upgrade Plan',
              url: `${webSiteURL}client_admin_billing.jsp?function=upgrade`,
              Icon: TrendingUp,
              label: 'Upgrade Plan'
            })
          }

          subMenuItemsMyAccount.push({
            id: 'User Forms & Links',
            url: `${webSiteURL}client_admin_forms.jsp`,
            Icon: PlaylistAddIcon,
            label: 'Forms & Links'
          })

          if (!isHelmsBriscoe && (accesseeTemplateUploadEnabled || accessorTemplateUploadEnabled)) {
            subMenuItemsMyAccount.push({
              id: 'User Template Library',
              url: `${webSiteURL}templates.jsp`,
              Icon: LibraryBooksIcon,
              label: i18n['menu.userTemplateLibrary']
            })
          }
          subMenuItemsMyAccount.push({
            id: 'User Image & File Library',
            onClick: openImageAndFileLibrary,
            Icon: PhotoLibraryIcon,
            label: i18n['menu.userFileLibrary']
          }, {
            id: 'User Notifications',
            url: `${webSiteURL}client_admin_notifications.jsp`,
            Icon: NotificationsIcon,
            label: 'Notifications'
          }, {
            id: 'User Integrations',
            url: `${webSiteURL}integrations.jsp`,
            Icon: SyncAlt,
            label: 'Integrations'
          }, {
            id: 'API Keys',
            url: `${webSiteURL}user.jsp?apiKeys#apiKeys`,
            Icon: VpnKeyIcon,
            label: 'API Keys'
          })

          if (accesseeBillingId !== 0) {
            subMenuItemsMyAccount.push({
              id: 'User Billing Invoices',
              url: `${webSiteURL}client_admin_billing.jsp?function=invoices`,
              Icon: ReceiptIcon,
              label: 'Invoices'
            }, {
              id: 'User Billing Change Billing Info',
              url: `${webSiteURL}client_admin_billing.jsp?function=details`,
              Icon: ContactMailIcon,
              label: 'Billing Info'
            })
            subMenuItemsMyAccount.push(accesseeBillingPaid ? {
              id: 'User Request Template',
              url: `${webSiteURL}client_admin_billing.jsp?function=services`,
              Icon: NoteAddIcon,
              label: 'Request Template'
            } : {
              id: 'User Request Template',
              url: `${webSiteURL}client_admin_billing.jsp?function=upgrade&amp;feature=${encodeURIComponent('Custom Template')}`,
              Icon: AddShoppingCartIcon,
              label: 'Request Template'
            })
            subMenuItemsMyAccount.push({
              id: 'Purchase Services or Support',
              url: `${webSiteURL}client_admin_billing.jsp?function=services`,
              Icon: AddShoppingCartIcon,
              label: 'Purchase Services'
            })
          }

          const subMenuIloyal = [
            {
              id: 'Publisher',
              url: 'https://genius.iloyal.com/#publish',
              target: '_blank',
              Icon: PublishIcon,
              label: 'Publisher'
            },
            {
              id: 'Scheduled Posts',
              url: 'https://genius.iloyal.com/#publish/scheduled',
              target: '_blank',
              Icon: EventIcon,
              label: 'Scheduled Posts'
            },
            {
              id: 'Activity',
              url: 'https://genius.iloyal.com/#activity/inbox',
              target: '_blank',
              Icon: InboxIcon,
              label: 'Activity'
            },
            {
              id: 'Reports',
              url: 'https://genius.iloyal.com/#measure',
              target: '_blank',
              Icon: BarChartIcon,
              label: 'Reports'
            },
            {
              id: 'Request Demo',
              url: 'https://calendly.com/loveandloyalty/discovery',
              target: '_blank',
              Icon: RequestDemoIcon,
              label: 'Request Demo'
            },
            {
              id: 'Create Account',
              url: 'https://genius.iloyal.com/#signup',
              target: '_blank',
              Icon: PersonAddIcon,
              label: 'Create Account'
            }
          ]

          const subMenuItemsHelp = []
          if (accesseeBillingId !== 0) {
            subMenuItemsHelp.push({
              id: 'View Support Tickets',
              url: `${webSiteURL}client_admin_billing.jsp?function=support_tickets`,
              Icon: SpeakerNotes,
              label: 'View Support Tickets'
            }, {
              id: 'Create Support Ticket',
              url: `${webSiteURL}client_admin_billing.jsp?function=support_create_ticket`,
              Icon: RateReviewIcon,
              label: 'Create Support Ticket'
            })
          }
          subMenuItemsHelp.push({
            id: 'Developer Docs',
            url: `${webSiteURL}api/v2docs.jsp`,
            Icon: CodeIcon,
            label: 'Developer Docs'
          })
          if (accesseeBillingId !== 0) {
            subMenuItemsHelp.push({
              id: 'Cancel Service',
              url: `${webSiteURL}client_admin_billing.jsp?function=cancel`,
              Icon: HighlightOffIcon,
              label: 'Cancel Service'
            })
          }
          subMenuItemsHelp.push({
            id: 'Contact Support',
            url: `mailto:${systemEmailFromAddress}`,
            Icon: EmailIcon,
            label: 'Contact Support'
          })
          if (userFeedbackEnabled) {
            subMenuItemsHelp.push({
              id: 'Send Feedback',
              onClick: openFeedback,
              Icon: FeedbackIcon,
              label: 'Send Feedback'
            })
          }

          menuItems = (
            <>
              {(accesseeCampaignsMenuEnabled || accessorCampaignsMenuEnabled) &&
                <>
                  <h3 className={`mdc-list-group__subheader${menuItem === 'Email Campaigns' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                    {type === 'permanent' &&
                      <MDCRipple
                        tagName='span'
                        className='mdc-ripple-surface'
                      />}
                    Email Campaigns
                  </h3>
                  <hr className='mdc-list-divider' />
                  <nav id='fd_email_campaigns_nav_menu' className='mdc-list'>
                    <MDCRipple
                      tagName='a'
                      className={`mdc-list-item${!subMenuItem && menuItem === 'Email Campaigns' ? ' mdc-list-item--activated' : ''}`}
                      href={`${webSiteURL}campaigns.jsp`}
                      target='_self'
                    >
                      <DescriptionIcon className='mdc-list-item__graphic' />
                      Active
                    </MDCRipple>
                    {subMenuItemsCampaigns.map(renderSubMenuItems)}
                  </nav>
                  <hr className='mdc-list-divider' />
                </>}

              {(hasContactMenuEnabled) &&
                <>
                  <h3 className={`mdc-list-group__subheader${menuItem === 'Contacts' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                    {type === 'permanent' &&
                      <MDCRipple
                        tagName='span'
                        className='mdc-ripple-surface'
                      />}
                Contacts
                  </h3>
                  <hr className='mdc-list-divider' />
                  <nav id='fd_contacts_nav_menu' className='mdc-list'>
                    <MDCRipple
                      tagName='a'
                      className={`mdc-list-item${!subMenuItem && menuItem === 'Contacts' ? ' mdc-list-item--activated' : ''}`}
                      href={`${webSiteURL}members.jsp`}
                      target='_self'
                    >
                      <DashboardIcon className='mdc-list-item__graphic' />
                  Dashboard
                    </MDCRipple>
                    {subMenuItemsMembers.map(renderSubMenuItems)}
                  </nav>
                  <hr className='mdc-list-divider' />
                </>}
              {!isHelmsBriscoe && accesseeParentUserId === 0 && accesseeSubUsers && !!accesseeSubUsers.length &&
                <>
                  <h3 className={`mdc-list-group__subheader${menuItem === 'User Sub Users' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                    {type === 'permanent' &&
                      <MDCRipple
                        tagName='span'
                        className='mdc-ripple-surface'
                      />}
                    Sub Users
                  </h3>
                  <hr className='mdc-list-divider' />
                  <nav id='fd_sub_users_nav_menu' className='mdc-list'>
                    <MDCRipple
                      tagName='a'
                      className={`mdc-list-item${!subMenuItem && menuItem === 'User Sub Users' ? ' mdc-list-item--activated' : ''}`}
                      href={`${webSiteURL}client_admin_sub_clients.jsp`}
                      target='_self'
                    >
                      <PeopleIcon className='mdc-list-item__graphic' />
                      View Sub Users
                    </MDCRipple>
                    {subMenuItemsSubUsers.map(renderSubMenuItems)}
                  </nav>
                  <hr className='mdc-list-divider' />
                </>}
              {(accesseeReportsMenuEnabled || accessorReportsMenuEnabled) && hasReportMenuEnabled &&
                <>
                  <h3 className={`mdc-list-group__subheader${menuItem === 'Reports' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                    {type === 'permanent' &&
                      <MDCRipple
                        tagName='span'
                        className='mdc-ripple-surface'
                      />}
                    Reports
                  </h3>
                  <hr className='mdc-list-divider' />
                  <nav id='fd_reports_nav_menu' className='mdc-list'>
                    <MDCRipple
                      tagName='a'
                      className={`mdc-list-item${!subMenuItem && menuItem === 'Reports' ? ' mdc-list-item--activated' : ''}`}
                      href={`${webSiteURL}reports.jsp`}
                      target='_self'
                    >
                      <BarChartIcon className='mdc-list-item__graphic' />
                      Email Campaigns
                    </MDCRipple>
                    {subMenuItemsReports.map(renderSubMenuItems)}
                  </nav>
                  <hr className='mdc-list-divider' />
                </>}
              {(accesseeAccountMenuEnabled || accessorAccountMenuEnabled) && !!subMenuItemsMyAccount.length && hasAccountMenuEnabled &&
                <>
                  <h3 className={`mdc-list-group__subheader${menuItem === 'My Account' && subMenuItemsMyAccount.findIndex(({ id }) => id === subMenuItem) !== -1 ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                    {type === 'permanent' &&
                      <MDCRipple
                        tagName='span'
                        className='mdc-ripple-surface'
                      />}
                    Account
                  </h3>
                  <hr className='mdc-list-divider' />
                  <nav id='fd_my_account_nav_menu' className='mdc-list'>
                    <MDCRipple
                      tagName='a'
                      className={`mdc-list-item${!subMenuItem && menuItem === 'My Account' ? ' mdc-list-item--activated' : ''}`}
                      role='menuitem'
                      id='Account Dashboard'
                      href={`${webSiteURL}client_admin.jsp`}
                      target='_self'
                    >
                      <DashboardIcon className='mdc-list-item__graphic' />
                      Account Dashboard
                    </MDCRipple>

                    {subMenuItemsMyAccount.map(renderSubMenuItems)}
                  </nav>
                  <hr className='mdc-list-divider' />
                </>}
              {isIloyal &&
                <>
                  <h3 className={`mdc-list-group__subheader${menuItem === 'Social Genius App' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                    {type === 'permanent' &&
                      <MDCRipple
                        tagName='span'
                        className='mdc-ripple-surface'
                      />}
                    Social Genius App
                  </h3>
                  <hr className='mdc-list-divider' />
                  <nav id='iloyal_nav_menu' className='mdc-list'>
                    <MDCRipple
                      tagName='a'
                      className={`mdc-list-item${!subMenuItem && menuItem === 'Social Genius App' ? ' mdc-list-item--activated' : ''}`}
                      href='https://genius.iloyal.com/#login'
                      target='_blank'
                    >
                      <ExitToAppIcon className='mdc-list-item__graphic' />
                       Login
                    </MDCRipple>
                    {subMenuIloyal.map(renderSubMenuItems)}
                  </nav>
                  <hr className='mdc-list-divider' />
                </>}
              <h3 className={`mdc-list-group__subheader${menuItem === 'Help' ? ' fd-rotate fd-expanded-nav expanded' : ''}`} onClick={type === 'permanent' ? onPermanentSubHeaderClick : null}>
                {type === 'permanent' &&
                  <MDCRipple
                    tagName='span'
                    className='mdc-ripple-surface'
                  />}
                Help
              </h3>
              <hr className='mdc-list-divider' />
              <nav id='fd_help_nav_menu' className='mdc-list'>
                <MDCRipple
                  tagName='a'
                  className={`mdc-list-item${!subMenuItem && menuItem === 'Help' ? ' mdc-list-item--activated' : ''}`}
                  href={`${webSiteURL}help_support.jsp`}
                  target='_self'
                >
                  <DashboardIcon className='mdc-list-item__graphic' />
                  Dashboard
                </MDCRipple>
                <MDCRipple
                  tagName='a'
                  className='mdc-list-item'
                  href={knowledgeBaseWebSiteURL}
                  target='_blank'
                >
                  <HelpIcon className='mdc-list-item__graphic' />
                  Knowledge Base
                </MDCRipple>
                {subMenuItemsHelp.map(renderSubMenuItems)}
              </nav>
            </>
          )
          break
        }
        case 'accountRep':
        case 'designer':
          // These account types have been removed from the application
          break
        default:
          // This should be impossible...
          break
      }
  }

  return (
    <>
      <div className='mdc-list-group'>
        {menuItems}
      </div>
      <hr className='mdc-list-divider' />
      <div className='fd-changelog'>
        Email Marketing Platform
        {!!buildNumber &&
          <>
            <span> </span>
            <Tooltip title={buildNumber}>
              <span>{buildNumber}</span>
            </Tooltip>
          </>}
        {accessee !== null && ((accesseeUserType === 'admin' && showResellerChangeLog) || (accesseeUserType !== 'admin' && showUserChangeLog)) &&
          <>
            <br />
            <a href={`${webSiteURL}changeLog.jsp`}>View Change Log</a>
          </>}
        <br />
        <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 176.6 209.27' enableBackground='new 0 0 176.6 209.27' width='60' height='72' className='fd-made-in-usa' onClick={onClickMadeInUsa}>
          <g>
            <path fill='#292E6B' d='M24.83,39.009c-2.078-2.298-4.568-5.101-6.439-7.464l-0.094,0.077c1.49,2.671,3.004,5.692,4.168,8.157 l4.83,10.453l-2.307,1.875l-8.945-6.737c-2.316-1.685-5.043-3.876-7.35-5.937L8.6,39.511c1.922,2.427,4.082,5.34,6.012,7.97 l6.225,8.426l-2.938,2.39L2,35.852l3.57-2.904l9.066,6.953c2.9,2.208,5.262,4.014,7.625,6.08l0.063-0.051 c-1.574-2.709-2.898-5.306-4.527-8.6l-5.025-10.241l3.633-2.955l18.582,20.261l-3.064,2.492L24.83,39.009z' />
            <path fill='#292E6B' d='M37.168,35.478l1.947,7.018l-3.721,1.933l-7.23-27.168l4.48-2.328l17.672,21.745l-3.758,1.952 l-4.584-5.648L37.168,35.478z M40,30.152l-3.922-5.075c-1.113-1.439-2.604-3.511-3.738-5.077l-0.107,0.056 c0.592,1.849,1.451,4.385,1.951,6.053l1.842,6.108L40,30.152z' />
            <path fill='#292E6B' d='M43.789,10.339c1.107-0.688,2.717-1.407,4.504-1.968c3.031-0.951,5.438-0.896,7.443,0.055 c2.734,1.232,5.127,4.236,6.773,9.481c1.768,5.633,1.479,9.606,0.107,12.171c-1.188,2.336-3.484,3.995-6.863,5.057 c-1.438,0.451-2.912,0.785-3.893,0.922L43.789,10.339z M54.967,31.54c0.375-0.032,0.764-0.153,1.035-0.239 c2.875-0.902,4.721-4.127,2.223-12.092c-1.865-5.943-4.609-8.838-8.223-7.704c-0.428,0.134-0.842,0.308-1.168,0.494L54.967,31.54z' />
            <path fill='#292E6B' d='M77.777,17.322L71.33,18.26l1.254,8.624l7.334-1.066l0.545,3.747l-11.646,1.693l-3.949-27.16l11.162-1.623 l0.545,3.747l-6.85,0.996l1.084,7.456l6.447-0.938L77.777,17.322z' />
            <path fill='#292E6B' d='M93.307,2.092L92.73,29.53l-4.355-0.091L88.951,2L93.307,2.092z' />
            <path fill='#292E6B' d='M96.293,29.476l4.227-27.12l3.943,0.615l2.781,12.303c0.584,2.605,1.406,6.318,1.844,9.066l0.08,0.012 c0.299-3.498,0.709-7.721,1.467-12.589l1.191-7.646l3.783,0.59l-4.227,27.12l-3.943-0.614l-2.828-12.27 c-0.617-2.651-1.316-6.1-1.635-9.075l-0.08-0.013c-0.289,3.17-0.764,7.012-1.643,12.645l-1.178,7.564L96.293,29.476z' />
            <path fill='#292E6B' d='M134.307,10.559l-6.986,16.673c-1.715,4.093-0.988,5.942,0.59,6.603c1.764,0.74,3.463-0.049,5.193-4.18 l6.984-16.673l4.02,1.683l-6.703,15.998c-2.736,6.533-6.617,8.527-10.975,6.703c-4.617-1.936-5.564-5.996-2.922-12.305 l6.779-16.186L134.307,10.559z' />
            <path fill='#292E6B' d='M138.203,37.342c0.539,1.059,1.795,2.425,3.002,3.183c2.277,1.429,4.342,0.898,5.553-1.032 c1.17-1.863,0.703-3.646-0.371-6.147c-1.547-3.279-1.889-6.716-0.309-9.233c2.273-3.621,6.52-4.945,10.863-2.217 c1.484,0.931,2.547,2.223,2.93,2.992l-2.646,2.762c-0.396-0.826-1.123-1.811-2.365-2.591c-2.104-1.321-3.99-0.534-4.879,0.879 c-1.125,1.794-0.715,3.206,0.713,6.217c1.719,3.772,1.482,6.749-0.076,9.232c-2.619,4.173-7.252,4.966-11.426,2.347 c-1.723-1.084-3.18-2.67-3.6-3.607L138.203,37.342z' />
            <path fill='#292E6B' d='M155.564,46.309l-5.824,4.371l-3.156-2.762l22.666-16.63l3.799,3.324L159.238,58.99l-3.188-2.788 l3.59-6.327L155.564,46.309z M161.555,47.006l3.299-5.5c0.934-1.563,2.32-3.703,3.365-5.33l-0.092-0.081 c-1.506,1.226-3.555,2.949-4.926,4.021l-5.016,3.94L161.555,47.006z' />
          </g>
          <g>
            <path fill='#FFFFFF' d='M113.502,134.322h60.352c0.473-3.561,0.744-7.184,0.744-10.871c0-3.717-0.273-7.371-0.756-10.961h-60.34 V134.322z' />
            <path fill='#FFFFFF' d='M113.502,86.9h52.488c-4.039-8.094-9.387-15.449-15.762-21.832h-36.727V86.9z' />
            <path fill='#FFFFFF' d='M10.82,159.912c4.025,8.092,9.361,15.447,15.721,21.832h123.775c6.359-6.385,11.695-13.74,15.721-21.832 H10.82z' />
            <path fill='#BD2426' d='M113.502,112.49h60.34c-1.219-9.063-3.904-17.676-7.852-25.59h-52.488V112.49z' />
            <path fill='#BD2426' d='M113.502,43.248v21.82h36.727C140.203,55.029,127.625,47.42,113.502,43.248z' />
            <path fill='#BD2426' d='M173.854,134.322h-60.352v25.379H10.715c0.033,0.07,0.07,0.141,0.105,0.211h155.217 C169.975,151.998,172.645,143.385,173.854,134.322z' />
            <path fill='#292E6B' d='M88.428,39.633c-24.248,0-46.143,9.756-61.801,25.436c-6.373,6.383-11.721,13.738-15.76,21.832 c-3.949,7.914-6.635,16.527-7.852,25.59c-0.482,3.59-0.758,7.244-0.758,10.961c0,3.688,0.271,7.311,0.746,10.871 c1.197,8.982,3.83,17.523,7.711,25.379h102.787v-25.379V112.49V86.9V65.068v-21.82C105.566,40.904,97.15,39.633,88.428,39.633z' />
            <path fill='#BD2426' d='M88.428,207.271c24.295,0,46.225-9.795,61.889-25.527H26.541 C42.205,197.477,64.135,207.271,88.428,207.271z' />
            <polygon fill='#FFFFFF' points='65.326,66.967 76.225,89.723 101.234,93.057 82.959,110.453 87.52,135.271 65.326,123.266 43.133,135.271 47.691,110.453 29.416,93.057 54.428,89.723' />
          </g>
        </svg>
      </div>
    </>
  )
}

MainMenu.propTypes = {
  onClickMadeInUsa: PropTypes.func,
  type: PropTypes.string
}

export default MainMenu
