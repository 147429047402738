import PropTypes from 'prop-types'
import React, { useCallback, useContext } from 'react'

import SelectEventContext from './SelectEventContext'

const AgendaEvent = ({ event, title }) => {
  const onSelectEvent = useContext(SelectEventContext)

  const onClick = useCallback(e => {
    onSelectEvent(event, e)
  }, [event, onSelectEvent])

  return onSelectEvent ? (
    <button className='fd-link-button' onClick={onClick}>
      {title}
    </button>
  ) : title
}

AgendaEvent.propTypes = {
  title: PropTypes.string,
  event: PropTypes.shape()
}

export default AgendaEvent
