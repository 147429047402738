import { eventChannel } from 'redux-saga'
import { all, call, cancel, cancelled, fork, put, race, take } from 'redux-saga/effects'
import uuidv4 from 'uuid/v4'

import { START_WEBSOCKET, STOP_WEBSOCKET, SEND_TO_WEBSOCKET } from '../constants/websocket'
import { webSocketDataReceived } from '../actions/websocket'

const WEB_SOCKET_URL = `${global.location.origin}/`.replace(/(^\w+:|^)\/\//, 'wss://')

const initWebSocket = (endpoint, dataKey) => {
  const uuid = uuidv4()
  const ws = new global.WebSocket(WEB_SOCKET_URL + endpoint)

  ws.onopen = () => {
    console.debug(`[WEBSOCKET - ${uuid}] - Connected to ${endpoint}`) // eslint-disable-line no-console
  }

  ws.onerror = (error) => {
    if (ws.readyState === global.WebSocket.OPEN) {
      console.debug(`[WEBSOCKET - ${uuid}] - Error detected!`, error) // eslint-disable-line no-console
    }
  }

  return {
    [`${dataKey}Socket`]: ws,
    [`${dataKey}Channel`]: eventChannel(emit => {
      ws.onclose = ({ type }) => {
        console.debug(`[WEBSOCKET - ${uuid}] - Disconnected from ${endpoint}`) // eslint-disable-line no-console

        switch (type) {
          case 1000: // Normal Closure: The connection successfully completed whatever purpose for which it was created.
          case 1001: // Going Away: The endpoint is going away, either because of a server failure or because the browser is navigating away from the page that opened the connection.
          case 1010: // Missing Extension: The client is terminating the connection because it expected the server to negotiate one or more extension, but the server didn't.
          case 4401: // FireDrum Specific: Unauthorized (missing auth credentials)
            break
          case 1002: // Protocol Error: The endpoint is terminating the connection due to a protocol error.
          case 1003: // Unsupported Data: The connection is being terminated because the endpoint received data of a type it cannot accept (for example, a text-only endpoint received binary data).
          case 1005: // No Status Received: Reserved.  Indicates that no status code was provided even though one was expected.
          case 1006: // Abnormal Closure: Reserved. Used to indicate that a connection was closed abnormally (that is, with no close frame being sent) when a status code is expected.
          case 1007: // Invalid frame payload data: The endpoint is terminating the connection because a message was received that contained inconsistent data (e.g., non-UTF-8 data within a text message).
          case 1008: // Policy Violation: The endpoint is terminating the connection because it received a message that violates its policy. This is a generic status code, used when codes 1003 and 1009 are not suitable.
          case 1009: // Message too big: The endpoint is terminating the connection because a data frame was received that is too large.
          case 1011: // Internal Error: The server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.
          case 1012: // Service Restart: The server is terminating the connection because it is restarting. [Ref]
          case 1013: // Try Again Later: The server is terminating the connection due to a temporary condition, e.g. it is overloaded and is casting off some of its clients. [Ref]
          case 1014: // Bad Gateway: The server was acting as a gateway or proxy and received an invalid response from the upstream server. This is similar to 502 HTTP Status Code.
          case 1015: // TLS Handshake: Reserved. Indicates that the connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).
          default:
            emit({
              type: 'restart',
              dataKey
            })
            break
        }
      }

      ws.onmessage = ({ data }) => {
        let message = null
        try {
          message = JSON.parse(data)
        } catch (e) {
          console.error(`[WEBSOCKET - ${uuid}] - Error parsing message: ${data}`)
        }

        if (message) {
          const { type, payload } = message
          switch (type) {
            case 'data':
              emit({
                type,
                payload,
                dataKey
              })
              break
            case 'error':
              switch (payload.errorCode) {
                default:
                  emit(new Error(payload.message))
                  break
              }
              break
            default:
              break
          }
        }
      }

      return () => {
        ws.close(1000)
      }
    })
  }
}

function * executeTask (socketType, socket) {
  while (true) {
    const { dataKey, payload } = yield take(SEND_TO_WEBSOCKET)
    if (dataKey === socketType) {
      socket.send(JSON.stringify(payload))
    }
  }
}

function * backgroundTask (socketChannel) {
  while (true) {
    const { type, payload, dataKey } = yield take(socketChannel)
    switch (type) {
      case 'restart':
        break
      case 'data':
        global.emailMarketingPlatform[dataKey] = payload[dataKey]
        global.fdFireCustomEvent(`emailMarketingPlatform.${dataKey}`, global.emailMarketingPlatform[dataKey])
        yield put(webSocketDataReceived(dataKey, payload))
        break
      default:
        break
    }
  }
}

function * restartHandler (socketChannel) {
  while (true) {
    const { type } = yield take(socketChannel)
    switch (type) {
      case 'restart':
        return true
      default:
        break
    }
  }
}

function * startCustomFieldsSocket () {
  while (true) {
    const { customFieldsSocket, customFieldsChannel } = yield call(initWebSocket, 'ws/customFields', 'customFields')

    try {
      yield race({
        task: all([
          call(backgroundTask, customFieldsChannel),
          call(executeTask, 'customFields', customFieldsSocket)
        ]),
        restart: call(restartHandler, customFieldsChannel)
      })
    } finally {
      customFieldsChannel.close()

      if (yield cancelled()) {
        return // eslint-disable-line no-unsafe-finally
      }
    }
  }
}

function * startVerifiedEmailAddressesSocket () {
  while (true) {
    const { verifiedEmailAddressesSocket, verifiedEmailAddressesChannel } = yield call(initWebSocket, 'ws/verifiedEmailAddresses', 'verifiedEmailAddresses')

    try {
      yield race({
        task: all([
          call(backgroundTask, verifiedEmailAddressesChannel),
          call(executeTask, 'verifiedEmailAddresses', verifiedEmailAddressesSocket)
        ]),
        restart: call(restartHandler, verifiedEmailAddressesChannel)
      })
    } finally {
      verifiedEmailAddressesChannel.close()

      if (yield cancelled()) {
        return // eslint-disable-line no-unsafe-finally
      }
    }
  }
}

function * startSockets () {
  return {
    customFieldsTask: yield fork(startCustomFieldsSocket),
    verifiedEmailAddressesTask: yield fork(startVerifiedEmailAddressesSocket)
  }
}

function * websocketSaga () {
  if (global.WebSocket) {
    while (true) {
      yield take(START_WEBSOCKET)

      const { customFieldsTask, verifiedEmailAddressesTask } = yield call(startSockets)

      yield take(STOP_WEBSOCKET)

      yield cancel(customFieldsTask)
      yield cancel(verifiedEmailAddressesTask)
    }
  }
}

export default websocketSaga
