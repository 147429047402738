import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import DashboardIcon from '@material-ui/icons/Dashboard'
import EmailIcon from '@material-ui/icons/Email'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PhoneIcon from '@material-ui/icons/Phone'
import ReceiptIcon from '@material-ui/icons/Receipt'
import SettingsIcon from '@material-ui/icons/Settings'
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
// import UpdateIcon from '@material-ui/icons/Update'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SyncAlt from '@material-ui/icons/SyncAlt'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import TrendingUp from '@material-ui/icons/TrendingUp'

import LogoutIcon from '../icons/Logout'

import MDCRipple from './MDCComponents/Ripple'

import Tooltip from './MUIComponents/Tooltip'

import {
  selectAccesseeAccountRepBusinessPhone,
  selectAccesseeAccountRepContactFirstName,
  selectAccesseeAccountRepContactLastName,
  selectAccesseeAccountRepFirstContactEmailAddress,
  selectAccesseeBillingId,
  selectAccesseeBusinessName,
  selectAccesseeContactFirstName,
  selectAccesseeContactLastName,
  selectAccesseeDesignerBusinessPhone,
  selectAccesseeDesignerContactFirstName,
  selectAccesseeDesignerContactLastName,
  selectAccesseeDesignerFirstContactEmailAddress,
  selectAccesseeFirstContactEmailAddress,
  selectAccesseeGravatarEmailHex,
  selectAccesseeId,
  selectAccesseeParentUserId,
  selectAccesseeUsername,
  selectAccesseeUserType,
  selectAccessorId,
  selectIsHelmsBriscoe,
  selectResellerId,
  selectMenuItem,
  selectSubMenuItem,
  selectHasAccountMenuEnabled,
  selectAccesseeTemplateUploadEnabled,
  selectAccessorTemplateUploadEnabled,
  selectAccesseeBillingPaid,
  selectI18N,
  selectWebSiteURL
} from '../selectors/application'

const openImageAndFileLibrary = () => {
  if (typeof global.loadmoxie === 'function') {
    global.loadmoxie()
  }
}

const ProfileMenu = ({ buttonRef }) => {
  const rootEl = useRef(null)
  const buttonEl = useRef(null)

  useEffect(() => {
    const el = rootEl.current
    if (buttonRef) {
      buttonRef.current = buttonEl.current
    }
    const button = buttonEl.current

    const onClick = () => {
      if (!el.MDCMenu.open) {
        el.MDCMenu.open = true
      }
    }

    el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
    el.MDCMenu = new global.mdc.menu.MDCMenu(el)
    el.MDCMenu.setAnchorElement(button.parentNode)
    el.MDCMenu.setAnchorCorner(global.mdc.menu.Corner.BOTTOM_LEFT)
    el.MDCMenu.setIsHoisted(true)

    button.addEventListener('click', onClick, { passive: true })

    return () => {
      el.MDCRipple.destroy()
      delete el.MDCRipple

      el.MDCMenu.open = false
      el.MDCMenu.destroy()
      delete el.MDCMenu

      button.removeEventListener('click', onClick)
    }
  })

  const accesseeAccountRepBusinessPhone = useSelector(selectAccesseeAccountRepBusinessPhone)
  const accesseeAccountRepContactFirstName = useSelector(selectAccesseeAccountRepContactFirstName)
  const accesseeAccountRepContactLastName = useSelector(selectAccesseeAccountRepContactLastName)
  const accesseeAccountRepFirstContactEmailAddress = useSelector(selectAccesseeAccountRepFirstContactEmailAddress)
  const accesseeBillingId = useSelector(selectAccesseeBillingId)
  const accesseeBusinessName = useSelector(selectAccesseeBusinessName)
  const accesseeContactFirstName = useSelector(selectAccesseeContactFirstName)
  const accesseeContactLastName = useSelector(selectAccesseeContactLastName)
  const accesseeDesignerBusinessPhone = useSelector(selectAccesseeDesignerBusinessPhone)
  const accesseeDesignerContactFirstName = useSelector(selectAccesseeDesignerContactFirstName)
  const accesseeDesignerContactLastName = useSelector(selectAccesseeDesignerContactLastName)
  const accesseeDesignerFirstContactEmailAddress = useSelector(selectAccesseeDesignerFirstContactEmailAddress)
  const accesseeFirstContactEmailAddress = useSelector(selectAccesseeFirstContactEmailAddress)
  const accesseeGravatarEmailHex = useSelector(selectAccesseeGravatarEmailHex)
  const accesseeId = useSelector(selectAccesseeId)
  const accesseeParentUserId = useSelector(selectAccesseeParentUserId)
  const accesseeUsername = useSelector(selectAccesseeUsername)
  const accesseeUserType = useSelector(selectAccesseeUserType)
  const accesseeTemplateUploadEnabled = useSelector(selectAccesseeTemplateUploadEnabled)
  const accessorTemplateUploadEnabled = useSelector(selectAccessorTemplateUploadEnabled)
  const accessorId = useSelector(selectAccessorId)
  const accesseeBillingPaid = useSelector(selectAccesseeBillingPaid)
  const i18n = useSelector(selectI18N)
  const isHelmsBriscoe = useSelector(selectIsHelmsBriscoe)
  const resellerId = useSelector(selectResellerId)
  const menuItem = useSelector(selectMenuItem)
  const subMenuItem = useSelector(selectSubMenuItem)
  const hasAccountMenuEnabled = useSelector(selectHasAccountMenuEnabled)
  const webSiteURL = useSelector(selectWebSiteURL)

  const renderSubMenuItems = useCallback(({ id, url, Icon, label, target }) => (
    <MDCRipple
      tagName='a'
      key={id}
      className={`mdc-list-item${id === subMenuItem ? ' mdc-list-item--activated' : ''}`}
      href={url}
      target={target || '_self'}
    >
      <Icon className='mdc-list-item__graphic' />
      {label}
    </MDCRipple>
  ), [subMenuItem])

  let userDetails = null
  if (accessorId !== accesseeId) {
    let businessName = accesseeBusinessName
    if (!businessName) {
      if (accesseeContactFirstName || accesseeContactLastName) {
        businessName = `${accesseeContactFirstName} ${accesseeContactLastName}`
      } else {
        businessName = accesseeFirstContactEmailAddress || ''
      }
    }

    const company = `${resellerId !== accesseeId || accesseeParentUserId !== 0 ? 'Sub ' : ''}User: ${businessName}`
    userDetails = (
      <>
        <Tooltip title={company}>
          <div className='fd-user-company'>{company}</div>
        </Tooltip>
        <div className='fd-user-id'>ID: {accesseeId}</div>
      </>
    )
  }

  let menuItems = null
  switch (accesseeUserType) {
    case 'superAdmin':
      break
    case 'admin':
      menuItems = (
        <>
          <hr className='mdc-list-divider' />
          <MDCRipple
            tagName='a'
            className={`mdc-list-item${!subMenuItem && menuItem === 'My Account' ? ' mdc-list-item--activated' : ''}`}
            role='menuitem'
            href={`${webSiteURL}user.jsp`}
            target='_self'
          >
            <DashboardIcon className='mdc-list-item__graphic' />
            Account Dashboard
          </MDCRipple>
        </>
      )
      break
    case 'user': {
      const subMenuItemsMyAccount = []

      if (hasAccountMenuEnabled) {
        subMenuItemsMyAccount.push({
          id: 'User Settings',
          url: `${webSiteURL}user.jsp`,
          Icon: SettingsIcon,
          label: 'Settings'
        })

        if (accesseeBillingId !== 0) {
          subMenuItemsMyAccount.push({
            id: 'User Billing Upgrade Plan',
            url: `${webSiteURL}client_admin_billing.jsp?function=upgrade`,
            Icon: TrendingUp,
            label: 'Upgrade Plan'
          })
        }

        subMenuItemsMyAccount.push({
          id: 'User Forms & Links',
          url: `${webSiteURL}client_admin_forms.jsp`,
          Icon: PlaylistAddIcon,
          label: 'Forms & Links'
        })

        if (!isHelmsBriscoe && (accesseeTemplateUploadEnabled || accessorTemplateUploadEnabled)) {
          subMenuItemsMyAccount.push({
            id: 'User Template Library',
            url: `${webSiteURL}templates.jsp`,
            Icon: LibraryBooksIcon,
            label: i18n['menu.userTemplateLibrary']
          })
        }

        subMenuItemsMyAccount.push({
          id: 'User Image & File Library',
          onClick: openImageAndFileLibrary,
          Icon: PhotoLibraryIcon,
          label: i18n['menu.userFileLibrary']
        }, {
          id: 'User Notifications',
          url: `${webSiteURL}client_admin_notifications.jsp`,
          Icon: NotificationsIcon,
          label: 'Notifications'
        }, {
          id: 'User Integrations',
          url: `${webSiteURL}integrations.jsp`,
          Icon: SyncAlt,
          label: 'Integrations'
        }, {
          id: 'API Keys',
          url: `${webSiteURL}user.jsp?apiKeys#apiKeys`,
          Icon: VpnKeyIcon,
          label: 'API Keys'
        })
        if (accesseeBillingId !== 0) {
          subMenuItemsMyAccount.push({
            id: 'User Billing Invoices',
            url: `${webSiteURL}client_admin_billing.jsp?function=invoices`,
            Icon: ReceiptIcon,
            label: 'Invoices'
          }, {
            id: 'User Billing Change Billing Info',
            url: `${webSiteURL}client_admin_billing.jsp?function=details`,
            Icon: ContactMailIcon,
            label: 'Billing Info'
          })
          subMenuItemsMyAccount.push(accesseeBillingPaid ? {
            id: 'User Request Template',
            url: `${webSiteURL}client_admin_billing.jsp?function=services`,
            Icon: NoteAddIcon,
            label: 'Request Template'
          } : {
            id: 'User Request Template',
            url: `${webSiteURL}client_admin_billing.jsp?function=upgrade&amp;feature=${encodeURIComponent('Custom Template')}`,
            Icon: AddShoppingCartIcon,
            label: 'Request Template'
          })
          subMenuItemsMyAccount.push({
            id: 'Purchase Services or Support',
            url: `${webSiteURL}client_admin_billing.jsp?function=services`,
            Icon: AddShoppingCartIcon,
            label: 'Purchase Services'
          })
        }
      }
      menuItems = (
        <>
          <hr className='mdc-list-divider' />
          <MDCRipple
            tagName='a'
            className={`mdc-list-item${!subMenuItem && menuItem === 'My Account' ? ' mdc-list-item--activated' : ''}`}
            role='menuitem'
            id='Account Dashboard'
            href={`${webSiteURL}client_admin.jsp`}
            target='_self'
          >
            <DashboardIcon className='mdc-list-item__graphic' />
              Account Dashboard
          </MDCRipple>
          <nav id='fd_my_account_nav_menu' className='mdc-list'>
            {subMenuItemsMyAccount.map(renderSubMenuItems)}
          </nav>
        </>
      )
      break
    }
    case 'accountRep':
    case 'designer':
      break
    default:
      // This should be impossible...
      break
  }

  const portal = createPortal(
    <div ref={rootEl} className='fd-user-profile-menu mdc-menu mdc-menu-surface'>
      <div className='mdc-list-group'>
        <div className='mdc-list' role='menu' aria-hidden='true' aria-orientation='vertical'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                {isHelmsBriscoe &&
                  <img src={`https://www.gravatar.com/avatar/${accesseeGravatarEmailHex}?d=mp`} alt='Profile' />}
                {!isHelmsBriscoe &&
                  <Tooltip title='Change Your Profile Picture on Gravatar'>
                    <a className='fd-avatar' href='https://www.gravatar.com' target='_blank' rel='nofollow noopener noreferrer'>
                      <img src={`https://www.gravatar.com/avatar/${accesseeGravatarEmailHex}?d=mp`} alt='Profile' />
                      <div>Edit</div>
                    </a>
                  </Tooltip>}
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
                <Tooltip title={accesseeUsername}>
                  <div className='fd-user-name'>{accesseeUsername}</div>
                </Tooltip>
                <Tooltip title={accesseeFirstContactEmailAddress}>
                  <div className='fd-user-email'>{accesseeFirstContactEmailAddress}</div>
                </Tooltip>
                {userDetails}
                {accesseeAccountRepFirstContactEmailAddress &&
                  <div className='fd-account-rep'>
                    <br />
                    {isHelmsBriscoe() ? 'Communications Advisor:' : 'Account Rep:'}
                    <br />
                    <span className='fd-details'>
                      {accesseeAccountRepContactFirstName || ''}
                      &nbsp;
                      {accesseeAccountRepContactLastName || ''}
                      <br />
                      <a href={`mailto:${accesseeAccountRepFirstContactEmailAddress}`}>
                        <Tooltip title='Email'>
                          <EmailIcon />
                        </Tooltip>
                      </a>
                      {accesseeAccountRepBusinessPhone &&
                        <>
                          <span> </span>
                          <a href={`tel:${accesseeAccountRepBusinessPhone}`}>
                            <Tooltip title='Phone'>
                              <PhoneIcon />
                            </Tooltip>
                          </a>
                        </>}
                    </span>
                  </div>}
                {accesseeDesignerFirstContactEmailAddress &&
                  <div className='fd-designer'>
                    <br />
                    Designer:
                    <br />
                    <span className='fd-details'>
                      {accesseeDesignerContactFirstName || ''}
                      &nbsp;
                      {accesseeDesignerContactLastName || ''}
                      <br />
                      <a href={`mailto:${accesseeDesignerFirstContactEmailAddress}`}>
                        <Tooltip title='Email'>
                          <EmailIcon />
                        </Tooltip>
                      </a>
                      {accesseeDesignerBusinessPhone &&
                        <>
                          <span> </span>
                          <a href={`tel:${accesseeDesignerBusinessPhone}`}>
                            <Tooltip title='Phone'>
                              <PhoneIcon />
                            </Tooltip>
                          </a>
                        </>}
                    </span>
                  </div>}
              </div>
            </div>
          </div>
          {accessorId !== accesseeId &&
            <MDCRipple
              tagName='a'
              className='mdc-list-item'
              role='menuitem'
              href={`${webSiteURL}login.jsp?userId=${accessorId}`}
              target='_self'
            >
              <ExitToAppIcon className='mdc-list-item__graphic' />
              <span className='mdc-list-item__text'>Exit To Admin</span>
            </MDCRipple>}
          <MDCRipple
            tagName='a'
            className='mdc-list-item'
            role='menuitem'
            href={`${webSiteURL}logout.jsp`}
            target='_self'
          >
            <LogoutIcon className='mdc-list-item__graphic' />
            <span className='mdc-list-item__text'>Sign Out</span>
          </MDCRipple>
          {menuItems}
        </div>
      </div>
    </div>,
    document.body
  )
  return (
    <div className='mdc-menu-surface--anchor'>
      {accessorId !== accesseeId &&
        <Tooltip title='Impersonating User'>
          <SupervisorAccountIcon className='fd-pseudo-login-indicator' />
        </Tooltip>}
      <img ref={buttonEl} className='fd-avatar-button' src={`https://www.gravatar.com/avatar/${accesseeGravatarEmailHex}?d=mp`} alt='Profile' />
      {portal}

    </div>
  )
}

ProfileMenu.propTypes = {
  buttonRef: PropTypes.any
}

export default ProfileMenu
