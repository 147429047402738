import {
  FETCHING_REPORT_DATA,
  SET_REPORT_DATA
} from '../constants/reports'

export const initialState = {
  reportsData: {},
  fetchingReports: {}
}

export default function reportsReducer (state = initialState, action) {
  switch (action.type) {
    case FETCHING_REPORT_DATA:
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          [`${action.userId}|${action.report}`]: null
        },
        fetchingReports: {
          ...state.fetchingReports,
          [`${action.userId}|${action.report}`]: true
        }
      }
    case SET_REPORT_DATA: {
      const { data } = action

      let newState = null
      if (data !== null) {
        switch (action.report) {
          case 'contactsByStatus':
            newState = {
              data,
              numContacts: ['activeGroup', 'notActiveGroup', 'seamlessSignupForm', 'publicSignupForm', 'userForm', 'imported', 'api', 'deactivated', 'bounced', 'unsubscribed', 'complained']
                .map(key => data[key] || 0)
                .reduce((accumulator, value) => accumulator + value, 0)
            }
            break
          case 'contactTrends':
            newState = {
              data
            }
            break
          case 'mostRecentIssueStatistics':
            newState = {
              data
            }
            break
          case 'scheduledSendingEvents':
            newState = data
            break
          default:
            break
        }
      }

      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          [`${action.userId}|${action.report}`]: newState
        },
        fetchingReports: {
          ...state.fetchingReports,
          [`${action.userId}|${action.report}`]: false
        }
      }
    }
    default:
      return state
  }
}
