import ContactsIcon from '@material-ui/icons/Contacts'
import ListAltIcon from '@material-ui/icons/ListAlt'
import PaymentIcon from '@material-ui/icons/Payment'
import PersonIcon from '@material-ui/icons/Person'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import SearchIcon from '@material-ui/icons/Search'

import AlertDecagramIcon from '../icons/AlertDecagram'
import ScheduleSendIcon from '../icons/ScheduleSend'

const widgetSchemas = {
  ContactsByStatus: {
    icon: PersonIcon,
    title: 'Contacts By Status',
    settings: {
      desktopSize: 4,
      tabletSize: 8,
      phoneSize: 4
    }
  },
  ContactTrends: {
    icon: ContactsIcon,
    title: 'Contact Trends',
    settings: {
      desktopSize: 8,
      tabletSize: 8,
      phoneSize: 4,
      dateGrouping: 'day'
    }
  },
  MostRecentIssueStatistics: {
    icon: ScheduleSendIcon,
    title: 'Most Recent Email Campaign Sending',
    settings: {
      desktopSize: 9,
      tabletSize: 8,
      phoneSize: 4
    }
  },
  PricingPlan: {
    icon: PaymentIcon,
    title: 'Plan Details',
    settings: {
      desktopSize: 3,
      tabletSize: 8,
      phoneSize: 4
    }
  },
  SearchContacts: {
    icon: SearchIcon,
    title: 'Search Contacts',
    settings: {
      desktopSize: 4,
      tabletSize: 8,
      phoneSize: 4
    }
  },
  AddContact: {
    icon: PersonAddIcon,
    title: 'Add Contact',
    settings: {
      desktopSize: 4,
      tabletSize: 8,
      phoneSize: 4
    }
  },
  Lists: {
    icon: ListAltIcon,
    title: 'Lists Overview',
    settings: {
      desktopSize: 8,
      tabletSize: 8,
      phoneSize: 4
    }
  },
  GettingStarted: {
    icon: AlertDecagramIcon,
    title: 'Getting Started',
    settings: {
      desktopSize: 12,
      tabletSize: 8,
      phoneSize: 4
    }
  }
}

export default widgetSchemas
