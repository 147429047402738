import {
  FETCH_VERIFIED_EMAIL_ADDRESSES,
  FETCHING_VERIFIED_EMAIL_ADDRESSES,
  SET_VERIFIED_EMAIL_ADDRESSES,
  VERIFY_EMAIL_ADDRESS,
  VERIFYING_EMAIL_ADDRESS
} from '../constants/verifiedEmailAddresses'

export function fetchVerifiedEmailAddresses () {
  return {
    type: FETCH_VERIFIED_EMAIL_ADDRESSES
  }
}

export function fetchingVerifiedEmailAddresses () {
  return {
    type: FETCHING_VERIFIED_EMAIL_ADDRESSES
  }
}

export function setVerifiedEmailAddresses (verifiedEmailAddresses) {
  return {
    type: SET_VERIFIED_EMAIL_ADDRESSES,
    verifiedEmailAddresses
  }
}

export function verifyEmailAddress (emailAddress) {
  return {
    type: VERIFY_EMAIL_ADDRESS,
    emailAddress
  }
}

export function verifyingEmailAddress (verifying) {
  return {
    type: VERIFYING_EMAIL_ADDRESS,
    verifying
  }
}
