import PropTypes from 'prop-types'
import React from 'react'
import { useSpring, animated } from 'react-spring'

const AnimatedInfinity = props => {
  const { delay, ...otherProps } = props

  const { offset } = useSpring({
    from: {
      offset: 250
    },
    to: {
      offset: 0
    },
    delay: delay || 0
  })

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
      viewBox='0 0 88 44'
      fill='none'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit='10'
    >
      <animated.path
        style={{
          strokeDasharray: 250,
          strokeDashoffset: offset.interpolate(x => x)
        }}
        d='m44.25,21.60047c9.3,9.5 13.8,17.9 23.5,17.9s17.5,-7.8 17.5,-17.5s-7.8,-17.6 -17.5,-17.5c-9.7,0.1 -13.3,7.2 -22.1,17.1c-8.9,8.8 -15.7,17.9 -25.4,17.9s-17.5,-7.8 -17.5,-17.5s7.8,-17.5 17.5,-17.5s16.3,9.3 24,17.1z'
      />
    </svg>
  )
}

AnimatedInfinity.propTypes = {
  delay: PropTypes.number
}

export default AnimatedInfinity
