import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'

import Tooltip from '../MUIComponents/Tooltip'

const MDCCheckbox = ({ checked, id, indeterminate, label, name, onChange, style, title, value }) => {
  const rootEl = useRef(null)
  const checkboxEl = useRef(null)
  const inputEl = useRef(null)
  const mdcHandleChange = useRef(null)

  useEffect(() => {
    if (rootEl.current && checkboxEl.current && inputEl.current) {
      const el = checkboxEl.current
      const formFieldEl = rootEl.current

      formFieldEl.MDCFormField = new global.mdc.formField.MDCFormField(formFieldEl)
      el.MDCCheckbox = formFieldEl.MDCFormField.input = new global.mdc.checkbox.MDCCheckbox(el)

      // HACK: The implementation of MDCCheckbox breaks when using DOM events!
      inputEl.current.removeEventListener('change', el.MDCCheckbox.handleChange_)
      mdcHandleChange.current = el.MDCCheckbox.handleChange_

      return function cleanup () {
        el.MDCCheckbox.destroy()
        formFieldEl.MDCFormField.destroy()

        delete el.MDCCheckbox
        delete formFieldEl.MDCFormField
      }
    }
  }, [])

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.indeterminate = !!indeterminate
    }
  }, [indeterminate])

  const handleChange = useCallback(event => {
    if (mdcHandleChange.current) {
      mdcHandleChange.current(event)
    }

    if (onChange) {
      onChange(event)
    }
  }, [onChange])

  const effectiveId = id !== undefined ? id : name !== undefined && value !== undefined ? `${name}-${value}` : (name || value)
  const content = (
    <div className='mdc-form-field' style={style || null}>
      <div ref={checkboxEl} className='mdc-checkbox'>
        <input ref={inputEl} type='checkbox' className='mdc-checkbox__native-control' id={effectiveId} name={name} value={value} onChange={handleChange} checked={checked} />
        <div className='mdc-checkbox__background'>
          <svg className='mdc-checkbox__checkmark' viewBox='0 0 24 24'>
            <path className='mdc-checkbox__checkmark-path' fill='none' d='M1.73,12.91 8.1,19.28 22.79,4.59' />
          </svg>
          <div className='mdc-checkbox__mixedmark' />
        </div>
      </div>
      <label htmlFor={effectiveId}>{label}</label>
    </div>
  )
  return title ? (
    <Tooltip title={title}>
      {content}
    </Tooltip>
  ) : content
}

MDCCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  indeterminate: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.shape(),
  title: PropTypes.string,
  value: PropTypes.string
}

export default MDCCheckbox
