import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import MDCRadio from './MDCComponents/Radio'
import MDCSelect from './MDCComponents/Select'
import MDCTextField from './MDCComponents/TextField'

const CustomFieldDisplay = ({ id, name, type, options, onChange, style, value }) => {
  const onMonthChange = useCallback(event => {
    onChange(id, `${value.substr(0, 4)}-${event.target.value === '' ? '00' : event.target.value}-${value.substr(8)}`)
  }, [id, onChange, value])

  const onDayChange = useCallback(event => {
    onChange(id, `${value.substr(0, 4)}-${value.substr(5, 7)}-${event.target.value === '' ? '00' : event.target.value}`)
  }, [id, onChange, value])

  const onYearChange = useCallback(event => {
    onChange(id, `${event.target.value === '' ? '0000' : event.target.value}-${value.substr(5, 7)}-${value.substr(8)}`)
  }, [id, onChange, value])

  const onInputChange = useCallback(event => {
    onChange(id, event.target.value)
  }, [id, onChange])

  const renderRadioOption = useCallback(option => {
    return (
      <div key={`customField_${id}-${option}`}>
        <MDCRadio
          checked={value === option}
          name={`customField_${id}`}
          value={option}
          label={option}
          onChange={onInputChange}
        />
      </div>
    )
  }, [id, onInputChange, value])

  switch (type) {
    case 'radio':
    case 'select':
      if (!options || !options.length) {
        return null
      }
      break
    default:
      break
  }

  let fieldContent = null
  switch (type) {
    case 'date': {
      const year = value.substr(0, 4)
      const month = value.substr(5, 7)
      const day = value.substr(8)

      fieldContent = (
        <>
          <h6 className='mdc-typography--subtitle2'>{name}</h6>
          <div className='fd-date'>
            <MDCSelect
              value={month === '00' ? '' : month}
              label='Month'
              onChange={onMonthChange}
              options={[
                ['', ''],
                ['01', 'January'],
                ['02', 'February'],
                ['03', 'March'],
                ['04', 'April'],
                ['05', 'May'],
                ['06', 'June'],
                ['07', 'July'],
                ['08', 'August'],
                ['09', 'September'],
                ['10', 'October'],
                ['11', 'November'],
                ['12', 'December']
              ]}
            />
            <MDCSelect
              value={day === '00' ? '' : day}
              label='Day'
              onChange={onDayChange}
              options={[
                ['', ''],
                ['1', '01'],
                ['2', '02'],
                ['3', '03'],
                ['4', '04'],
                ['5', '05'],
                ['6', '06'],
                ['7', '07'],
                ['8', '08'],
                ['9', '09'],
                ['10', '10'],
                ['11', '11'],
                ['12', '12'],
                ['13', '13'],
                ['14', '14'],
                ['15', '15'],
                ['16', '16'],
                ['17', '17'],
                ['18', '18'],
                ['19', '19'],
                ['20', '20'],
                ['21', '21'],
                ['22', '22'],
                ['23', '23'],
                ['24', '24'],
                ['25', '25'],
                ['26', '26'],
                ['27', '27'],
                ['28', '28'],
                ['29', '29'],
                ['30', '30'],
                ['31', '31']
              ]}
            />
            <MDCSelect
              value={year === '0000' ? '' : year}
              label='Year'
              onChange={onYearChange}
              options={[
                ['', ''],
                ['2022', '2022'],
                ['2021', '2021'],
                ['2020', '2020'],
                ['2019', '2019'],
                ['2018', '2018'],
                ['2017', '2017'],
                ['2016', '2016'],
                ['2015', '2015'],
                ['2014', '2014'],
                ['2013', '2013'],
                ['2012', '2012'],
                ['2011', '2011'],
                ['2010', '2010'],
                ['2009', '2009'],
                ['2008', '2008'],
                ['2007', '2007'],
                ['2006', '2006'],
                ['2005', '2005'],
                ['2004', '2004'],
                ['2003', '2003'],
                ['2002', '2002'],
                ['2001', '2001'],
                ['2000', '2000'],
                ['1999', '1999'],
                ['1998', '1998'],
                ['1997', '1997'],
                ['1996', '1996'],
                ['1995', '1995'],
                ['1994', '1994'],
                ['1993', '1993'],
                ['1992', '1992'],
                ['1991', '1991'],
                ['1990', '1990'],
                ['1989', '1989'],
                ['1988', '1988'],
                ['1987', '1987'],
                ['1986', '1986'],
                ['1985', '1985'],
                ['1984', '1984'],
                ['1983', '1983'],
                ['1982', '1982'],
                ['1981', '1981'],
                ['1980', '1980'],
                ['1979', '1979'],
                ['1978', '1978'],
                ['1977', '1977'],
                ['1976', '1976'],
                ['1975', '1975'],
                ['1974', '1974'],
                ['1973', '1973'],
                ['1972', '1972'],
                ['1971', '1971'],
                ['1970', '1970'],
                ['1969', '1969'],
                ['1968', '1968'],
                ['1967', '1967'],
                ['1966', '1966'],
                ['1965', '1965'],
                ['1964', '1964'],
                ['1963', '1963'],
                ['1962', '1962'],
                ['1961', '1961'],
                ['1960', '1960'],
                ['1959', '1959'],
                ['1958', '1958'],
                ['1957', '1957'],
                ['1956', '1956'],
                ['1955', '1955'],
                ['1954', '1954'],
                ['1953', '1953'],
                ['1952', '1952'],
                ['1951', '1951'],
                ['1950', '1950'],
                ['1949', '1949'],
                ['1948', '1948'],
                ['1947', '1947'],
                ['1946', '1946'],
                ['1945', '1945'],
                ['1944', '1944'],
                ['1943', '1943'],
                ['1942', '1942'],
                ['1941', '1941'],
                ['1940', '1940'],
                ['1939', '1939'],
                ['1938', '1938'],
                ['1937', '1937'],
                ['1936', '1936'],
                ['1935', '1935'],
                ['1934', '1934'],
                ['1933', '1933'],
                ['1932', '1932'],
                ['1931', '1931'],
                ['1930', '1930'],
                ['1929', '1929'],
                ['1928', '1928'],
                ['1927', '1927'],
                ['1926', '1926'],
                ['1925', '1925'],
                ['1924', '1924'],
                ['1923', '1923'],
                ['1922', '1922'],
                ['1921', '1921'],
                ['1920', '1920'],
                ['1919', '1919'],
                ['1918', '1918'],
                ['1917', '1917'],
                ['1916', '1916'],
                ['1915', '1915'],
                ['1914', '1914'],
                ['1913', '1913'],
                ['1912', '1912'],
                ['1911', '1911'],
                ['1910', '1910'],
                ['1909', '1909'],
                ['1908', '1908'],
                ['1907', '1907'],
                ['1906', '1906'],
                ['1905', '1905']
              ]}
            />
          </div>
        </>
      )
      break
    }
    case 'radio':
      fieldContent = (
        <>
          <h6 className='mdc-typography--subtitle2'>{name}</h6>
          {options.map(renderRadioOption)}
        </>
      )
      break
    case 'select':
      fieldContent = (
        <MDCSelect
          value={value}
          label={name}
          onChange={onInputChange}
          options={[['', '']].concat(options.map(option => [option, option]))}
          style={{ width: '100%' }}
        />
      )
      break
    case 'text':
      fieldContent = (
        <MDCTextField
          label={name}
          onChange={onInputChange}
          style={{ width: '100%' }}
          value={value}
        />
      )
      break
    default:
      return null
  }

  return (
    <div style={style || null}>
      {fieldContent}
    </div>
  )
}

CustomFieldDisplay.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['date', 'text', 'radio', 'select']).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.shape(),
  value: PropTypes.string.isRequired
}

export default CustomFieldDisplay
