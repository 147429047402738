import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useSelector, useDispatch } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'
import DashboardIcon from '@material-ui/icons/Dashboard'

import { fetchApplication } from '../../actions/application'
import { fetchUserDashboard, saveUserDashboard, setUserDashboard } from '../../actions/user'

import { selectAccessee, selectAccesseeBillingId } from '../../selectors/application'
import { makeUserDashboardSelector, makeUserDashboardWidgetOrderSelector } from '../../selectors/user'

import MDCCircularProgress from '../MDCComponents/CircularProgress'

import Tooltip from '../MUIComponents/Tooltip'

import Sortable from '../UtilityComponents/Sortable'
import ItemTypes from '../UtilityComponents/ItemTypes'

import { SortableEventHandlerProvider } from '../UtilityComponents/SortableEventHandlerContext'
import { WidgetEventHandlerProvider } from './widgets/utilities/WidgetEventHandlerContext'

import AddContactWidget from './widgets/AddContact'
import ContactsByStatusWidget from './widgets/ContactsByStatus'
import ContactTrendsWidget from './widgets/ContactTrends'
import GettingStartedWidget from './widgets/GettingStarted'
import ListsWidget from './widgets/Lists'
import MostRecentIssueStatisticsWidget from './widgets/MostRecentIssueStatistics'
import PricingPlanWidget from './widgets/PricingPlan'
import SearchContactsWidget from './widgets/SearchContacts'

import widgetSchemas from '../../utilities/widgetSchemas'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%'
  }
}))

const SORTABLE_WIDGET_STYLE = {
  height: '100%'
}

const WIDGET_GRID_PANEL_CONTENT_STYLE = {
  paddingTop: '1rem'
}

const WIDGET_CHOOSER_DIALOG_PAPER_STYLE = {
  width: '100%',
  height: '100%'
}

const WIDGET_CHOOSER_DIALOG_CLOSE_BUTTON_STYLE = {
  position: 'absolute',
  top: '8px',
  right: '8px',
  cursor: 'pointer'
}

const WIDGET_CHOOSER_PANEL_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%'
}

const WIDGET_CHOOSER_PANEL_CONTENT_STYLE = {
  minHeight: '548px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  flex: 1
}

const WIDGET_CHOOSER_PANEL_LAYOUT_GRID_STYLE = {
  width: '100%',
  height: '100%'
}

const WIDGET_CHOOSER_ALLOWED_WIDGET_PANEL_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '100%',
  maxHeight: '100%',
  width: '120px',
  height: '120px',
  textAlign: 'center',
  cursor: 'pointer'
}

const WIDGET_CHOOSER_ALLOWED_WIDGET_PANEL_CONTENT_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 1'
}

const WIDGET_CHOOSER_ALLOWED_WIDGET_PANEL_ICON_STYLE = {
  width: '48px',
  height: '48px'
}

const WIDGET_CHOOSER_ALLOWED_WIDGET_PANEL_TITLE_STYLE = {
  flex: '0 1',
  fontWeight: 500,
  padding: '0 0 1em'
}

const WidgetGrid = ({ allowedWidgets, openWidgetChooser, type, userDashboard, userId }) => {
  const dispatch = useDispatch()

  const selectUserDashboardWidgetOrder = useMemo(makeUserDashboardWidgetOrderSelector)
  const userDashboardWidgetOrder = useSelector(state => selectUserDashboardWidgetOrder(state, {
    dashboard: type,
    userId
  }))

  const rootEl = useRef(null)
  useEffect(() => {
    if (rootEl.current && rootEl.current.style.minHeight) {
      rootEl.current.style.minHeight = ''
    }
  })

  const [, drop] = useDrop({ accept: `${type}_${ItemTypes.PANEL}` })

  const widgetEventHandler = useRef(null)
  widgetEventHandler.current = useCallback(action => {
    switch (action.type) {
      case 'remove': {
        const { id } = action
        const removedWidget = userDashboard.filter(widget => widget.id === id)[0]
        const removedWidgetLocation = userDashboard.indexOf(removedWidget)
        const newWidgets = []

        for (let index = 0; index < userDashboard.length; index++) {
          if (index === removedWidgetLocation) {
            continue
          }

          newWidgets.push(userDashboard[index])
        }

        dispatch(setUserDashboard(type, newWidgets, userId))
        dispatch(saveUserDashboard(type, newWidgets))
        break
      }
      case 'configure': {
        const { id, settings } = action
        const newWidgets = userDashboard.map(widget => widget.id === id ? { ...widget, settings } : widget)
        dispatch(setUserDashboard(type, newWidgets, userId))
        dispatch(saveUserDashboard(type, newWidgets))
        break
      }
      default:
        break
    }
  }, [dispatch, type, userDashboard, userId])

  const lastSwitchedWidgetId = useRef(null)
  const sortableEventHandler = useRef(null)
  sortableEventHandler.current = useCallback(action => {
    switch (action.type) {
      case 'move': {
        const { id, targetId } = action
        if (lastSwitchedWidgetId.current === targetId) {
          return
        }

        if (id !== targetId) {
          const movingWidget = userDashboard.filter(widget => widget.id === id)[0]
          const movingWidgetLocation = userDashboard.indexOf(movingWidget)
          const targetWidget = userDashboard.filter(widget => widget.id === targetId)[0]
          const targetWidgetLocation = userDashboard.indexOf(targetWidget)
          const newWidgets = []

          for (let index = 0; index < userDashboard.length; index++) {
            if (index === targetWidgetLocation) {
              newWidgets.push(movingWidget)
            } else if (index === movingWidgetLocation) {
              newWidgets.push(targetWidget)
            } else {
              newWidgets.push(userDashboard[index])
            }
          }

          // HACK: Ensure the height doesnt change while dragging!
          rootEl.current.style.minHeight = `${rootEl.current.getBoundingClientRect().height}px`

          dispatch(setUserDashboard(type, newWidgets, userId))
        }

        lastSwitchedWidgetId.current = targetId
        break
      }
      case 'reset': {
        const newWidgets = []

        for (let index = 0; index < userDashboardWidgetOrder.length; index++) {
          newWidgets.push(userDashboard.filter(widget => widget.id === userDashboardWidgetOrder[index])[0])
        }
        dispatch(setUserDashboard(type, newWidgets, userId))
        lastSwitchedWidgetId.current = null
        break
      }
      case 'commit':
        if (userDashboardWidgetOrder.length !== userDashboard.length || userDashboardWidgetOrder.some((widgetId, index) => widgetId !== userDashboard[index].id)) {
          dispatch(saveUserDashboard(type, userDashboard))
        }
        lastSwitchedWidgetId.current = null
        break
      default:
        break
    }
  }, [dispatch, type, userDashboard, userDashboardWidgetOrder, userId])

  const renderSortableWidget = useCallback(data => {
    const widgetDetails = widgetSchemas[data.id]
    if (!widgetDetails || allowedWidgets.indexOf(data.id) === -1) {
      return null
    }

    const { title } = widgetDetails
    const { id, settings } = data

    let widget = null
    switch (id) {
      case 'ContactsByStatus':
        widget = (
          <ContactsByStatusWidget
            id={id}
            settings={settings}
            title={title}
            userId={userId}
          />
        )
        break
      case 'ContactTrends':
        widget = (
          <ContactTrendsWidget
            id={id}
            settings={settings}
            title={title}
            userId={userId}
          />
        )
        break
      case 'MostRecentIssueStatistics':
        widget = (
          <MostRecentIssueStatisticsWidget
            id={id}
            settings={settings}
            title={title}
            userId={userId}
          />
        )
        break
      case 'PricingPlan':
        widget = (
          <PricingPlanWidget
            id={id}
            settings={settings}
            title={title}
            userId={userId}
          />
        )
        break
      case 'SearchContacts':
        widget = (
          <SearchContactsWidget
            id={id}
            settings={settings}
            title={title}
            userId={userId}
          />
        )
        break
      case 'AddContact':
        widget = (
          <AddContactWidget
            id={id}
            settings={settings}
            title={title}
          />
        )
        break
      case 'Lists':
        widget = (
          <ListsWidget
            id={id}
            settings={settings}
            title={title}
          />
        )
        break
      case 'GettingStarted':
        widget = (
          <GettingStartedWidget
            id={id}
            settings={settings}
            title={title}
          />
        )
        break
      default:
        break
    }

    if (widget === null) {
      return null
    }

    const { desktopSize, tabletSize, phoneSize } = settings
    return (
      <Sortable
        key={id}
        id={id}
        className={`mdc-layout-grid__cell mdc-layout-grid__cell--span-${desktopSize}-desktop mdc-layout-grid__cell--span-${tabletSize}-tablet mdc-layout-grid__cell--span-${phoneSize}-phone`}
        style={SORTABLE_WIDGET_STYLE}
        type={`${type}_${ItemTypes.PANEL}`}
        dragHandle={DashboardIcon}
        hasHandle
      >
        {widget}
      </Sortable>
    )
  }, [allowedWidgets, type, userId])

  const onClick = useCallback(event => {
    if (event) {
      event.preventDefault()
    }

    openWidgetChooser(event)
  }, [openWidgetChooser])

  return (
    <div ref={rootEl} className='mdc-layout-grid'>
      <div ref={drop} className='mdc-layout-grid__inner'>
        {!userDashboard.length ? (
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-12'>
            <div className='panel'>
              <div className='panelContent' style={WIDGET_GRID_PANEL_CONTENT_STYLE}>
                 This dashboard is empty. Try <button className='fd-link-button' onClick={onClick}>adding a widget</button> to see some cool data!
              </div>
            </div>
          </div>
        ) : (
          <SortableEventHandlerProvider value={sortableEventHandler}>
            <WidgetEventHandlerProvider value={widgetEventHandler}>
              {userDashboard.map(renderSortableWidget)}
            </WidgetEventHandlerProvider>
          </SortableEventHandlerProvider>
        )}
      </div>
    </div>
  )
}

WidgetGrid.propTypes = {
  allowedWidgets: PropTypes.arrayOf(PropTypes.string).isRequired,
  openWidgetChooser: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  userDashboard: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
}

const WidgetChooser = ({ allowedWidgets, dashboardType, onChoose, userId }) => {
  const dispatch = useDispatch()

  const selectUserDashboard = useMemo(makeUserDashboardSelector)
  const userDashboard = useSelector(state => selectUserDashboard(state, {
    dashboard: dashboardType,
    userId
  }))

  useEffect(() => {
    if (!userDashboard) {
      dispatch(fetchUserDashboard(dashboardType))
    }
  }, [dashboardType, dispatch, userDashboard])

  const renderAllowedWidgetDescription = useCallback(allowedWidget => {
    const widgetDetails = widgetSchemas[allowedWidget]
    if (!widgetDetails || userDashboard.some(widget => widget.id === allowedWidget)) {
      return null
    }

    const { title, icon: Icon, settings } = widgetDetails
    const onClick = event => {
      if (event) {
        event.preventDefault()
      }

      dispatch(saveUserDashboard(dashboardType, [{
        id: allowedWidget,
        settings
      }].concat(userDashboard)))

      if (onChoose) {
        onChoose()
      }

      return false
    }

    return (
      <div key={allowedWidget} className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone'>
        <div className='panel' onClick={onClick} style={WIDGET_CHOOSER_ALLOWED_WIDGET_PANEL_STYLE}>
          <div style={WIDGET_CHOOSER_ALLOWED_WIDGET_PANEL_CONTENT_STYLE}>
            <Icon style={WIDGET_CHOOSER_ALLOWED_WIDGET_PANEL_ICON_STYLE} />
          </div>
          <span style={WIDGET_CHOOSER_ALLOWED_WIDGET_PANEL_TITLE_STYLE}>{title}</span>
        </div>
      </div>
    )
  }, [dashboardType, dispatch, onChoose, userDashboard])

  if (!userDashboard) {
    return null
  }

  return (
    <div className='panel' style={WIDGET_CHOOSER_PANEL_STYLE}>
      <h2>Add Dashboard Widget</h2>
      <div className='panelContent' style={WIDGET_CHOOSER_PANEL_CONTENT_STYLE}>
        <div className='mdc-layout-grid' style={WIDGET_CHOOSER_PANEL_LAYOUT_GRID_STYLE}>
          <div className='mdc-layout-grid__inner'>
            {allowedWidgets.length === userDashboard.length
              ? (
                <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-12'>
                  All available widgets have been added to this dashboard!
                </div>
              )
              : allowedWidgets.map(renderAllowedWidgetDescription)}
          </div>
        </div>
      </div>
    </div>
  )
}

WidgetChooser.propTypes = {
  allowedWidgets: PropTypes.arrayOf(PropTypes.string).isRequired,
  dashboardType: PropTypes.string.isRequired,
  onChoose: PropTypes.func,
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
}

const allPossibleWidgets = Object.keys(widgetSchemas)
const manualBillingWidgets = allPossibleWidgets.filter(widget => widget !== 'PricingPlan')

const Dashboard = ({ type, userId, usePageloadProgress }) => {
  const [open, setOpen] = useState(false)

  const openModal = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const closeModal = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const dispatch = useDispatch()

  const accessee = useSelector(selectAccessee)
  const accesseeBillingId = useSelector(selectAccesseeBillingId)
  const selectUserDashboard = useMemo(makeUserDashboardSelector)
  const userDashboard = useSelector(state => selectUserDashboard(state, {
    dashboard: type,
    userId
  }))

  useEffect(() => {
    if (!accessee) {
      dispatch(fetchApplication())
    }
  }, [accessee, dispatch])

  useEffect(() => {
    if (!userDashboard) {
      dispatch(fetchUserDashboard(type))
    }
  }, [dispatch, type, userDashboard])

  useEffect(() => {
    if (accessee) {
      global.appLoadFloatingActionButton({
        classes: ['mdc-fab--extended'],
        label: 'Add Dashboard Widget',
        icon: 'add',
        onClick: openModal
      })

      return global.appUnloadFloatingActionButton
    }
  }, [accessee, openModal])

  useEffect(() => {
    if (usePageloadProgress) {
      if (!accessee || !userDashboard) {
        global.$('#fd_pageload_progress').removeClass('mdc-linear-progress--closed')
      } else {
        global.$('#fd_pageload_progress').addClass('mdc-linear-progress--closed')
      }
    }
  }, [accessee, usePageloadProgress, userDashboard])

  const classes = useStyles()

  const allowedWidgets = useMemo(() => {
    if (accesseeBillingId > 0) {
      return allPossibleWidgets
    }
    return manualBillingWidgets
  }, [accesseeBillingId])

  return (
    <div className={classes.root}>
      {
        !usePageloadProgress
          ? !accessee || !userDashboard ? (
            <div className='mdc-layout-grid'>
              <div className='mdc-layout-grid__inner'>
                <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-12'>
                  <div className='panel'>
                    <div className='panelContent' style={WIDGET_GRID_PANEL_CONTENT_STYLE}>
                      <MDCCircularProgress>Loading widgets...</MDCCircularProgress>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
            : null
          : null
      }
      {accessee && userDashboard &&
        <>
          <Dialog
            fullWidth
            maxWidth='xl'
            onClose={closeModal}
            open={open}
            PaperProps={{
              style: WIDGET_CHOOSER_DIALOG_PAPER_STYLE
            }}
          >
            <Tooltip title='Close'>
              <div style={WIDGET_CHOOSER_DIALOG_CLOSE_BUTTON_STYLE} onClick={closeModal}><CloseIcon /></div>
            </Tooltip>
            <WidgetChooser
              allowedWidgets={allowedWidgets}
              dashboardType={type}
              onChoose={closeModal}
              userId={userId}
            />
          </Dialog>
          <WidgetGrid
            allowedWidgets={allowedWidgets}
            openWidgetChooser={openModal}
            type={type}
            userDashboard={userDashboard}
            userId={userId}
            usePageloadProgress={usePageloadProgress}
          />
        </>}
    </div>
  )
}

Dashboard.propTypes = {
  type: PropTypes.string.isRequired,
  usePageloadProgress: PropTypes.bool,
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
}

export default Dashboard
