import PropTypes from 'prop-types'
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import GetAppIcon from '@material-ui/icons/GetApp'
import PrintIcon from '@material-ui/icons/Print'
import RefreshIcon from '@material-ui/icons/Refresh'
import SettingsIcon from '@material-ui/icons/Settings'

import ConfigureModal from './utilities/ConfigureModal'

import MDCCircularProgress from '../../MDCComponents/CircularProgress'
import MDCMenu from '../../MDCComponents/Menu'
import MDCMenuItem from '../../MDCComponents/MenuItem'
import MDCSelect from '../../MDCComponents/Select'

import Tooltip from '../../MUIComponents/Tooltip'

import DragHandleContext from '../../UtilityComponents/DragHandleContext'

import useContactTrendsChart from '../../../hooks/useContactTrendsChart'

import WidgetEventHandlerContext from './utilities/WidgetEventHandlerContext'

const ContactsTrendsWidget = props => {
  const { children, dialog, id, settings, title, userId } = props

  const { current: widgetEventHandler } = useContext(WidgetEventHandlerContext)

  const drag = useContext(DragHandleContext)

  const [containerEl, {
    accessee,
    closeExpandedChartModal,
    contactTrendsReportData,
    downloadChart,
    downloadEl,
    expandChart,
    expandEl,
    isExpandedChartModalOpen,
    printChart,
    printEl,
    refreshData,
    refreshEl
  }] = useContactTrendsChart({
    dialog,
    settings,
    userId
  })

  const removeEl = useRef(null)
  useEffect(() => {
    if (removeEl.current) {
      const el = removeEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const configureEl = useRef(null)
  useEffect(() => {
    if (configureEl.current) {
      const el = configureEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const dateGrouping = useRef(settings.dateGrouping)
  const onDateGroupingChange = event => {
    dateGrouping.current = event.target.value
  }

  const [isConfigureModalOpen, setConfigureModalOpen] = useState(false)
  const closeConfigureModal = useCallback(() => setConfigureModalOpen(false), [setConfigureModalOpen])
  const saveConfiguration = useCallback((newSettings, widgetSizeConfigurationChanged) => {
    let changed = widgetSizeConfigurationChanged

    if (settings.dateGrouping !== dateGrouping.current) {
      changed = true
      newSettings.dateGrouping = dateGrouping.current
    }

    if (changed) {
      widgetEventHandler({
        type: 'configure',
        id,
        settings: newSettings
      })
    }

    setConfigureModalOpen(false)
  }, [id, setConfigureModalOpen, settings, widgetEventHandler])

  const onConfigure = useCallback(() => {
    setConfigureModalOpen(true)
  }, [setConfigureModalOpen])

  const onRemove = useCallback(() => {
    widgetEventHandler({
      type: 'remove',
      id
    })
  }, [id, widgetEventHandler])

  return (
    <div className='panel' style={{ willChange: 'transform', ...(dialog ? { display: 'flex', flexDirection: 'column', width: '100%', height: '100%' } : {}) }}>
      <h2>{title}</h2>
      <div className='panelContent' style={{ minHeight: '548px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div className='panelActions' style={dialog ? { top: 0, marginBottom: 0 } : null}>
          {!dialog &&
            <Tooltip title='Move Widget'>
              <span ref={drag} style={{ cursor: 'move' }}><DragIndicatorIcon /></span>
            </Tooltip>}
        </div>
        {children}
        <div ref={containerEl} style={{ flex: 1, willChange: 'transform' }}>
          {!accessee || !contactTrendsReportData
            ? <MDCCircularProgress>Please wait...</MDCCircularProgress>
            : null}
        </div>
        <div className='panelBottomActions'>
          <div className='panelBottomIcons'>
            {accessee && contactTrendsReportData &&
              <>
                <Tooltip title='Refresh'>
                  <button ref={refreshEl} className='mdc-icon-button' onClick={refreshData}><RefreshIcon className='mdc-icon-button__icon' /></button>
                </Tooltip>
                {!dialog &&
                  <Tooltip title='Expand'>
                    <button ref={expandEl} className='mdc-icon-button' onClick={expandChart}><FullscreenIcon className='mdc-icon-button__icon' /></button>
                  </Tooltip>}
                <Tooltip title='Print'>
                  <button ref={printEl} className='mdc-icon-button' onClick={printChart}><PrintIcon className='mdc-icon-button__icon' /></button>
                </Tooltip>
                <div className='mdc-menu-surface--anchor' style={{ display: 'inline-block' }}>
                  <MDCMenu buttonIcon={GetAppIcon} buttonRef={downloadEl} buttonTitle='Download'>
                    <MDCMenuItem data-format='pdf' onClick={downloadChart} text='Download Chart as PDF' />
                    <MDCMenuItem data-format='png' onClick={downloadChart} text='Download Chart as Image' />
                    <MDCMenuItem data-format='csv' onClick={downloadChart} text='Download Data as CSV' />
                  </MDCMenu>
                </div>
              </>}
            <Tooltip title='Configure Widget'>
              <button ref={configureEl} className='mdc-icon-button' onClick={onConfigure}><SettingsIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
            <Tooltip title='Remove Widget'>
              <button ref={removeEl} className='mdc-icon-button' onClick={onRemove}><DeleteIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth='xl'
        onClose={closeExpandedChartModal}
        open={isExpandedChartModalOpen}
        PaperProps={{
          style: {
            width: '100%',
            height: '100%'
          }
        }}
      >
        <Tooltip title='Close'>
          <div style={{ position: 'absolute', top: '8px', right: '8px', zIndex: 1, cursor: 'pointer' }} onClick={closeExpandedChartModal}><CloseIcon /></div>
        </Tooltip>
        <MemoizedContactsTrendsWidget
          {...props}
          dialog
        />
      </Dialog>
      <ConfigureModal
        close={closeConfigureModal}
        height='480px'
        isOpen={isConfigureModalOpen}
        saveConfiguration={saveConfiguration}
        settings={settings}
        title={title}
      >
        <MDCSelect
          label='Date Grouping'
          value={settings.dateGrouping}
          options={[
            ['year', 'Year'],
            ['month', 'Month'],
            ['day', 'Day'],
            ['hour', 'Hour']
          ]}
          onChange={onDateGroupingChange}
          style={{ width: '100%' }}
        />
      </ConfigureModal>
    </div>
  )
}

ContactsTrendsWidget.propTypes = {
  children: PropTypes.node,
  dialog: PropTypes.bool,
  id: PropTypes.string,
  settings: PropTypes.shape(),
  title: PropTypes.string,
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
}

const MemoizedContactsTrendsWidget = memo(ContactsTrendsWidget)

export default MemoizedContactsTrendsWidget
