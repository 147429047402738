import PropTypes from 'prop-types'
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import SettingsIcon from '@material-ui/icons/Settings'

import ConfigureModal from './utilities/ConfigureModal'

import MDCButton from '../../MDCComponents/Button'
import MDCCircularProgress from '../../MDCComponents/CircularProgress'
import MDCSelect from '../../MDCComponents/Select'
import MDCTabBar from '../../MDCComponents/TabBar'
import MDCTextField from '../../MDCComponents/TextField'

import MDCTab from '../../MDCComponents/utilities/Tab'

import Tooltip from '../../MUIComponents/Tooltip'

import DragHandleContext from '../../UtilityComponents/DragHandleContext'

import { fetchApplication } from '../../../actions/application'
import { saveUser } from '../../../actions/user'
import { fetchVerifiedEmailAddresses, verifyEmailAddress } from '../../../actions/verifiedEmailAddresses'

import {
  selectAccessee,
  selectAccesseeBusinessCity,
  selectAccesseeBusinessCountry,
  selectAccesseeBusinessName,
  selectAccesseeBusinessState,
  selectAccesseeBusinessStreetAddress,
  selectAccesseeBusinessZipcode,
  selectAccesseeId,
  selectAccesseeLogo1Exists,
  selectWebSiteURL
} from '../../../selectors/application'

import { makeSavingUserProfileSelector } from '../../../selectors/user'

import { selectVerifiedEmailAddresses, selectVerifyingEmailAddress } from '../../../selectors/verifiedEmailAddresses'

import WidgetEventHandlerContext from './utilities/WidgetEventHandlerContext'

const renderVerifiedEmailAddress = verifiedEmailAddress => (
  <li key={verifiedEmailAddress}>
    {verifiedEmailAddress}
  </li>
)

const VerifyYourEmailTab = props => {
  const dispatch = useDispatch()

  const verifiedEmailAddresses = useSelector(selectVerifiedEmailAddresses)
  const verifyingEmailAddress = useSelector(selectVerifyingEmailAddress)

  useEffect(() => {
    if (!verifiedEmailAddresses || !verifiedEmailAddresses.length) {
      dispatch(fetchVerifiedEmailAddresses())
    }
  }, [dispatch, verifiedEmailAddresses])

  const [emailAddress, setEmailAddress] = useState('')

  const handleChange = useCallback(event => {
    setEmailAddress(event.target.value)
  }, [setEmailAddress])

  const handleClick = useCallback(event => {
    dispatch(verifyEmailAddress(emailAddress))
    setEmailAddress('')
  }, [dispatch, emailAddress])

  return !verifiedEmailAddresses
    ? (
      <MDCCircularProgress>Please wait...</MDCCircularProgress>
    )
    : (
      <div className='mdc-layout-grid'>
        <div className='mdc-layout-grid__inner'>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-12'>
            {!verifiedEmailAddresses.length
              ? (
                <>
                  We sent you an email when you signed up asking to confirm your email address!
                  <br />
                  Simply click the link in that email to continue! Or, specify another email address to confirm below.
                </>
              )
              : (
                <>
                  Congratulations! Your email address has been confirmed!
                  <br />
                  <ul>
                    {verifiedEmailAddresses.map(renderVerifiedEmailAddress)}
                  </ul>
                  <br />
                  Feel free to verify another email address below.
                </>
              )}
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet'>
            <MDCTextField
              label='Email Address'
              value={emailAddress}
              onChange={handleChange}
            />
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <MDCButton
              disabled={verifyingEmailAddress}
              label='Verify'
              onClick={handleClick}
              type='raised'
            />
          </div>
        </div>
      </div>
    )
}

VerifyYourEmailTab.propTypes = {
}

const EnterYourBusinessAddressTab = props => {
  const dispatch = useDispatch()

  const accesseeBusinessCity = useSelector(selectAccesseeBusinessCity)
  const accesseeBusinessCountry = useSelector(selectAccesseeBusinessCountry)
  const accesseeBusinessState = useSelector(selectAccesseeBusinessState)
  const accesseeBusinessStreetAddress = useSelector(selectAccesseeBusinessStreetAddress)
  const accesseeBusinessZipcode = useSelector(selectAccesseeBusinessZipcode)

  const selectSavingUserProfile = useMemo(makeSavingUserProfileSelector)
  const isSavingUser = useSelector(state => selectSavingUserProfile(state, 'self'))

  const [streetAddress, setStreetAddress] = useState(accesseeBusinessStreetAddress)
  const [city, setCity] = useState(accesseeBusinessCity)
  const [state, setState] = useState(accesseeBusinessState)
  const [postalCode, setPostalCode] = useState(accesseeBusinessZipcode)
  const [country, setCountry] = useState(accesseeBusinessCountry)

  const handleStreetAddressChange = useCallback(event => {
    setStreetAddress(event.target.value)
  }, [setStreetAddress])

  const handleCityChange = useCallback(event => {
    setCity(event.target.value)
  }, [setCity])

  const handleStateChange = useCallback(event => {
    setState(event.target.value)
  }, [setState])

  const handlePostalCodeChange = useCallback(event => {
    setPostalCode(event.target.value)
  }, [setPostalCode])

  const handleCountryChange = useCallback(event => {
    setCountry(event.target.value)
  }, [setCountry])

  const handleClick = useCallback(event => {
    dispatch(saveUser({
      businessStreetAddress: streetAddress,
      businessCity: city,
      [`businessState${country !== 'USA' ? 'International' : ''}`]: state,
      businessZipcode: postalCode,
      businessCountry: country
    }))
  }, [dispatch, streetAddress, city, state, postalCode, country])

  return (
    <div className='mdc-layout-grid'>
      <div className='mdc-layout-grid__inner'>
        <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-12'>
          <MDCTextField
            label='Street Address'
            value={streetAddress}
            onChange={handleStreetAddressChange}
          />
        </div>
        <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
          <MDCTextField
            label='City'
            value={city}
            onChange={handleCityChange}
          />
        </div>
        <div className='mdc-layout-grid__cell country'>
          {accesseeBusinessCountry === 'USA'
            ? (
              <MDCSelect
                label='State'
                value={state}
                onChange={handleStateChange}
                options={[
                  ['', ''],
                  ['Alabama', 'Alabama'],
                  ['Alaska', 'Alaska'],
                  ['Arizona', 'Arizona'],
                  ['Arkansas', 'Arkansas'],
                  ['California', 'California'],
                  ['Colorado', 'Colorado'],
                  ['Connecticut', 'Connecticut'],
                  ['Delaware', 'Delaware'],
                  ['District of Columbia', 'District of Columbia'],
                  ['Florida', 'Florida'],
                  ['Georgia', 'Georgia'],
                  ['Hawaii', 'Hawaii'],
                  ['Idaho', 'Idaho'],
                  ['Illinois', 'Illinois'],
                  ['Indiana', 'Indiana'],
                  ['Iowa', 'Iowa'],
                  ['Kansas', 'Kansas'],
                  ['Kentucky', 'Kentucky'],
                  ['Louisiana', 'Louisiana'],
                  ['Maine', 'Maine'],
                  ['Maryland', 'Maryland'],
                  ['Massachusetts', 'Massachusetts'],
                  ['Michigan', 'Michigan'],
                  ['Minnesota', 'Minnesota'],
                  ['Mississippi', 'Mississippi'],
                  ['Missouri', 'Missouri'],
                  ['Montana', 'Montana'],
                  ['Nebraska', 'Nebraska'],
                  ['Nevada', 'Nevada'],
                  ['New Hampshire', 'New Hampshire'],
                  ['New Jersey', 'New Jersey'],
                  ['New Mexico', 'New Mexico'],
                  ['New York', 'New York'],
                  ['North Carolina', 'North Carolina'],
                  ['North Dakota', 'North Dakota'],
                  ['Ohio', 'Ohio'],
                  ['Oklahoma', 'Oklahoma'],
                  ['Oregon', 'Oregon'],
                  ['Pennsylvania', 'Pennsylvania'],
                  ['Rhode Island', 'Rhode Island'],
                  ['South Carolina', 'South Carolina'],
                  ['South Dakota', 'South Dakota'],
                  ['Tennessee', 'Tennessee'],
                  ['Texas', 'Texas'],
                  ['Trust Territories', 'Trust Territories'],
                  ['Utah', 'Utah'],
                  ['Vermont', 'Vermont'],
                  ['Virginia', 'Virginia'],
                  ['Washington', 'Washington'],
                  ['West Virginia', 'West Virginia'],
                  ['Wisconsin', 'Wisconsin'],
                  ['Wyoming', 'Wyoming']
                ]}
              />
            )
            : (
              <MDCTextField
                label='State / Province'
                value={state}
                onChange={handleStateChange}
              />
            )}
        </div>
        <div className='mdc-layout-grid__cell'>
          <MDCTextField
            label='Postal Code'
            value={postalCode}
            onChange={handlePostalCodeChange}
          />
        </div>
        <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
          <MDCSelect
            label='Country'
            value={country}
            onChange={handleCountryChange}
            select2
            options={[
              ['USA', 'United States'],
              ['AFG', 'Afghanistan'],
              ['ALA', 'Åland Islands'],
              ['ALB', 'Albania'],
              ['DZA', 'Algeria'],
              ['ASM', 'American Samoa'],
              ['AND', 'Andorra'],
              ['AGO', 'Angola'],
              ['AIA', 'Anguilla'],
              ['ATA', 'Antarctica'],
              ['ATG', 'Antigua and Barbuda'],
              ['ARG', 'Argentina'],
              ['ARM', 'Armenia'],
              ['ABW', 'Aruba'],
              ['AUS', 'Australia'],
              ['AUT', 'Austria'],
              ['AZE', 'Azerbaijan'],
              ['BHS', 'Bahamas'],
              ['BHR', 'Bahrain'],
              ['BGD', 'Bangladesh'],
              ['BRB', 'Barbados'],
              ['BLR', 'Belarus'],
              ['BEL', 'Belgium'],
              ['BLZ', 'Belize'],
              ['BEN', 'Benin'],
              ['BMU', 'Bermuda'],
              ['BTN', 'Bhutan'],
              ['BOL', 'Bolivia, Plurinational State of'],
              ['BES', 'Bonaire, Sint Eustatius and Saba'],
              ['BIH', 'Bosnia and Herzegovina'],
              ['BWA', 'Botswana'],
              ['BVT', 'Bouvet Island'],
              ['BRA', 'Brazil'],
              ['IOT', 'British Indian Ocean Territory'],
              ['BRN', 'Brunei Darussalam'],
              ['BGR', 'Bulgaria'],
              ['BFA', 'Burkina Faso'],
              ['BDI', 'Burundi'],
              ['KHM', 'Cambodia'],
              ['CMR', 'Cameroon'],
              ['CAN', 'Canada'],
              ['CPV', 'Cape Verde'],
              ['CYM', 'Cayman Islands'],
              ['CAF', 'Central African Republic'],
              ['TCD', 'Chad'],
              ['CHL', 'Chile'],
              ['CHN', 'China'],
              ['CXR', 'Christmas Island'],
              ['CCK', 'Cocos (Keeling) Islands'],
              ['COL', 'Colombia'],
              ['COM', 'Comoros'],
              ['COG', 'Congo'],
              ['COD', 'Congo, the Democratic Republic of the'],
              ['COK', 'Cook Islands'],
              ['CRI', 'Costa Rica'],
              ['CIV', 'Côte d\'Ivoire'],
              ['HRV', 'Croatia'],
              ['CUB', 'Cuba'],
              ['CUW', 'Curaçao'],
              ['CYP', 'Cyprus'],
              ['CZE', 'Czech Republic'],
              ['DNK', 'Denmark'],
              ['DJI', 'Djibouti'],
              ['DMA', 'Dominica'],
              ['DOM', 'Dominican Republic'],
              ['ECU', 'Ecuador'],
              ['EGY', 'Egypt'],
              ['SLV', 'El Salvador'],
              ['GNQ', 'Equatorial Guinea'],
              ['ERI', 'Eritrea'],
              ['EST', 'Estonia'],
              ['ETH', 'Ethiopia'],
              ['FLK', 'Falkland Islands (Malvinas)'],
              ['FRO', 'Faroe Islands'],
              ['FJI', 'Fiji'],
              ['FIN', 'Finland'],
              ['FRA', 'France'],
              ['GUF', 'French Guiana'],
              ['PYF', 'French Polynesia'],
              ['ATF', 'French Southern Territories'],
              ['GAB', 'Gabon'],
              ['GMB', 'Gambia'],
              ['GEO', 'Georgia'],
              ['DEU', 'Germany'],
              ['GHA', 'Ghana'],
              ['GIB', 'Gibraltar'],
              ['GRC', 'Greece'],
              ['GRL', 'Greenland'],
              ['GRD', 'Grenada'],
              ['GLP', 'Guadeloupe'],
              ['GUM', 'Guam'],
              ['GTM', 'Guatemala'],
              ['GGY', 'Guernsey'],
              ['GIN', 'Guinea'],
              ['GNB', 'Guinea-Bissau'],
              ['GUY', 'Guyana'],
              ['HTI', 'Haiti'],
              ['HMD', 'Heard Island and McDonald Islands'],
              ['VAT', 'Holy See (Vatican City State)'],
              ['HND', 'Honduras'],
              ['HKG', 'Hong Kong'],
              ['HUN', 'Hungary'],
              ['ISL', 'Iceland'],
              ['IND', 'India'],
              ['IDN', 'Indonesia'],
              ['IRN', 'Iran, Islamic Republic of'],
              ['IRQ', 'Iraq'],
              ['IRL', 'Ireland'],
              ['IMN', 'Isle of Man'],
              ['ISR', 'Israel'],
              ['ITA', 'Italy'],
              ['JAM', 'Jamaica'],
              ['JPN', 'Japan'],
              ['JEY', 'Jersey'],
              ['JOR', 'Jordan'],
              ['KAZ', 'Kazakhstan'],
              ['KEN', 'Kenya'],
              ['KIR', 'Kiribati'],
              ['PRK', 'Korea, Democratic People\'s Republic of'],
              ['KOR', 'Korea, Republic of'],
              ['KWT', 'Kuwait'],
              ['KGZ', 'Kyrgyzstan'],
              ['LAO', 'Lao People\'s Democratic Republic'],
              ['LVA', 'Latvia'],
              ['LBN', 'Lebanon'],
              ['LSO', 'Lesotho'],
              ['LBR', 'Liberia'],
              ['LBY', 'Libya'],
              ['LIE', 'Liechtenstein'],
              ['LTU', 'Lithuania'],
              ['LUX', 'Luxembourg'],
              ['MAC', 'Macao'],
              ['MKD', 'Macedonia, the former Yugoslav Republic of'],
              ['MDG', 'Madagascar'],
              ['MWI', 'Malawi'],
              ['MYS', 'Malaysia'],
              ['MDV', 'Maldives'],
              ['MLI', 'Mali'],
              ['MLT', 'Malta'],
              ['MHL', 'Marshall Islands'],
              ['MTQ', 'Martinique'],
              ['MRT', 'Mauritania'],
              ['MUS', 'Mauritius'],
              ['MYT', 'Mayotte'],
              ['MEX', 'Mexico'],
              ['FSM', 'Micronesia, Federated States of'],
              ['MDA', 'Moldova, Republic of'],
              ['MCO', 'Monaco'],
              ['MNG', 'Mongolia'],
              ['MNE', 'Montenegro'],
              ['MSR', 'Montserrat'],
              ['MAR', 'Morocco'],
              ['MOZ', 'Mozambique'],
              ['MMR', 'Myanmar'],
              ['NAM', 'Namibia'],
              ['NRU', 'Nauru'],
              ['NPL', 'Nepal'],
              ['NLD', 'Netherlands'],
              ['NCL', 'New Caledonia'],
              ['NZL', 'New Zealand'],
              ['NIC', 'Nicaragua'],
              ['NER', 'Niger'],
              ['NGA', 'Nigeria'],
              ['NIU', 'Niue'],
              ['NFK', 'Norfolk Island'],
              ['MNP', 'Northern Mariana Islands'],
              ['NOR', 'Norway'],
              ['OMN', 'Oman'],
              ['PAK', 'Pakistan'],
              ['PLW', 'Palau'],
              ['PSE', 'Palestinian Territory, Occupied'],
              ['PAN', 'Panama'],
              ['PNG', 'Papua New Guinea'],
              ['PRY', 'Paraguay'],
              ['PER', 'Peru'],
              ['PHL', 'Philippines'],
              ['PCN', 'Pitcairn'],
              ['POL', 'Poland'],
              ['PRT', 'Portugal'],
              ['PRI', 'Puerto Rico'],
              ['QAT', 'Qatar'],
              ['REU', 'Réunion'],
              ['ROU', 'Romania'],
              ['RUS', 'Russian Federation'],
              ['RWA', 'Rwanda'],
              ['BLM', 'Saint Barthélemy'],
              ['SHN', 'Saint Helena, Ascension and Tristan da Cunha'],
              ['KNA', 'Saint Kitts and Nevis'],
              ['LCA', 'Saint Lucia'],
              ['MAF', 'Saint Martin (French part)'],
              ['SPM', 'Saint Pierre and Miquelon'],
              ['VCT', 'Saint Vincent and the Grenadines'],
              ['WSM', 'Samoa'],
              ['SMR', 'San Marino'],
              ['STP', 'Sao Tome and Principe'],
              ['SAU', 'Saudi Arabia'],
              ['SEN', 'Senegal'],
              ['SRB', 'Serbia'],
              ['SYC', 'Seychelles'],
              ['SLE', 'Sierra Leone'],
              ['SGP', 'Singapore'],
              ['SXM', 'Sint Maarten (Dutch part)'],
              ['SVK', 'Slovakia'],
              ['SVN', 'Slovenia'],
              ['SLB', 'Solomon Islands'],
              ['SOM', 'Somalia'],
              ['ZAF', 'South Africa'],
              ['SGS', 'South Georgia and the South Sandwich Islands'],
              ['SSD', 'South Sudan'],
              ['ESP', 'Spain'],
              ['LKA', 'Sri Lanka'],
              ['SDN', 'Sudan'],
              ['SUR', 'Suriname'],
              ['SJM', 'Svalbard and Jan Mayen'],
              ['SWZ', 'Swaziland'],
              ['SWE', 'Sweden'],
              ['CHE', 'Switzerland'],
              ['SYR', 'Syrian Arab Republic'],
              ['TWN', 'Taiwan, Province of China'],
              ['TJK', 'Tajikistan'],
              ['TZA', 'Tanzania, United Republic of'],
              ['THA', 'Thailand'],
              ['TLS', 'Timor-Leste'],
              ['TGO', 'Togo'],
              ['TKL', 'Tokelau'],
              ['TON', 'Tonga'],
              ['TTO', 'Trinidad and Tobago'],
              ['TUN', 'Tunisia'],
              ['TUR', 'Turkey'],
              ['TKM', 'Turkmenistan'],
              ['TCA', 'Turks and Caicos Islands'],
              ['TUV', 'Tuvalu'],
              ['UGA', 'Uganda'],
              ['UKR', 'Ukraine'],
              ['ARE', 'United Arab Emirates'],
              ['GBR', 'United Kingdom'],
              ['UMI', 'United States Minor Outlying Islands'],
              ['URY', 'Uruguay'],
              ['UZB', 'Uzbekistan'],
              ['VUT', 'Vanuatu'],
              ['VEN', 'Venezuela, Bolivarian Republic of'],
              ['VNM', 'Viet Nam'],
              ['VGB', 'Virgin Islands, British'],
              ['VIR', 'Virgin Islands, U.S.'],
              ['WLF', 'Wallis and Futuna'],
              ['ESH', 'Western Sahara'],
              ['YEM', 'Yemen'],
              ['ZMB', 'Zambia'],
              ['ZWE', 'Zimbabwe']
            ]}
          />
        </div>
        <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <MDCButton
            disabled={isSavingUser}
            label='Submit'
            onClick={handleClick}
            type='raised'
          />
        </div>
      </div>
    </div>
  )
}

EnterYourBusinessAddressTab.propTypes = {
}

const UploadYourLogoTab = props => {
  const dispatch = useDispatch()

  const accesseeId = useSelector(selectAccesseeId)
  const accesseeBusinessName = useSelector(selectAccesseeBusinessName)
  const accesseeLogo1Exists = useSelector(selectAccesseeLogo1Exists)
  const webSiteURL = useSelector(selectWebSiteURL)

  const selectSavingUserProfile = useMemo(makeSavingUserProfileSelector)
  const isSavingUser = useSelector(state => selectSavingUserProfile(state, 'self'))

  const cacheBuster = useMemo(Date.now, [isSavingUser])

  const [logo, setLogo] = useState(null)

  const handleFileChange = useCallback(event => {
    setLogo(event.target.files[0] || null)
  }, [setLogo])

  const handleClick = useCallback(async event => {
    if (logo) {
      const reader = new global.FileReader()
      reader.addEventListener('load', () => {
        const content = reader.result.substr(reader.result.indexOf(',') + 1)
        dispatch(saveUser({
          userLogo: {
            filename: logo.name,
            content
          }
        }))
      }, false)

      reader.readAsDataURL(logo)
    } else {
      global.appShowSnackbarMessage('No logo file has been selected!')
    }
  }, [dispatch, logo])

  return (
    <div className='mdc-layout-grid'>
      <div className='mdc-layout-grid__inner'>
        {accesseeLogo1Exists
          ? (
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-12'>
              <img
                decoding='async'
                src={`${webSiteURL}/${String(accesseeId).padStart(8, '0')}/logo/logo.png?_=${cacheBuster}`}
                alt={accesseeBusinessName}
                style={{
                  display: 'block',
                  maxHeight: '264px',
                  margin: 'auto'
                }}
              />
            </div>
          )
          : null}
        <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-12'>
          <label htmlFor='uploadLogo1File-upload'>
            <button
              type='button'
              className='mdc-button mdc-button--raised fd-file-upload-button'
            >
              <i className='material-icons mdc-button__icon' aria-hidden='true'>
                file_upload
              </i>
              <span className='mdc-button__label'>Choose File</span>
            </button>
            <span> </span>
            <span id='uploadLogo1File-upload-file'>No file chosen</span>
          </label>
          <input
            id='uploadLogo1File-upload'
            name='uploadFile'
            type='file'
            aria-hidden='true'
            className='fd-file-upload-input'
            onChange={handleFileChange}
          />
          <br />
          <MDCButton
            disabled={isSavingUser}
            label='Upload'
            onClick={handleClick}
            type='raised'
            style={{ marginTop: '1em' }}
          />
        </div>
      </div>
    </div>
  )
}

UploadYourLogoTab.propTypes = {
}

const TABS = [
  {
    component: VerifyYourEmailTab,
    label: 'Verify Your Email'
  },
  {
    component: EnterYourBusinessAddressTab,
    label: 'Enter Your Business Address'
  },
  {
    component: UploadYourLogoTab,
    label: 'Upload Your Logo'
  }
]

const renderTab = (item, index) => {
  const { component: TabContent, label } = item

  return (
    <MDCTab key={label} index={index}>
      <TabContent />
    </MDCTab>
  )
}

const GettingStartedWidget = props => {
  const { children, id, settings, title } = props

  const dispatch = useDispatch()

  const accessee = useSelector(selectAccessee)

  useEffect(() => {
    if (!accessee) {
      dispatch(fetchApplication())
    }
  }, [accessee, dispatch])

  const { current: widgetEventHandler } = useContext(WidgetEventHandlerContext)

  const drag = useContext(DragHandleContext)

  const removeEl = useRef(null)
  useEffect(() => {
    if (removeEl.current) {
      const el = removeEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const configureEl = useRef(null)
  useEffect(() => {
    if (configureEl.current) {
      const el = configureEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const [isConfigureModalOpen, setConfigureModalOpen] = useState(false)
  const closeConfigureModal = useCallback(() => setConfigureModalOpen(false), [setConfigureModalOpen])
  const saveConfiguration = useCallback((newSettings, changed) => {
    if (changed) {
      widgetEventHandler({
        type: 'configure',
        id,
        settings: newSettings
      })
    }

    setConfigureModalOpen(false)
  }, [id, setConfigureModalOpen, widgetEventHandler])

  const onConfigure = useCallback(() => {
    setConfigureModalOpen(true)
  }, [setConfigureModalOpen])

  const onRemove = useCallback(() => {
    widgetEventHandler({
      type: 'remove',
      id
    })
  }, [id, widgetEventHandler])

  return (
    <div className='panel' style={{ willChange: 'transform' }}>
      <h2>{title}</h2>
      <div className='panelContent' style={{ height: '548px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div className='panelActions'>
          <Tooltip title='Move Widget'>
            <span ref={drag} style={{ cursor: 'move' }}><DragIndicatorIcon /></span>
          </Tooltip>
        </div>
        {children}
        <div style={{ flex: 1, margin: '1em 4px', overflowY: 'auto', willChange: 'transform' }}>
          {!accessee
            ? (
              <MDCCircularProgress>Please wait...</MDCCircularProgress>
            )
            : (
              <MDCTabBar
                tabs={TABS}
              >
                {TABS.map(renderTab)}
              </MDCTabBar>
            )}
        </div>
        <div className='panelBottomActions' style={{ flex: 0 }}>
          <div className='panelBottomIcons'>
            <Tooltip title='Configure Widget'>
              <button ref={configureEl} className='mdc-icon-button' onClick={onConfigure}><SettingsIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
            <Tooltip title='Remove Widget'>
              <button ref={removeEl} className='mdc-icon-button' onClick={onRemove}><DeleteIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
          </div>
        </div>
      </div>
      <ConfigureModal
        close={closeConfigureModal}
        isOpen={isConfigureModalOpen}
        saveConfiguration={saveConfiguration}
        settings={settings}
        title={title}
      />
    </div>
  )
}

GettingStartedWidget.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  settings: PropTypes.shape(),
  title: PropTypes.string
}

const MemoizedGettingStartedWidget = memo(GettingStartedWidget)

export default MemoizedGettingStartedWidget
