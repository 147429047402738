import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchApplication } from '../actions/application'
import { fetchReportData } from '../actions/reports'

import { selectAccessee, selectAccesseeTimeZone } from '../selectors/application'
import { makeReportDataSelector } from '../selectors/reports'

import { appFontStyle } from '../utilities/constants'
import { getContactSearchCriteriaBySubscriptionType } from '../utilities/methods'

export const REPORT_ID = 'contactTrends'

const contactTrendsColors = {
  subscribedSubGroup: '#5EC442',
  addedSubGroup: '#B5CA92',
  bouncedSubGroup: '#3D96AE',
  deactivatedSubGroup: '#A47D7C',
  unsubscribed: '#DB843D',
  complained: '#AA4643'
}

const contactTrendsPositiveTypes = ['activeGroup', 'subscribedSubGroup', 'addedSubGroup', 'seamlessSignupForm', 'publicSignupForm', 'api', 'imported', 'userForm']

const useContactTrendsChart = ({ dialog, userId, settings }) => {
  const { desktopSize, tabletSize, phoneSize } = settings

  const dispatch = useDispatch()

  const accessee = useSelector(selectAccessee)
  const accesseeTimeZone = useSelector(selectAccesseeTimeZone)

  useEffect(() => {
    if (!accessee) {
      dispatch(fetchApplication())
    }
  }, [accessee, dispatch])

  const selectContactTrendsReportData = useMemo(makeReportDataSelector)
  const contactTrendsReportData = useSelector(state => selectContactTrendsReportData(state, {
    report: REPORT_ID,
    userId
  }))

  const { dateGrouping } = settings
  useEffect(() => {
    dispatch(fetchReportData(REPORT_ID, userId, {
      dateGrouping
    }))
  }, [dateGrouping, dispatch, userId])

  // MDC Init
  const refreshEl = useRef(null)
  const expandEl = useRef(null)
  const printEl = useRef(null)
  const downloadEl = useRef(null)
  useEffect(() => {
    if (refreshEl.current) {
      const el = refreshEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })
  useEffect(() => {
    if (expandEl.current) {
      const el = expandEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })
  useEffect(() => {
    if (printEl.current) {
      const el = printEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })
  useEffect(() => {
    if (downloadEl.current) {
      const el = downloadEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const containerEl = useRef(null)
  const chartInstance = useRef(null)
  const currentDataGrouping = useRef(null)
  const [isExpandedChartModalOpen, setExpandedChartModalOpen] = useState(false)

  const contactTrendsChartOptions = useMemo(() => {
    if (accesseeTimeZone && contactTrendsReportData) {
      const { data } = contactTrendsReportData
      const columnData = {
        subscribedSubGroup: null,
        addedSubGroup: null,
        unsubscribed: null,
        complained: null,
        bouncedSubGroup: null,
        deactivatedSubGroup: null
      }
      const lineData = []

      const firstStartTimestamp = data.intervals.intervalStartTimestampInclusive[0]
      if (data.intervals.intervalStartTimestampInclusive.length < 2) {
        Object.keys(columnData).forEach(type => {
          columnData[type] = [[moment(firstStartTimestamp).subtract(1, 'hour').valueOf(), 0]]
        })
      }

      // name in columnData : name in the raw data
      let zeroMembers = true
      Object.keys(columnData).forEach(type => {
        if (!data.intervals[type]) {
          columnData[type] = columnData[type] || []
          return
        }

        zeroMembers = zeroMembers && data.intervals[type].every(y => y <= 0)
        const values = contactTrendsPositiveTypes.includes(type) ? [...data.intervals[type]] : data.intervals[type].map(y => -y)
        const timestamps = data.intervals.intervalStartTimestampInclusive
        const itemLength = Math.max(timestamps.length, values.length)
        columnData[type] = []
        for (let index = 0; index < itemLength; index++) {
          columnData[type].push([timestamps[index], values[index]])
        }
      })

      const itemLength = Math.max(data.intervals.intervalStartTimestampInclusive.length, data.intervals.runningActiveCount.length)
      for (let index = 0; index < itemLength; index++) {
        lineData.push([data.intervals.intervalStartTimestampInclusive[index], data.intervals.runningActiveCount[index]])
      }

      const chart = {
        alignTicks: true,
        backgroundColor: 'rgba(0,0,0,0)',
        events: {
          load: function () {
            if (chartInstance.current === null || chartInstance.current === this) {
              var visibleSeries = this.series.filter(series => series.name !== 'Navigator' && series.visible)
              if (visibleSeries.length && visibleSeries[0].currentDataGrouping) {
                currentDataGrouping.current = visibleSeries[0].currentDataGrouping
              }

              global.requestAnimationFrame(() => {
                const $minDate = global.jQuery('input.highcharts-range-selector[name="min"]', chartInstance.current.renderTo)
                const $maxDate = global.jQuery('input.highcharts-range-selector[name="max"]', chartInstance.current.renderTo)

                $minDate.on('change', () => {
                  $maxDate.datepicker('option', 'minDate', $minDate.val())
                }).datepicker({
                  dateFormat: 'mm/dd/yy',
                  minDate: moment(data.intervals.intervalEndTimestampExclusive[0]).tz(accesseeTimeZone).format('MM/DD/YYYY'),
                  maxDate: $maxDate.val(),
                  showMinute: false
                })

                $maxDate.on('change', () => {
                  $minDate.datepicker('option', 'maxDate', $maxDate.val())
                }).datepicker({
                  dateFormat: 'mm/dd/yy',
                  minDate: $minDate.val(),
                  maxDate: moment(data.intervals.intervalStartTimestampInclusive[data.intervals.intervalStartTimestampInclusive.length - 1]).tz(accesseeTimeZone).format('MM/DD/YYYY'),
                  showMinute: false
                })
              })
            }
          },
          exportData: event => {
            const visibleSeries = chartInstance.current.series.filter(series => !!series.checkbox && series.visible)
            event.dataRows.splice(0, event.dataRows.length)
            event.dataRows.push(['From Date', 'To Date'].concat(visibleSeries.map(series => series.name)))
            const seriesData = {}; let dateGroupingUnitName = 'hour'; let dateGroupingCount = 1
            if (currentDataGrouping.current) {
              dateGroupingUnitName = currentDataGrouping.current.unitName
              dateGroupingCount = currentDataGrouping.current.count
            }

            visibleSeries.forEach((series, seriesIndex) => {
              series.groupedData.forEach(point => {
                seriesData[point.x] = seriesData[point.x] || {}
                seriesData[point.x][seriesIndex] = point.y
              })
            })

            Object.keys(seriesData).sort((a, b) => {
              var numericA = Number(a)
              var numericB = Number(b)
              return numericA < numericB ? -1 : numericA > numericB ? 1 : 0
            }).forEach((key, x) => {
              const data = seriesData[key]
              const startDate = moment(parseInt(key, 10)).tz(accesseeTimeZone).startOf(dateGroupingUnitName)
              var row = [
                startDate.format('YYYY-MM-DD HH:mm:ss'),
                startDate.add(dateGroupingCount - 1, dateGroupingUnitName).endOf(dateGroupingUnitName).format('YYYY-MM-DD HH:mm:ss')
              ]
              for (var i = 0; i < visibleSeries.length; i++) {
                row.push(data[i] || 0)
              }
              row.xValues = []
              row.x = x++
              row.name = row[0]
              event.dataRows.push(row)
            })
          }
        },
        style: {
          ...appFontStyle
        }
      }

      if (!dialog) {
        chart.height = 481
      }

      const getRangeAndFormatForPoint = point => {
        const startTime = moment(point.x).tz(accesseeTimeZone)
        const endTime = point.series.currentDataGrouping ? startTime.add(point.series.currentDataGrouping.count, point.series.currentDataGrouping.unitName + 's')
          : data.dateGrouping === 'hour' ? startTime.add(1, 'hours') : startTime.add(1, 'days')

        return {
          startTimestamp: point.x,
          endTimestamp: endTime.isAfter(moment()) ? moment().valueOf() : endTime.valueOf(),
          dateFormat: data.dateGrouping === 'hour' || point.series.options.type === 'spline' ? 'dddd, MMMM D, YYYY h:mm A' : 'dddd, MMMM D, YYYY'
        }
      }

      return data.memberCount > 0 ? {
        time: {
          timezone: accesseeTimeZone
        },
        chart,
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false,
          filename: 'ContactsTrends_' + moment().tz(accesseeTimeZone).format('YYYY-MM-DD_HHmmss'),
          url: '/highcharts-export.jsp'
        },
        labels: {
          style: {
            ...appFontStyle
          }
        },
        legend: {
          borderWidth: 1,
          borderRadius: 2,
          borderColor: '#d4d4d4',
          enabled: true,
          itemDistance: 40,
          itemCheckboxStyle: {
            width: 60,
            height: 60
          },
          itemStyle: {
            ...appFontStyle,
            fontWeight: 500
          },
          itemMarginBottom: 11,
          itemMarginTop: 11
        },
        navigator: {
          enabled: true,
          xAxis: {
            dateTimeLabelFormats: {
              hour: '%l %p',
              day: '%b %e',
              week: '%b %e',
              month: '%b \'%y',
              year: '%Y'
            },
            labels: {
              style: {
                ...appFontStyle
              }
            }
          },
          height: 30
        },
        plotOptions: {
          spline: {
            cursor: 'pointer',
            dataGrouping: {
              approximation: 'close'
            },
            point: {
              events: {
                click: function (event) {
                  const { endTimestamp } = getRangeAndFormatForPoint(this)
                  const criteria = {
                    activeAsOfTimestampExclusive: endTimestamp
                  }
                  const link = document.createElement('a')
                  link.setAttribute('href', `/view_members.jsp?criteria=${encodeURIComponent(JSON.stringify(criteria))}&returnToPrevious=${encodeURIComponent(global.location.href)}`)
                  link.style.display = 'none'
                  document.body.appendChild(link)
                  link.click()
                  link.remove()
                }
              }
            },
            shadow: true,
            zIndex: 3 // show over grid and columns (grid lines are 1, columns are 2)
          },
          column: {
            borderColor: '#d4d4d4',
            cursor: 'pointer',
            point: {
              events: {
                click: function (event) {
                  const { startTimestamp, endTimestamp } = getRangeAndFormatForPoint(this)
                  const criteria = getContactSearchCriteriaBySubscriptionType(this.series.options.subscriptionType, startTimestamp, endTimestamp)
                  delete criteria.statusList
                  const link = document.createElement('a')
                  link.setAttribute('href', `/view_members.jsp?criteria=${encodeURIComponent(JSON.stringify(criteria))}&returnToPrevious=${encodeURIComponent(global.location.href)}`)
                  link.style.display = 'none'
                  document.body.appendChild(link)
                  link.click()
                  link.remove()
                }
              }
            },
            shadow: true,
            zIndex: 2 // show over grid lines but under splines (grid lines are 2, splines are 3)
          },
          series: {
            dataGrouping: {
              enabled: true,
              groupPixelWidth: 100,
              units: [
                [
                  'hour',
                  [1] //, 2, 3, 4, 6, 8, 12]
                ], [
                  'day',
                  [1]
                ], [
                  'week',
                  [1]
                ], [
                  'month',
                  [1]
                ], [
                  'year',
                  [1]
                ]
              ]
            },
            events: {
              legendItemClick: function (e) {
                var series = this
                var index = this.options.legendIndex
                series.select()
                const checkboxEl = chartInstance.current.container.querySelectorAll('.mdc-checkbox')[index]
                if (checkboxEl) {
                  setTimeout(() => {
                    checkboxEl.MDCCheckbox.checked = series.visible
                  }, 1)
                }
              },
              checkboxClick: function (event) {
                var series = this
                var index = this.options.legendIndex
                if (series.visible) {
                  series.hide()
                } else {
                  series.show()
                }
                const checkboxEl = chartInstance.current.container.querySelectorAll('.mdc-checkbox')[index]
                if (checkboxEl) {
                  setTimeout(() => {
                    checkboxEl.MDCCheckbox.checked = series.visible
                  }, 1)
                }
              }
            },
            showCheckbox: true
          }
        },
        rangeSelector: {
          buttons: (function () {
            var buttons = []
            var startDate = moment(data.intervals.intervalStartTimestampInclusive[0])
            var endDate = moment(data.intervals.intervalEndTimestampExclusive[data.intervals.intervalEndTimestampExclusive.length - 1])

            if (startDate.add(1, 'week').isBefore(endDate)) {
              buttons.push({
                type: 'week',
                count: 1,
                text: '1w'
              })
            }

            if (startDate.add(1, 'month').isBefore(endDate)) {
              buttons.push({
                type: 'month',
                count: 1,
                text: '1m'
              })
            }

            if (startDate.add(3, 'month').isBefore(endDate)) {
              buttons.push({
                type: 'month',
                count: 3,
                text: '3m'
              })
            }

            if (startDate.add(6, 'month').isBefore(endDate)) {
              buttons.push({
                type: 'month',
                count: 6,
                text: '6m'
              })
            }

            if (startDate.year() < endDate.year()) {
              buttons.push({
                type: 'ytd',
                text: 'YTD'
              })
            }

            if (startDate.add(1, 'year').isBefore(endDate)) {
              buttons.push({
                type: 'year',
                count: 1,
                text: '1y'
              })
            }

            buttons.push({
              type: 'all',
              text: 'All'
            })

            return buttons
          }()),
          buttonTheme: {
            style: {
              ...appFontStyle
            },
            states: {
              select: {
                style: {
                  ...appFontStyle,
                  fontWeight: 500
                }
              }
            }
          },
          enabled: true,
          inputDateFormat: '%m/%d/%Y',
          inputEditDateFormat: '%m/%d/%Y',
          inputBoxFill: 'white',
          inputStyle: {
            ...appFontStyle
          },
          labelStyle: {
            ...appFontStyle
          },
          selected: (() => {
            // >3m then use 1m view
            // <3m then use 1w view (or All)
            const startDate = moment(data.intervals.intervalStartTimestampInclusive[0])
            const endDate = moment(data.intervals.intervalEndTimestampExclusive[data.intervals.intervalEndTimestampExclusive.length - 1])
            return startDate.add(3, 'month').isBefore(endDate) ? 1 : 0
          })()
        },
        scrollbar: {
          enabled: true
        },
        series: [{
          data: lineData,
          subscriptionType: 'runningActiveCount',
          dataLabels: {
            borderColor: 'black',
            borderWidth: 1,
            borderRadius: 2,
            backgroundColor: 'rgba(255,255,255,.5)',
            formatter: function (options) {
              var extremes = this.series.chart.xAxis[0].getExtremes()
              var pointsInRange = this.series.points.filter(point => point.x >= extremes.min && point.x <= extremes.max)
              var pointIndex = pointsInRange.indexOf(this.point)
              if (pointIndex === 0 || pointIndex === pointsInRange.length - 1) {
                return this.point.y
              } else {
                return false
              }
            },
            shadow: true,
            style: {
              ...appFontStyle,
              fontWeight: 500
            },
            y: -8
          },
          legendIndex: 0,
          name: 'Active',
          marker: {
            enabled: true,
            fillColor: 'rgba(255,255,255,1)',
            lineWidth: 2,
            lineColor: null,
            radius: 4,
            shadow: true
          },
          type: 'spline',
          selected: true,
          visible: true
        }, {
          color: contactTrendsColors.subscribedSubGroup,
          data: columnData.subscribedSubGroup,
          subscriptionType: 'subscribedSubGroup',
          legendIndex: 1,
          name: 'Subscribed',
          type: 'column',
          selected: true,
          visible: true,
          yAxis: 1
        }, {
          color: contactTrendsColors.addedSubGroup,
          data: columnData.addedSubGroup,
          subscriptionType: 'addedSubGroup',
          legendIndex: 2,
          name: 'Manually Added',
          type: 'column',
          selected: true,
          visible: true,
          yAxis: 1
        }, {
          color: contactTrendsColors.unsubscribed,
          data: columnData.unsubscribed,
          subscriptionType: 'unsubscribed',
          legendIndex: 3,
          name: 'Unsubscribed',
          type: 'column',
          selected: false,
          visible: false,
          yAxis: 1
        }, {
          color: contactTrendsColors.complained,
          data: columnData.complained,
          subscriptionType: 'complained',
          legendIndex: 5,
          name: 'Complained',
          type: 'column',
          selected: false,
          visible: false,
          yAxis: 1
        }, {
          color: contactTrendsColors.bouncedSubGroup,
          data: columnData.bouncedSubGroup,
          subscriptionType: 'bouncedSubGroup',
          legendIndex: 4,
          name: 'Bounced',
          type: 'column',
          selected: false,
          visible: false,
          yAxis: 1
        }, {
          color: contactTrendsColors.deactivatedSubGroup,
          data: columnData.deactivatedSubGroup,
          subscriptionType: 'deactivatedSubGroup',
          legendIndex: 6,
          name: 'Manually Deactivated',
          type: 'column',
          selected: false,
          visible: false,
          yAxis: 1
        }],
        tooltip: {
          followPointer: true,
          formatter: function () {
            const { dateFormat, startTimestamp, endTimestamp } = getRangeAndFormatForPoint(this)
            if (this.series.options.type === 'spline') {
              return 'As of ' +
                  moment(endTimestamp - 1).tz(accesseeTimeZone).format(dateFormat) + '<br><br>' +
                  '<span style="color:' + this.series.color + ';font-weight:500">' + this.series.name + '</span>: ' +
                  '<span style="font-weight:500">' + Math.abs(this.point.y) + '</span><br><br>Click chart to view contacts'
            } else {
              return 'From ' +
                  moment(startTimestamp).tz(accesseeTimeZone).format(dateFormat) +
                  '<br>To ' + moment(endTimestamp - 1).tz(accesseeTimeZone).format(dateFormat) + '<br><br>' +
                  '<span style="color:' + this.series.color + ', font-weight:500">' + this.series.name + '</span>: ' +
                  '<span style="font-weight:500">' + Math.abs(this.point.y) + '</span><br><br>Click chart to view contacts'
            }
          },
          shared: false,
          split: false,
          style: {
            ...appFontStyle
          },
          useHTML: true
        },
        xAxis: {
          dateTimeLabelFormats: {
            millisecond: '',
            second: '',
            minute: '',
            hour: '%l %p',
            day: '%b %e',
            week: '%b %e',
            month: '%b \'%y',
            year: '%Y'
          },
          events: {
            afterSetExtremes: function () {
              currentDataGrouping.current = null
              var visibleSeries = this.series.filter(series => series.name !== 'Navigator' && series.visible)
              if (visibleSeries.length && visibleSeries[0].currentDataGrouping) {
                currentDataGrouping.current = visibleSeries[0].currentDataGrouping
              }
            }
          },
          labels: {
            style: {
              fontFamily: appFontStyle.fontFamily
            }
          },
          maxPadding: 0.02,
          minPadding: 0.02,
          minRange: 1000 * 60 * 60 * (data.dateGrouping === 'hour' ? 1 : 24),
          minTickInterval: 1000 * 60 * 60 * (data.dateGrouping === 'hour' ? 1 : 24),
          startOnTick: false,
          tickPixelInterval: 100
        },
        yAxis: [{
          opposite: false,
          labels: {
            align: 'right',
            style: {
              fontFamily: appFontStyle.fontFamily
            },
            y: 4
          },
          minTickInterval: 1,
          offset: 5,
          showLastLabel: true,
          title: {
            margin: 15,
            text: 'Active Contacts (line)',
            style: {
              ...appFontStyle,
              fontWeight: 500
            }
          }
        }, {
          labels: {
            align: 'left',
            style: {
              fontFamily: appFontStyle.fontFamily
            },
            y: 4
          },
          minTickInterval: 1,
          offset: 5,
          showLastLabel: true,
          title: {
            margin: 15,
            text: 'Contacts Gained (bars)',
            style: {
              ...appFontStyle,
              fontWeight: 500
            }
          }
        }]
      } : {
        chart: {
          backgroundColor: 'rgba(0,0,0,0)',
          style: {
            ...appFontStyle
          },
          type: 'line'
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        navigator: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        series: [{
          data: [[0, 0]]
        }],
        title: {
          text: ' ',
          style: {
            ...appFontStyle,
            fontSize: '1.2rem',
            fontWeight: 500
          }
        },
        xAxis: {
          labels: {
            enabled: false
          }
        },
        yAxis: {
          opposite: false,
          min: 0,
          max: 100,
          showLastLabel: true,
          title: {
            margin: 15,
            text: 'Contacts',
            style: {
              ...appFontStyle,
              fontWeight: 500
            }
          }
        }
      }
    }
  }, [dialog, accesseeTimeZone, contactTrendsReportData])

  useEffect(() => {
    if (contactTrendsChartOptions) {
      const contactTrendsChart = chartInstance.current = global.Highcharts.stockChart(containerEl.current, contactTrendsChartOptions)

      return () => {
        contactTrendsChart.destroy()
      }
    } else {
      chartInstance.current = null
    }
  }, [contactTrendsChartOptions, chartInstance, containerEl])

  useEffect(() => {
    const contactTrendsChart = chartInstance.current
    if (contactTrendsChart) {
      contactTrendsChart.reflow()
    }
  }, [desktopSize, tabletSize, phoneSize])

  const expandChart = useCallback(event => {
    if (event) {
      event.preventDefault()
    }

    setExpandedChartModalOpen(true)
  }, [setExpandedChartModalOpen])

  const printChart = event => {
    if (event) {
      event.preventDefault()
    }

    if (!chartInstance.current) {
      return false
    }

    if (dialog) {
      chartInstance.current.setSize(780, null, false)
      chartInstance.current.print()
      chartInstance.current.setSize(chartInstance.current.chartWidth, chartInstance.current.chartHeight, false)
    } else {
      chartInstance.current.print()
    }
  }

  const downloadChart = event => {
    if (event) {
      event.preventDefault()
    }

    if (!chartInstance.current) {
      return false
    }

    const exportOptions = {
      url: '/highcharts-export.jsp'
    }

    const exportChartOptions = {
      chart: {
        backgroundColor: 'white'
      }
    }

    if (dialog) {
      exportChartOptions.chart.width = 650
    }

    switch (event.currentTarget.dataset.format) {
      case 'csv':
        chartInstance.current.downloadCSV()
        break
      case 'pdf':
        exportOptions.type = 'application/pdf'
        chartInstance.current.exportChart(exportOptions, exportChartOptions)
        break
      case 'png':
      default:
        exportOptions.type = 'image/png'
        chartInstance.current.exportChart(exportOptions, exportChartOptions)
        break
    }
  }

  const refreshData = useCallback(event => {
    if (event) {
      event.preventDefault()
    }

    dispatch(fetchReportData(REPORT_ID, userId, {
      dateGrouping
    }))
  }, [dateGrouping, dispatch, userId])

  const closeExpandedChartModal = useCallback(() => {
    setExpandedChartModalOpen(false)
  }, [setExpandedChartModalOpen])

  return [containerEl, {
    accessee,
    chartInstance,
    closeExpandedChartModal,
    contactTrendsChartOptions,
    contactTrendsReportData,
    downloadChart,
    downloadEl,
    expandChart,
    expandEl,
    isExpandedChartModalOpen,
    printChart,
    printEl,
    refreshData,
    refreshEl
  }]
}

export default useContactTrendsChart
