import { createSelector } from 'reselect'

export const selectApplication = state => state.application ? state.application : null

export const selectFetchingApplication = state => {
  const application = selectApplication(state)
  if (application) {
    return !!application.fetching
  }
  return false
}

export const selectApplicationProperties = state => {
  const application = selectApplication(state)
  if (application) {
    return application.properties
  }
  return null
}

export const selectMenuType = state => {
  const application = selectApplication(state)
  if (application) {
    return application.menuType
  }
  return null
}

export const selectMenuItem = state => {
  const application = selectApplication(state)
  if (application) {
    return application.menuItem
  }
  return null
}

export const selectPageTitle = state => {
  const application = selectApplication(state)
  if (application) {
    return application.pageTitle
  }
  return null
}

export const selectSubMenuItem = state => {
  const application = selectApplication(state)
  if (application) {
    return application.subMenuItem
  }
  return null
}

export const selectHeaderType = state => {
  const application = selectApplication(state)
  if (application) {
    return application.headerType
  }
  return null
}

export const selectAccessee = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.accessee
  }
  return null
}

export const selectAccesseeAccountMenuEnabled = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.accountMenuEnabled
  }
  return false
}

export const selectAccesseeAccountRep = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.accountRep
  }
  return false
}

export const selectAccesseeAccountRepBusinessPhone = state => {
  const accountRep = selectAccesseeAccountRep(state)
  if (accountRep) {
    return accountRep.businessPhone
  }
  return null
}

export const selectAccesseeAccountRepContactFirstName = state => {
  const accountRep = selectAccesseeAccountRep(state)
  if (accountRep) {
    return accountRep.contactFirstName
  }
  return null
}

export const selectAccesseeAccountRepContactLastName = state => {
  const accountRep = selectAccesseeAccountRep(state)
  if (accountRep) {
    return accountRep.contactLastName
  }
  return null
}

export const selectAccesseeAccountRepContactEmailAddresses = state => {
  const accountRep = selectAccesseeAccountRep(state)
  if (accountRep) {
    return accountRep['contactEmail[]']
  }
  return null
}

export const selectAccesseeAccountRepFirstContactEmailAddress = createSelector(
  selectAccesseeAccountRepContactEmailAddresses,
  contactEmails => contactEmails && contactEmails.length ? contactEmails[0] : null
)

export const selectAccesseeActiveContacts = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.activeContacts !== undefined ? accessee.activeContacts : -1
  }
  return -1
}

export const selectAccesseeAnniversaryCampaignsEnabled = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.anniversaryCampaignsEnabled
  }
  return false
}

export const selectAccesseeBillingId = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.billingId
  }
  return 0
}

export const selectBillingURL = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.BillingAdminURL
  }
  return false
}

export const selectAccesseeBillingPaid = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.billingPaid
  }
  return false
}

export const selectAccesseeBirthdayCampaignsEnabled = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.birthdayCampaignsEnabled
  }
  return false
}

export const selectAccesseeBusinessCity = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.businessCity
  }
  return false
}

export const selectAccesseeBusinessCountry = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.businessCountry
  }
  return false
}

export const selectAccesseeBusinessName = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.businessName
  }
  return false
}

export const selectAccesseeBusinessState = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.businessState
  }
  return false
}

export const selectAccesseeBusinessStreetAddress = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.businessStreetAddress
  }
  return false
}

export const selectAccesseeBusinessZipcode = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.businessZIPCode
  }
  return false
}

export const selectAccesseeCampaignSharing = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.campaignSharing
  }
  return false
}

export const selectAccesseeCampaignsMenuEnabled = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.campaignsMenuEnabled
  }
  return false
}

export const selectAccesseeContactEmailAddresses = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee['contactEmail[]']
  }
  return null
}

export const selectAccesseeContactFirstName = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.contactFirstName
  }
  return null
}

export const selectAccesseeContactLastName = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.contactLastName
  }
  return null
}

export const selectAccesseeDesigner = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.designer
  }
  return false
}

export const selectAccesseeDesignerBusinessPhone = state => {
  const designer = selectAccesseeDesigner(state)
  if (designer) {
    return designer.businessPhone
  }
  return null
}

export const selectAccesseeDesignerContactFirstName = state => {
  const designer = selectAccesseeDesigner(state)
  if (designer) {
    return designer.contactFirstName
  }
  return null
}

export const selectAccesseeDesignerContactLastName = state => {
  const designer = selectAccesseeDesigner(state)
  if (designer) {
    return designer.contactLastName
  }
  return null
}

export const selectAccesseeDesignerContactEmailAddresses = state => {
  const designer = selectAccesseeDesigner(state)
  if (designer) {
    return designer['contactEmail[]']
  }
  return null
}

export const selectAccesseeDesignerFirstContactEmailAddress = createSelector(
  selectAccesseeDesignerContactEmailAddresses,
  contactEmails => contactEmails && contactEmails.length ? contactEmails[0] : null
)

export const selectAccesseeEmailSendingLimit = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.emailSendingLimit !== undefined ? accessee.emailSendingLimit : -1
  }
  return -1
}

export const selectAccesseeEmailsSentThisMonth = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.emailsSentThisMonth !== undefined ? accessee.emailsSentThisMonth : -1
  }
  return -1
}

export const selectAccesseeFirstContactEmailAddress = createSelector(
  selectAccesseeContactEmailAddresses,
  contactEmails => contactEmails && contactEmails.length ? contactEmails[0] : null
)

export const selectAccesseeGravatarEmailHex = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.gravatarEmailHex
  }
  return null
}

export const selectAccesseeId = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.id
  }
  return null
}

export const selectAccesseeLogo1Exists = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.logo1Exists
  }
  return false
}

export const selectAccesseeMaxActiveContacts = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.maxActiveContacts !== undefined ? accessee.maxActiveContacts : -1
  }
  return -1
}

export const selectAccesseeOnlineSignup = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.onlineSignup
  }
  return false
}

export const selectAccesseeParentUserId = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.parentUserId
  }
  return null
}

export const selectAccesseeReportsMenuEnabled = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.reportsMenuEnabled
  }
  return false
}

export const selectAccesseeSubUsers = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee['subUser[]']
  }
  return null
}

export const selectAccesseeSystemTemplateLibraryEnabled = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.systemTemplateLibraryEnabled
  }
  return false
}

export const selectAccesseeTemplateUploadEnabled = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.templateUploadEnabled
  }
  return false
}

export const selectAccesseeTimeZone = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.timeZone
  }
  return null
}

export const selectAccesseeUsername = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.username
  }
  return null
}

export const selectAccesseeUserType = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.userType
  }
  return null
}

export const selectAccesseeWelcomeCampaignsEnabled = state => {
  const accessee = selectAccessee(state)
  if (accessee) {
    return accessee.welcomeCampaignsEnabled
  }
  return false
}

export const selectAccessor = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.accessor
  }
  return null
}

export const selectAccessorAccountMenuEnabled = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.accountMenuEnabled
  }
  return false
}

export const selectAccessorAccountRep = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.accountRep
  }
  return false
}

export const selectAccessorAccountRepBusinessPhone = state => {
  const accountRep = selectAccessorAccountRep(state)
  if (accountRep) {
    return accountRep.businessPhone
  }
  return null
}

export const selectAccessorAccountRepContactFirstName = state => {
  const accountRep = selectAccessorAccountRep(state)
  if (accountRep) {
    return accountRep.contactFirstName
  }
  return null
}

export const selectAccessorAccountRepContactLastName = state => {
  const accountRep = selectAccessorAccountRep(state)
  if (accountRep) {
    return accountRep.contactLastName
  }
  return null
}

export const selectAccessorAccountRepContactEmailAddresses = state => {
  const accountRep = selectAccessorAccountRep(state)
  if (accountRep) {
    return accountRep['contactEmail[]']
  }
  return null
}

export const selectAccessorAccountRepFirstContactEmailAddress = createSelector(
  selectAccessorAccountRepContactEmailAddresses,
  contactEmails => contactEmails && contactEmails.length ? contactEmails[0] : null
)

export const selectAccessorActiveContacts = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.activeContacts !== undefined ? accessor.activeContacts : -1
  }
  return -1
}

export const selectAccessorAnniversaryCampaignsEnabled = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.anniversaryCampaignsEnabled
  }
  return false
}

export const selectAccessorBillingId = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.billingId
  }
  return 0
}

export const selectAccessorBillingPaid = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.billingPaid
  }
  return false
}

export const selectAccessorBirthdayCampaignsEnabled = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.birthdayCampaignsEnabled
  }
  return false
}

export const selectAccessorBusinessCity = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.businessCity
  }
  return false
}

export const selectAccessorBusinessCountry = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.businessCountry
  }
  return false
}

export const selectAccessorBusinessName = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.businessName
  }
  return false
}

export const selectAccessorBusinessState = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.businessState
  }
  return false
}

export const selectAccessorBusinessStreetAddress = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.businessStreetAddress
  }
  return false
}

export const selectAccessorBusinessZipcode = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.businessZIPCode
  }
  return false
}

export const selectAccessorCampaignSharing = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.campaignSharing
  }
  return false
}

export const selectAccessorCampaignsMenuEnabled = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.campaignsMenuEnabled
  }
  return false
}

export const selectAccessorContactEmailAddresses = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor['contactEmail[]']
  }
  return null
}

export const selectAccessorContactFirstName = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.contactFirstName
  }
  return null
}

export const selectAccessorContactLastName = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.contactLastName
  }
  return null
}

export const selectAccessorDesigner = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.designer
  }
  return false
}

export const selectAccessorDesignerBusinessPhone = state => {
  const designer = selectAccessorDesigner(state)
  if (designer) {
    return designer.businessPhone
  }
  return null
}

export const selectAccessorDesignerContactFirstName = state => {
  const designer = selectAccessorDesigner(state)
  if (designer) {
    return designer.contactFirstName
  }
  return null
}

export const selectAccessorDesignerContactLastName = state => {
  const designer = selectAccessorDesigner(state)
  if (designer) {
    return designer.contactLastName
  }
  return null
}

export const selectAccessorDesignerContactEmailAddresses = state => {
  const designer = selectAccessorDesigner(state)
  if (designer) {
    return designer['contactEmail[]']
  }
  return null
}

export const selectAccessorDesignerFirstContactEmailAddress = createSelector(
  selectAccessorDesignerContactEmailAddresses,
  contactEmails => contactEmails && contactEmails.length ? contactEmails[0] : null
)

export const selectAccessorEmailSendingLimit = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.emailSendingLimit !== undefined ? accessor.emailSendingLimit : -1
  }
  return -1
}

export const selectAccessorEmailsSentThisMonth = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.emailsSentThisMonth !== undefined ? accessor.emailsSentThisMonth : -1
  }
  return -1
}

export const selectAccessorFirstContactEmailAddress = createSelector(
  selectAccessorContactEmailAddresses,
  contactEmails => contactEmails && contactEmails.length ? contactEmails[0] : null
)

export const selectAccessorGravatarEmailHex = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.gravatarEmailHex
  }
  return null
}

export const selectAccessorId = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.id
  }
  return null
}

export const selectAccessorLogo1Exists = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.logo1Exists
  }
  return false
}

export const selectAccessorMaxActiveContacts = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.maxActiveContacts !== undefined ? accessor.maxActiveContacts : -1
  }
  return -1
}

export const selectAccessorOnlineSignup = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.onlineSignup
  }
  return false
}

export const selectAccessorParentUserId = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.parentUserId
  }
  return null
}

export const selectAccessorReportsMenuEnabled = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.reportsMenuEnabled
  }
  return false
}

export const selectAccessorSubUsers = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor['subUser[]']
  }
  return null
}

export const selectAccessorSystemTemplateLibraryEnabled = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.systemTemplateLibraryEnabled
  }
  return false
}

export const selectAccessorTemplateUploadEnabled = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.templateUploadEnabled
  }
  return false
}

export const selectAccessorTimeZone = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.timeZone
  }
  return null
}

export const selectAccessorUsername = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.username
  }
  return null
}

export const selectAccessorUserType = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.userType
  }
  return null
}

export const selectAccessorWelcomeCampaignsEnabled = state => {
  const accessor = selectAccessor(state)
  if (accessor) {
    return accessor.welcomeCampaignsEnabled
  }
  return false
}

export const selectCampaignSharingEnabled = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.campaignSharingEnabled
  }
  return false
}

export const selectBuildNumber = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.buildNumber
  }
  return null
}

export const selectDomain = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.domain
  }
  return null
}

export const selectHasAdminPrivileges = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.hasAdminPrivileges
  }
  return false
}

export const selectHasExportEnabled = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.hasExportEnabled
  }
  return false
}

export const selectHasContactsMenuEnabled = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.hasContactMenuEnabled
  }
  return false
}

export const selectHasAccountMenuEnabled = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.hasAccountMenuEnabled
  }
  return false
}

export const selectHasReportMenuEnabled = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.hasReportMenuEnabled
  }
  return false
}

export const selectHeaderTagLine = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.headerTagLine
  }
  return null
}

export const selectI18N = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.i18n
  }
  return null
}

export const selectIsAbuseMonitoringEnabled = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.isAbuseMonitoringEnabled
  }
  return true
}

export const selectIsFireDrum = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.isFireDrum
  }
  return false
}

export const selectIsHelmsBriscoe = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.isHelmsBriscoe
  }
  return false
}

export const selectIsIloyal = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.isIloyal
  }
  return false
}

export const selectIsWeddingMBA = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.isWeddingMBA
  }
  return false
}

export const selectKnowledgeBaseWebSiteURL = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.knowledgeBaseWebSiteURL
  }
  return null
}

export const selectLiveChatEnabled = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.liveChatEnabled
  }
  return false
}

export const selectReseller = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.reseller
  }
  return null
}

export const selectResellerBusinessName = state => {
  const reseller = selectReseller(state)
  if (reseller) {
    return reseller.businessName
  }
  return null
}

export const selectResellerId = state => {
  const reseller = selectReseller(state)
  if (reseller) {
    return reseller.id
  }
  return null
}

export const selectShowResellerChangeLog = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.showResellerChangeLog
  }
  return false
}

export const selectShowUserChangeLog = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.showUserChangeLog
  }
  return false
}

export const selectSystemEmailFromAddress = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.systemEmailFromAddress
  }
  return null
}

export const selectSystemNotifications = state => {
  const application = selectApplication(state)
  if (application) {
    return application.systemNotifications
  }

  return []
}

export const selectSystemTemplateLibraryEnabled = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.systemTemplateLibraryEnabled
  }
  return true
}

export const selectTimeZones = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.timeZones
  }
  return null
}

export const selectUserFeedbackEnabled = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.userFeedbackEnabled
  }
  return false
}

export const selectWebSiteURL = state => {
  const properties = selectApplicationProperties(state)
  if (properties) {
    return properties.webSiteURL
  }
  return null
}
