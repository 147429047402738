import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { DndProvider } from 'react-dnd'
import ReactDOM from 'react-dom'
import { Provider, ReactReduxContext } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { getDomain } from 'tldts'
import 'twix'
import uuidv4 from 'uuid/v4'

import createRootReducer from './reducers'
import sagas from './sagas'

import App from './App'

import CodeYourOwn from './components/CodeYourOwn'
import Dashboard from './components/Dashboard'
import ScheduledSendingsCalendar from './components/ScheduledSendingsCalendar'
import CustomDragLayer from './components/UtilityComponents/CustomDragLayer'

import { HTML5toTouch, MultiBackend } from './utilities/multiDnDBackend'

const sagaMiddleware = createSagaMiddleware()
const middlewares = process.env.NODE_ENV === 'development' ? [require('redux-freeze'), sagaMiddleware] : [sagaMiddleware]
const enhancer = compose(
  applyMiddleware(...middlewares),
  global.__REDUX_DEVTOOLS_EXTENSION__ ? global.__REDUX_DEVTOOLS_EXTENSION__() : noop => noop
)

let content = ''
let preloadedState = {}
if (global.__PRELOADED_STATE__) {
  preloadedState = global.__PRELOADED_STATE__
  delete global.__PRELOADED_STATE__
  content = preloadedState.contentHACK
  delete preloadedState.contentHACK
}
const store = createStore(createRootReducer(), preloadedState, enhancer)

sagaMiddleware.run(sagas.application)
sagaMiddleware.run(sagas.user)
sagaMiddleware.run(sagas.reports)
sagaMiddleware.run(sagas.lists)
sagaMiddleware.run(sagas.customFields)
sagaMiddleware.run(sagas.contacts)
sagaMiddleware.run(sagas.verifiedEmailAddresses)
sagaMiddleware.run(sagas.websocket)

let themeOverrides = null
switch (getDomain(global.origin)) {
  case 'arizonacollegeemail.com':
    themeOverrides = {
      palette: {
        primary: {
          main: '#303f9f'
        },
        secondary: {
          main: '#536dfe'
        }
      }
    }
    break
  case 'lucorpemarketing.com':
    themeOverrides = {
      palette: {
        primary: {
          main: '#0a3f83'
        },
        secondary: {
          main: '#aa9d89'
        }
      }
    }
    break
  case 'marketing2inbox.com':
    themeOverrides = {
      palette: {
        primary: {
          main: '#2581c5'
        },
        secondary: {
          main: '#8bc53f'
        }
      }
    }
    break
  case 'greenmailinternetmarketing.com':
    themeOverrides = {
      palette: {
        primary: {
          main: '#34770d'
        },
        secondary: {
          main: '#dd9933'
        }
      }
    }
    break
  case 'hbclientnews.com':
    themeOverrides = {
      palette: {
        primary: {
          main: '#42738d'
        },
        secondary: {
          main: '#7ea8ad'
        }
      }
    }
    break
  case 'plm2e.com':
    themeOverrides = {
      palette: {
        primary: {
          main: '#e07000'
        },
        secondary: {
          main: '#828221'
        }
      }
    }
    break
  case 'vetsend.com.au':
    themeOverrides = {
      palette: {
        primary: {
          main: '#003f72'
        },
        secondary: {
          main: '#2ea3f2'
        }
      }
    }
    break
  default:
    themeOverrides = {
      palette: {
        primary: {
          main: '#e64a19'
        },
        secondary: {
          main: '#039be5'
        }
      }
    }
    break
}
/*
themeOverrides.props = {
  ToolTip: {
    // The default props to change
  }
}
*/

const theme = createMuiTheme(themeOverrides)

const rootEl = document.getElementById('root')

let portals = {}
let setPortals = () => {}

const breakOutSetter = (value, setter) => {
  portals = value
  setPortals = setter
}

const HackUntilWeAreEntirelyMigratedToReact = props => {
  const [portals, setPortals] = useState({})

  // HACK: Update the pointers outside of this component!
  breakOutSetter(portals, setPortals)

  return (
    <Provider store={store} context={ReactReduxContext}>
      <MuiThemeProvider theme={theme}>
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <CustomDragLayer />
          <App content={content} />
          {Object.keys(portals).map(id => portals[id])}
        </DndProvider>
      </MuiThemeProvider>
    </Provider>
  )
}

const code = {
  codeYourOwn: {
    start: (target, callback) => {
      if (target) {
        if (rootEl) {
          const id = uuidv4()
          target.setAttribute('data-portal-id', id)
          setPortals({
            ...portals,
            [id]: ReactDOM.createPortal(
              <CodeYourOwn
                callback={callback}
              />,
              target
            )
          })
        } else {
          ReactDOM.render(
            <Provider store={store} context={ReactReduxContext}>
              <MuiThemeProvider theme={theme}>
                <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                  <CodeYourOwn
                    callback={callback}
                  />
                </DndProvider>
              </MuiThemeProvider>
            </Provider>,
            target
          )
        }
      }
    },
    end: target => {
      if (target) {
        if (rootEl) {
          const newPortals = {
            ...portals
          }
          delete newPortals[target.getAttribute('data-portal-id')]
          target.setAttribute('data-portal-id', false)
          setPortals(newPortals)
        } else {
          ReactDOM.unmountComponentAtNode(target)
        }
      }
    }
  },
  userDashboard: {
    start: (target, settings = {}) => {
      if (target) {
        const { userId, usePageloadProgress } = settings
        if (rootEl) {
          const id = uuidv4()
          target.setAttribute('data-portal-id', id)
          setPortals({
            ...portals,
            [id]: ReactDOM.createPortal(
              <Dashboard
                type='User'
                userId={userId || 'self'}
                usePageloadProgress={!!usePageloadProgress}
              />,
              target
            )
          })
        } else {
          ReactDOM.render(
            <Provider store={store} context={ReactReduxContext}>
              <MuiThemeProvider theme={theme}>
                <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                  <Dashboard
                    type='User'
                    userId={userId || 'self'}
                    usePageloadProgress={!!usePageloadProgress}
                  />
                </DndProvider>
              </MuiThemeProvider>
            </Provider>,
            target
          )
        }
      }
    },
    end: target => {
      if (target) {
        if (rootEl) {
          const newPortals = {
            ...portals
          }
          delete newPortals[target.getAttribute('data-portal-id')]
          target.setAttribute('data-portal-id', false)
          setPortals(newPortals)
        } else {
          ReactDOM.unmountComponentAtNode(target)
        }
      }
    }
  },
  contactsDashboard: {
    start: (target, settings = {}) => {
      if (target) {
        const { userId, usePageloadProgress } = settings
        if (rootEl) {
          const id = uuidv4()
          target.setAttribute('data-portal-id', id)
          setPortals({
            ...portals,
            [id]: ReactDOM.createPortal(
              <Dashboard
                type='Contacts'
                userId={userId || 'self'}
                usePageloadProgress={!!usePageloadProgress}
              />,
              target
            )
          })
        } else {
          ReactDOM.render(
            <Provider store={store} context={ReactReduxContext}>
              <MuiThemeProvider theme={theme}>
                <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                  <Dashboard
                    type='Contacts'
                    userId={userId || 'self'}
                    usePageloadProgress={!!usePageloadProgress}
                  />
                </DndProvider>
              </MuiThemeProvider>
            </Provider>,
            target
          )
        }
      }
    },
    end: target => {
      if (target) {
        if (rootEl) {
          const newPortals = {
            ...portals
          }
          delete newPortals[target.getAttribute('data-portal-id')]
          target.setAttribute('data-portal-id', false)
          setPortals(newPortals)
        } else {
          ReactDOM.unmountComponentAtNode(target)
        }
      }
    }
  },
  scheduledSendingsCalendar: {
    start: (target, settings = {}) => {
      if (target) {
        const effectiveSettings = {
          showTitle: true,
          usePageloadProgress: false,
          ...settings
        }
        const { usePageloadProgress, showTitle } = effectiveSettings
        if (rootEl) {
          const id = uuidv4()
          target.setAttribute('data-portal-id', id)
          setPortals({
            ...portals,
            [id]: ReactDOM.createPortal(
              <ScheduledSendingsCalendar
                usePageloadProgress={!!usePageloadProgress}
                showTitle={!!showTitle}
              />,
              target
            )
          })
        } else {
          ReactDOM.render(
            <Provider store={store} context={ReactReduxContext}>
              <MuiThemeProvider theme={theme}>
                <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                  <ScheduledSendingsCalendar
                    usePageloadProgress={!!usePageloadProgress}
                  />
                </DndProvider>
              </MuiThemeProvider>
            </Provider>,
            target
          )
        }
      }
    },
    end: target => {
      if (target) {
        if (rootEl) {
          const newPortals = {
            ...portals
          }
          delete newPortals[target.getAttribute('data-portal-id')]
          target.setAttribute('data-portal-id', false)
          setPortals(newPortals)
        } else {
          ReactDOM.unmountComponentAtNode(target)
        }
      }
    }
  }
}

global.moment = moment
global.fdReactComponents = code

if (rootEl) {
  ReactDOM.render(
    <HackUntilWeAreEntirelyMigratedToReact />,
    rootEl
  )
} else {
  global.$(() => {
    global.fdFireCustomEvent('reactReady')
  })
}

export default code
