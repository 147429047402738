import {
  FETCH_CUSTOM_FIELDS,
  FETCHING_CUSTOM_FIELDS,
  SET_CUSTOM_FIELDS
} from '../constants/customFields'

export function fetchCustomFields () {
  return {
    type: FETCH_CUSTOM_FIELDS
  }
}

export function fetchingCustomFields () {
  return {
    type: FETCHING_CUSTOM_FIELDS
  }
}

export function setCustomFields (customFields) {
  return {
    type: SET_CUSTOM_FIELDS,
    customFields
  }
}
