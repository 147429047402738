import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import LiveHelpIcon from '@material-ui/icons/Forum'
import MenuIcon from '@material-ui/icons/Menu'
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import FeedbackIcon from '@material-ui/icons/Feedback'
import TrendingUp from '@material-ui/icons/TrendingUp'
import { fetchApplication } from './actions/application'
import { startWebSocket, stopWebSocket } from './actions/websocket'

import {
  selectAccessee,
  selectAccesseeBillingId,
  selectAccesseeUserType,
  selectDomain,
  selectHeaderTagLine,
  selectHeaderType,
  selectLiveChatEnabled,
  selectResellerBusinessName,
  selectPageTitle,
  selectSystemNotifications,
  selectWebSiteURL,
  selectUserFeedbackEnabled
} from './selectors/application'

import MainMenu from './components/MainMenu'
import ProfileMenu from './components/ProfileMenu'
import Tooltip from './components/MUIComponents/Tooltip'

const toggleLiveChat = () => {
  if (global.$_Tawk !== undefined && global.$_Tawk.ready) {
    global.$_Tawk.toggle()
  }
}

const openFeedback = () => {
  if (global._urq) {
    global._urq.push(['Feedback_Open'])
  }
}

const upgradePlan = () => {
  global.location.href = '/client_admin_billing.jsp?function=upgrade'
}

const setMenuDrawerOpenCookieToTrue = () => {
  document.cookie = 'menuDrawerOpen=1; path=/'
}

let initialized = false
const App = ({ content }) => {
  const drawerEl = useRef(null)
  const drawerContentEl = useRef(null)
  const topAppBarEl = useRef(null)
  const contentFrameEl = useRef(null)
  const mainContentEl = useRef(null)

  useEffect(() => {
    const drawerElement = drawerEl.current
    if (drawerElement) {
      const drawer = drawerElement.MDCDrawer = new global.mdc.drawer.MDCDrawer(drawerElement)
      const topAppBarElement = topAppBarEl.current
      if (topAppBarElement) {
        const topAppBar = topAppBarElement.MDCTopAppBar = new global.mdc.topAppBar.MDCTopAppBar(topAppBarElement)

        if (contentFrameEl.current) {
          topAppBar.setScrollTarget(contentFrameEl.current)
        }

        // Hook the Top App Bar Nav Button to the MDCDrawer instance
        topAppBar.listen('MDCTopAppBar:nav', () => {
          drawer.open = !drawer.open
        })
      }

      // Recommended for accessibility
      const closeDrawer = () => {
        drawer.open = false
      }

      const drawerContentElement = drawerContentEl.current
      if (drawerContentElement) {
        drawerContentElement.addEventListener('click', closeDrawer)
      }

      const mainContentElement = mainContentEl.current
      const setMenuDrawerOpenCookieToFalse = () => {
        if (mainContentElement) {
          const focusable = mainContentElement.querySelector('input, button')
          if (focusable) {
            focusable.focus()
          }
        }

        // TODO: Remove this when fully converted to a single page app
        document.cookie = 'menuDrawerOpen=0; path=/'
      }

      // TODO: Remove this when fully converted to a single page app
      if (global.fdReadCookie('menuDrawerOpen') === '1') {
        // Turn this back on when the mdc-drawer--rail variant is supported
        // drawer.open = true;
      }

      drawerElement.addEventListener('MDCDrawer:closed', setMenuDrawerOpenCookieToFalse)

      // TODO: Remove this when fully converted to a single page app
      drawerElement.addEventListener('MDCDrawer:opened', setMenuDrawerOpenCookieToTrue)

      return () => {
        drawer.destroy()
        delete drawerElement.MDCDrawer

        if (topAppBarElement) {
          topAppBarElement.MDCTopAppBar.destroy()
          delete topAppBarElement.MDCTopAppBar
        }

        if (drawerContentElement) {
          drawerContentElement.removeEventListener('click', closeDrawer)
        }

        drawerElement.removeEventListener('MDCDrawer:closed', setMenuDrawerOpenCookieToFalse)
        drawerElement.removeEventListener('MDCDrawer:opened', setMenuDrawerOpenCookieToTrue)
      }
    }
  }, [])

  useEffect(() => {
    if (!initialized) {
      initialized = true
      global.fdFireCustomEvent('reactReady')
    }
  }, [])

  const dispatch = useDispatch()

  const accessee = useSelector(selectAccessee)
  const accesseeBillingId = useSelector(selectAccesseeBillingId)
  const accesseeUserType = useSelector(selectAccesseeUserType)

  const domain = useSelector(selectDomain)
  const headerTagLine = useSelector(selectHeaderTagLine)
  const headerType = useSelector(selectHeaderType)
  const liveChatEnabled = useSelector(selectLiveChatEnabled)
  const resellerBusinessName = useSelector(selectResellerBusinessName)
  const pageTitle = useSelector(selectPageTitle)
  const systemNotifications = useSelector(selectSystemNotifications)
  const webSiteURL = useSelector(selectWebSiteURL)
  const userFeedbackEnabled = useSelector(selectUserFeedbackEnabled)

  useEffect(() => {
    if (!accessee) {
      dispatch(stopWebSocket())
      dispatch(fetchApplication())
    } else {
      dispatch(startWebSocket())
    }
  }, [accessee, dispatch])

  let headerItems = null
  switch (headerType) {
    case 'signup_iloyal':
    case 'signup_greenmail':
      break
    case 'blank':
      if (liveChatEnabled || (accessee !== null && accesseeUserType === 'user' && accesseeBillingId !== 0)) {
        headerItems = (
          <>
            {liveChatEnabled &&
              <Tooltip title='Live Chat'>
                <button className='mdc-top-app-bar__action-item mdc-icon-button' aria-label='Live Chat' onClick={toggleLiveChat}>
                  <LiveHelpIcon />
                </button>
              </Tooltip>}
            {userFeedbackEnabled &&
              <Tooltip title='Feedback'>
                <button className='mdc-top-app-bar__action-item mdc-icon-button' aria-label='Feedback' onClick={openFeedback}>
                  <FeedbackIcon />
                </button>
              </Tooltip>}
            {accessee !== null && accesseeUserType === 'user' && accesseeBillingId !== 0 &&
              <Tooltip title='Upgrade Plan'>
                <button className='mdc-top-app-bar__action-item mdc-icon-button' aria-label='Upgrade Plan' onClick={upgradePlan}>
                  <TrendingUp />
                </button>
              </Tooltip>}
          </>
        )
      }
      break
    default:
      if (accessee != null) {
        headerItems = (
          <>
            {liveChatEnabled &&
              <Tooltip title='Live Chat'>
                <button className='mdc-top-app-bar__action-item mdc-icon-button' aria-label='Live Chat' onClick={toggleLiveChat}>
                  <LiveHelpIcon />
                </button>
              </Tooltip>}
            {userFeedbackEnabled &&
              <Tooltip title='Feedback'>
                <button className='mdc-top-app-bar__action-item mdc-icon-button' aria-label='Feedback' onClick={openFeedback}>
                  <FeedbackIcon />
                </button>
              </Tooltip>}
            {accessee !== null && accesseeUserType === 'user' && accesseeBillingId !== 0 &&
              <Tooltip title='Upgrade Plan'>
                <button className='mdc-top-app-bar__action-item mdc-icon-button' aria-label='Upgrade Plan' onClick={upgradePlan}>
                  <TrendingUp />
                </button>
              </Tooltip>}
            <ProfileMenu />
          </>
        )
      }
      break
  }

  const openDashboard = () => {
    switch (accesseeUserType) {
      case 'admin':
        global.location.href = '/user.jsp'
        break
      case 'user':
        global.location.href = '/client_admin.jsp'
        break
      default:
        global.location.href = '/client_admin.jsp'
        break
    }
  }

  const [isMadeInUsaModalOpen, setMadeInUsaModalOpen] = useState(false)
  const onClickMadeInUsa = useCallback(() => {
    setMadeInUsaModalOpen(true)
  }, [setMadeInUsaModalOpen])

  const closeMadeInUsaModal = () => {
    setMadeInUsaModalOpen(false)
  }

  return (
    <>
      <div
        id='initMask'
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 100001,
          background: '#FFFFFF',
          opacity: 0,
          display: 'none'
        }}
      >
        <div
          style={{
            width: '40%',
            minWidth: '500px',
            height: '20%',
            minHeight: '168px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'table',
            verticalAlign: 'middle',
            lineHeight: '100%',
            tableLayout: 'fixed'
          }}
        >
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              textAlign: 'center',
              fontSize: '32px',
              lineHeight: '84px',
              color: '#333333',
              fontFamily: 'Roboto, sans-serif',
              backgroundColor: '#EEEEEE',
              border: '1px solid rgba(0, 0, 0, .5)',
              borderRadius: '8px'
            }}
          >
            <img
              src={`${webSiteURL}brand/${domain}/images/icon-loop-white.gif`}
              alt='Loading..'
              style={{
                willChange: 'transform',
                verticalAlign: 'middle'
              }}
            />
            <span
              style={{
                marginLeft: '16px',
                whiteSpace: 'nowrap'
              }}
            >
              <span className='initMaskMessage' />
              <span className='dots'>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className='fd-top-wrapper'>
        {systemNotifications && systemNotifications.map(({ id, message, type }) => (
          <div key={id} className={`header_bar system_notification ${type}`}>
            <span dangerouslySetInnerHTML={{ __html: message }} />
            <span className='system_notification_close_button' data-system-notification-id={id}><CloseIcon /></span>
          </div>))}
        <div className='fd-top-wrapper-row'>
          <aside id='fd_drawer_permanent' className='mdc-drawer fd-drawer'>
            <div className='mdc-drawer__header' onClick={openDashboard}>
              <i className='app-logo' />
              {!!resellerBusinessName &&
                <div className='fd-drawer-header-text'>
                  <h3 className='mdc-drawer__title'>{resellerBusinessName}</h3>
                  {!!headerTagLine &&
                    <h6 className='mdc-drawer__subtitle'>{headerTagLine}</h6>}
                </div>}
            </div>
            <div id='fd_fab' />
            <div className='mdc-drawer__content'>
              <nav className='mdc-list' data-mdc-auto-init='MDCList'>
                <MainMenu
                  type='permanent'
                  onClickMadeInUsa={onClickMadeInUsa}
                />
              </nav>
            </div>
          </aside>
          <aside ref={drawerEl} id='fd_drawer' className='mdc-drawer mdc-drawer--modal fd-drawer'>
            <div className='mdc-drawer__header'>
              <div className='fd-drawer-header-text'>
                <h3 className='mdc-drawer__title'>{resellerBusinessName}</h3>
                {!!headerTagLine &&
                  <h6 className='mdc-drawer__subtitle'>{headerTagLine}</h6>}
              </div>
            </div>
            <nav ref={drawerContentEl} className='mdc-drawer__content'>
              <MainMenu
                onClickMadeInUsa={onClickMadeInUsa}
              />
            </nav>
          </aside>
          <div className='mdc-drawer-scrim' />
          <div className='fd-app-content'>
            <header ref={topAppBarEl} id='fd_top_app_bar' className='fd-header mdc-top-app-bar mdc-top-app-bar--fixed'>
              <div className='mdc-top-app-bar__row'>
                <section className='mdc-top-app-bar__section mdc-top-app-bar__section--align-start'>
                  {accessee &&
                    <button className='mdc-top-app-bar__navigation-icon mdc-icon-button'><MenuIcon /></button>}
                  <span className='mdc-top-app-bar__title'>{pageTitle}</span>
                </section>
                <section className='mdc-top-app-bar__section mdc-top-app-bar__section--align-end'>
                  {headerItems}
                </section>
              </div>
              <div id='fd_pageload_progress' role='progressbar' className='mdc-linear-progress mdc-linear-progress--indeterminate mdc-linear-progress--closed'>
                <div className='mdc-linear-progress__buffering-dots' />
                <div className='mdc-linear-progress__buffer' />
                <div className='mdc-linear-progress__bar mdc-linear-progress__primary-bar'>
                  <span className='mdc-linear-progress__bar-inner' />
                </div>
                <div className='mdc-linear-progress__bar mdc-linear-progress__secondary-bar'>
                  <span className='mdc-linear-progress__bar-inner' />
                </div>
              </div>
            </header>
            <div ref={contentFrameEl} className='fd-content-frame'>
              <div className='mdc-top-app-bar--fixed-adjust' />
              <main className='fd-main'>
                <div className='mainLayoutWrapper'>
                  <div id='mainLayoutContent' className='mainLayoutContent'>
                    <div ref={mainContentEl} id='mainBody' dangerouslySetInnerHTML={{ __html: content || '' }} />
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        onClose={closeMadeInUsaModal}
        open={isMadeInUsaModalOpen}
        PaperProps={{
          style: {
            width: '450px',
            maxWidth: '100%',
            maxHeight: '100%',
            height: '180px'
          }
        }}
      >
        <Tooltip title='Close'>
          <div style={{ position: 'absolute', top: '8px', right: '8px', zIndex: 1, cursor: 'pointer' }} onClick={closeMadeInUsaModal}><CloseIcon /></div>
        </Tooltip>
        <div className='panel' style={{ willChange: 'transform', display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <h2>Proudly made in the USA!</h2>
          <div id='fd_made_in_usa_content' className='panelContent' style={{ boxSizing: 'border-box', paddingTop: '1em', display: 'flex', flexDirection: 'column', flex: 1 }}>
            We believe that the security and quality the United States provides is second to none!
            <br />
            <br />
            All of our staff, including support and developers, along with our data centers and offices are located in the US.
          </div>
        </div>
      </Dialog>
      <div id='fd_snackbar' className='mdc-snackbar' data-mdc-auto-init='MDCSnackbar'>
        <div className='mdc-snackbar__surface'>
          <div className='mdc-snackbar__label' role='status' aria-live='polite' />
          <div className='mdc-snackbar__actions'>
            <button type='button' className='mdc-button mdc-snackbar__action' />
            <Tooltip title='Dismiss'>
              <button className='mdc-icon-button mdc-snackbar__dismiss'><CloseIcon /></button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  )
}

App.propTypes = {
  content: PropTypes.string
}

export default App
