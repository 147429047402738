import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const MDCMenuItem = props => {
  const { className, text, icon: Icon } = props
  const rootEl = useRef(null)

  useEffect(() => {
    if (rootEl.current) {
      const el = rootEl.current
      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)

      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  }, [])

  return (
    <span {...props} ref={rootEl} className={`mdc-list-item${className ? ' ' + className : ''}`} role='menuitem'>
      {Icon && <Icon className='mdc-list-item__graphic' />}
      {text && <span className='mdc-list-item__text'>{text}</span>}
    </span>
  )
}

MDCMenuItem.propTypes = {
  icon: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string
}

export default MDCMenuItem
