import {
  FETCHING_VERIFIED_EMAIL_ADDRESSES,
  SET_VERIFIED_EMAIL_ADDRESSES,
  VERIFYING_EMAIL_ADDRESS
} from '../constants/verifiedEmailAddresses'

export const initialState = {
  data: null,
  fetching: false,
  verifying: false
}

export default function verifiedEmailAddressesReducer (state = initialState, action) {
  switch (action.type) {
    case FETCHING_VERIFIED_EMAIL_ADDRESSES:
      return {
        ...state,
        fetching: true
      }
    case VERIFYING_EMAIL_ADDRESS:
      return {
        ...state,
        verifying: action.verifying
      }
    case SET_VERIFIED_EMAIL_ADDRESSES:
      return {
        ...state,
        fetching: false,
        data: action.verifiedEmailAddresses
      }
    default:
      return state
  }
}
