import React from 'react'
import { useDragLayer } from 'react-dnd'
import { createPortal } from 'react-dom'
import { useSpring, animated } from 'react-spring'

import ItemTypes from './ItemTypes'
import PanelDragPreview from './PanelDragPreview'

const renderItem = (itemType, content) => {
  switch (itemType) {
    case `Contacts_${ItemTypes.PANEL}`:
    case `User_${ItemTypes.PANEL}`:
    case ItemTypes.PANEL:
      return (
        <PanelDragPreview content={content} />
      )
    default:
      return null
  }
}

const CustomDragLayer = props => {
  const [{ offset: transformXY }, setSpring] = useSpring(() => ({ offset: [null, null] }))

  const { item, itemType, isDragging } = useDragLayer(monitor => {
    const { x: prevX, y: prevY } = transformXY

    const clientOffset = monitor.getClientOffset()

    const offset = clientOffset !== null ? [clientOffset.x, clientOffset.y] : [null, null]
    if (prevX !== offset[0] || prevY !== offset[1]) {
      setSpring({
        offset,
        immediate: true
      })
    }

    return {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      isDragging: monitor.isDragging()
    }
  })

  if (!isDragging) {
    return null
  }

  return createPortal(
    <animated.div
      role='presentation'
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        top: 0,
        left: 0,
        transformOrigin: '50% 50% 0',
        willChange: 'transform',
        transform: transformXY.interpolate((x, y) =>
          x === null || y === null ? 'none' : `translate3d(${x}px, ${y}px, 0)`
        )
      }}
    >
      {renderItem(itemType, item ? item.content : null)}
    </animated.div>,
    document.body
  )
}

export default CustomDragLayer
