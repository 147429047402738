import r2 from 'r2'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { FETCH_APPLICATION } from '../constants/application'
import { fetchingApplication, setApplication } from '../actions/application'
import { selectFetchingApplication } from '../selectors/application'

function * fetchApplication () {
  const isFetchingApplication = yield select(selectFetchingApplication)

  if (!isFetchingApplication) {
    yield put(fetchingApplication())
    try {
      const payload = yield (yield call(r2, {
        url: '/api/v2.1.jsp',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: 'params=' + encodeURIComponent(JSON.stringify({
          action: 'Private_AppConfig_Retrieve'
        }))
      })).json

      if (payload) {
        if (payload.config) {
          yield put(setApplication(payload.config))
        } else {
          let message = payload.message || 'System error.'
          switch (payload.responseCode.id) {
            case 4:
              message = 'Authentication required.'
              break
            default:
              break
          }
          throw new Error(message)
        }
      }
    } catch (e) {
      yield put(fetchingApplication(false))
    }
  }
}

function * applicationSaga () {
  yield takeEvery(FETCH_APPLICATION, fetchApplication)
}

export default applicationSaga
