import {
  FETCH_APPLICATION,
  FETCHING_APPLICATION,
  SET_APPLICATION
} from '../constants/application'

export function fetchApplication () {
  return {
    type: FETCH_APPLICATION
  }
}

export function fetchingApplication (fetching = true) {
  return {
    type: FETCHING_APPLICATION,
    fetching
  }
}

export function setApplication (properties) {
  return {
    type: SET_APPLICATION,
    properties
  }
}
