import React, { forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const MDCTextField = forwardRef(({ children, label, onChange, required, rows, style, type, value }, ref) => {
  const rootEl = useRef(null)

  useEffect(() => {
    if (rootEl.current) {
      const el = rootEl.current
      el.MDCTextField = new global.mdc.textField.MDCTextField(el)

      return function cleanup () {
        el.MDCTextField.destroy()
      }
    }
  }, [])

  const refCallback = r => {
    if (ref) {
      ref.current = r
    }
    rootEl.current = r
  }

  const helperText = children ? (
    <div className='mdc-text-field-helper-text mdc-text-field-helper-text--persistent'>{children}</div>
  ) : null

  return (
    <>
      <div
        ref={refCallback}
        className={`mdc-text-field ${rows ? 'mdc-text-field--textarea' : 'mdc-text-field--outlined'}`}
        style={{ ...(style || {}), width: '100%' }}
      >
        {rows ? (
          <textarea
            className='mdc-text-field__input'
            rows={rows}
            value={value}
            onChange={onChange}
            required={!!required}
          />
        ) : (
          <input
            type={type || 'text'}
            className='mdc-text-field__input'
            value={value}
            onChange={onChange}
            required={!!required}
          />
        )}
        <div className='mdc-notched-outline'>
          <div className='mdc-notched-outline__leading' />
          <div className='mdc-notched-outline__notch'>
            <label className='mdc-floating-label'>{label}</label>
          </div>
          <div className='mdc-notched-outline__trailing' />
        </div>
      </div>
      {helperText
        ? rows
          ? <div className='mdc-text-field-helper-line'>{helperText}</div>
          : helperText
        : null}
    </>
  )
})

MDCTextField.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  rows: PropTypes.number,
  style: PropTypes.shape(),
  type: PropTypes.string,
  value: PropTypes.string
}

export default MDCTextField
