import {
  FETCHING_APPLICATION,
  SET_APPLICATION
} from '../constants/application'

import {
  SET_USER
} from '../constants/user'

export const initialState = {
  properties: null,
  systemNotifications: [],
  menuType: null,
  headerType: null,
  menuItem: null,
  subMenuItem: null,
  pageTitle: null,
  fetching: false
}

export default function userReducer (state = initialState, action) {
  switch (action.type) {
    case FETCHING_APPLICATION:
      return {
        ...state,
        properties: null,
        fetching: action.fetching
      }
    case SET_APPLICATION:
      return {
        ...state,
        properties: action.properties,
        fetching: false
      }
    case SET_USER:
      if (action.user && action.id === 'self') {
        return {
          ...state,
          properties: {
            ...state.properties,
            accessee: action.user
          }
        }
      }
      return state
    default:
      return state
  }
}
