const subscribeTypes = ['seamlessSignupForm', 'publicSignupForm', 'api', 'imported', 'userForm', 'subscribedSubGroup', 'addedSubGroup', 'activeGroup']
const unsubscribeTypes = ['bounced', 'unsubscribed', 'complained', 'deactivated', 'bouncedSubGroup', 'unsubscribedSubGroup', 'deactivatedSubGroup', 'notActiveGroup']

export const getContactSearchCriteriaBySubscriptionType = (key, intervalStartTimestampInclusive, intervalEndTimestampExclusive) => {
  const criteria = {}

  if (subscribeTypes.includes(key)) {
    criteria.subscribeMethodList = [key]
    criteria.statusList = ['Y']

    if (intervalStartTimestampInclusive !== undefined) {
      criteria.enrollTimestampStartInclusive = intervalStartTimestampInclusive
    }

    if (intervalEndTimestampExclusive !== undefined) {
      criteria.enrollTimestampEndExclusive = intervalEndTimestampExclusive
    }
  } else if (unsubscribeTypes.includes(key)) {
    criteria.unsubscribeMethodList = [key]
    criteria.statusList = ['N']

    if (intervalStartTimestampInclusive !== undefined) {
      criteria.optOutTimestampStartInclusive = intervalStartTimestampInclusive
    }

    if (intervalEndTimestampExclusive !== undefined) {
      criteria.optOutTimestampEndExclusive = intervalEndTimestampExclusive
    }
  }

  return criteria
}

export const addReturnToPreviousParameter = url => {
  if (!url) {
    return url
  }

  return `${url}${url.indexOf('?') > -1 ? '&' : '?'}returnToPrevious=${encodeURIComponent(global.location.href.substring(global.location.origin.length))}`
}

export const formatPercent = (percent, decimals) => {
  if (Number.isNaN(percent) || percent === Infinity) {
    percent = 0.0
  }

  // Turn 0.46% from 0% to 0.5%
  if (decimals === 0 && percent < 0.5 && percent > 0.0) {
    decimals = 1
  }
  var p = percent.toFixed(decimals) + '%'

  // Remove trailing decimal zeroes
  // 100.00% --> 100%, 10.70% -> 10.7%, 10.71% -> 10.71%
  if (decimals) {
    p = p.replace(/0*%/, '%').replace('.%', '%')
  }

  if (percent > 0.0 && p === '0%') {
    return '<0.1%'
  }

  return p
}
