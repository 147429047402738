import {
  FETCH_REPORT_DATA,
  FETCHING_REPORT_DATA,
  SET_REPORT_DATA
} from '../constants/reports'

export function fetchReportData (report, userId, parameters) {
  return {
    type: FETCH_REPORT_DATA,
    report,
    userId: userId || 'self',
    parameters: parameters || {}
  }
}

export function fetchingReportData (report, userId) {
  return {
    type: FETCHING_REPORT_DATA,
    report,
    userId: userId || 'self'
  }
}

export function setReportData (report, data, userId) {
  return {
    type: SET_REPORT_DATA,
    report,
    data,
    userId: userId || 'self'
  }
}
