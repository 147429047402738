import React from 'react'
import PropTypes from 'prop-types'

const PanelDragPreview = ({ width, height, content: Content }) => {
  return (
    <div className='panel' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: width || '64px', height: height || '64px', opacity: 0.8, transform: 'translate(-50%, -50%)' }}>
      <Content />
    </div>
  )
}

PanelDragPreview.propTypes = {
  content: PropTypes.shape(),
  height: PropTypes.string,
  width: PropTypes.string
}

export default PanelDragPreview
