import {
  FETCHING_CUSTOM_FIELDS,
  SET_CUSTOM_FIELDS
} from '../constants/customFields'

export const initialState = {
  data: null,
  fetching: false
}

export default function customFieldsReducer (state = initialState, action) {
  switch (action.type) {
    case FETCHING_CUSTOM_FIELDS:
      return {
        ...state,
        fetching: true
      }
    case SET_CUSTOM_FIELDS:
      return {
        ...state,
        fetching: false,
        data: action.customFields
      }
    default:
      return state
  }
}
