import merge from 'deepmerge'

import {
  FETCHING_USER,
  FETCHING_USER_DASHBOARD,
  SAVING_USER,
  SET_USER,
  SET_USER_DASHBOARD,
  SET_USER_DASHBOARD_ORDER
} from '../constants/user'

import widgetSchemas from '../utilities/widgetSchemas'

import diffpatcher from '../utilities/diffpatcher'

const jdp = require('jsondiffpatch')

const mergeDashboardSettings = dashboard => ({
  ...dashboard,
  settings: {
    ...widgetSchemas[dashboard.id].settings,
    ...dashboard.settings
  }
})

export const initialState = {
  profiles: {},
  dashboards: {},
  dashboardOrders: {},
  savingProfiles: {},
  fetchingProfiles: {},
  fetchingDashboards: {}
}

export default function userReducer (state = initialState, action) {
  switch (action.type) {
    case FETCHING_USER:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.id]: null
        },
        fetchingProfiles: {
          ...state.fetchingProfiles,
          [action.id]: true
        }
      }
    case FETCHING_USER_DASHBOARD:
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [`${action.userId}|${action.dashboard}`]: null
        },
        fetchingDashboards: {
          ...state.fetchingDashboards,
          [`${action.userId}|${action.dashboard}`]: true
        }
      }
    case SAVING_USER:
      return {
        ...state,
        savingProfiles: {
          ...state.savingProfiles,
          [action.id]: action.saving
        }
      }
    case SET_USER: {
      const currentUser = state.profiles[action.id]
      if (currentUser === action.user || (!currentUser && !action.user)) {
        return {
          ...state,
          fetchingProfiles: {
            ...state.fetchingProfiles,
            [action.id]: false
          }
        }
      }

      if ((!currentUser && action.user) || (currentUser && !action.user)) {
        return {
          ...state,
          fetchingProfiles: {
            ...state.fetchingProfiles,
            [action.id]: false
          },
          profiles: {
            ...state.profiles,
            [action.id]: action.user
          }
        }
      }

      const diff = jdp.diff(currentUser, action.user)
      if (diff === undefined) {
        return {
          ...state,
          fetchingProfiles: {
            ...state.fetchingProfiles,
            [action.id]: false
          }
        }
      }

      const original = {}
      let clonedProfile = merge(original, currentUser)
      try {
        clonedProfile = diffpatcher.patch(clonedProfile, diff)
        if (original !== clonedProfile) {
          // Apply to actual profile
          return {
            ...state,
            fetchingProfiles: {
              ...state.fetchingProfiles,
              [action.id]: false
            },
            profiles: {
              ...state.profiles,
              [action.id]: diffpatcher.patch(currentUser, diff)
            }
          }
        }
      } catch (ex) {
        console.error('ERROR IN PATCH', ex) // eslint-disable-line no-console
      }

      return {
        ...state,
        fetchingProfiles: {
          ...state.fetchingProfiles,
          [action.id]: false
        },
        profiles: {
          ...state.profiles,
          [action.id]: action.user
        }
      }
    }
    case SET_USER_DASHBOARD: {
      const currentDashboard = state.dashboards[`${action.userId}|${action.dashboard}`]
      const actionData = action.data ? action.data.map(mergeDashboardSettings) : null

      if (currentDashboard === actionData || (!currentDashboard && !actionData)) {
        return {
          ...state,
          fetchingDashboards: {
            ...state.fetchingDashboards,
            [`${action.userId}|${action.dashboard}`]: false
          }
        }
      }

      if ((!currentDashboard && actionData) || (currentDashboard && !actionData)) {
        return {
          ...state,
          fetchingDashboards: {
            ...state.fetchingDashboards,
            [`${action.userId}|${action.dashboard}`]: false
          },
          dashboards: {
            ...state.dashboards,
            [`${action.userId}|${action.dashboard}`]: actionData
          }
        }
      }

      const diff = jdp.diff(currentDashboard, actionData)
      if (diff === undefined) {
        return {
          ...state,
          fetchingDashboards: {
            ...state.fetchingDashboards,
            [`${action.userId}|${action.dashboard}`]: false
          }
        }
      }

      let changed = false
      const newDashboard = actionData.map((dashboard, index) => {
        const oldDashboard = currentDashboard.find(({ id }) => id === dashboard.id)
        if (!oldDashboard) {
          changed = true
          return dashboard
        }

        const oldDashboardIndex = currentDashboard.indexOf(oldDashboard)
        if (oldDashboardIndex !== index) {
          changed = true
        }

        const diff = jdp.diff(oldDashboard, dashboard)
        if (diff === undefined) {
          return oldDashboard
        }

        changed = true

        const original = {}
        let clonedDashboard = merge(original, oldDashboard)
        try {
          clonedDashboard = diffpatcher.patch(clonedDashboard, diff)
          if (original !== clonedDashboard) {
            // Apply to actual dashboard
            return diffpatcher.patch(oldDashboard, diff)
          }
        } catch (ex) {
          console.error('ERROR IN PATCH', ex) // eslint-disable-line no-console
        }

        return dashboard
      })

      if (changed) {
        return {
          ...state,
          fetchingDashboards: {
            ...state.fetchingDashboards,
            [`${action.userId}|${action.dashboard}`]: false
          },
          dashboards: {
            ...state.dashboards,
            [`${action.userId}|${action.dashboard}`]: newDashboard
          }
        }
      }

      return {
        ...state,
        fetchingDashboards: {
          ...state.fetchingDashboards,
          [`${action.userId}|${action.dashboard}`]: false
        }
      }
    }
    case SET_USER_DASHBOARD_ORDER: {
      const currentDashboardOrder = state.dashboardOrders[`${action.userId}|${action.dashboard}`]
      if (currentDashboardOrder === action.data || (!currentDashboardOrder && !action.data)) {
        return state
      }

      if ((!currentDashboardOrder && action.data) || (currentDashboardOrder && !action.data)) {
        return {
          ...state,
          dashboardOrders: {
            ...state.dashboardOrders,
            [`${action.userId}|${action.dashboard}`]: action.data
          }
        }
      }

      const diff = jdp.diff(currentDashboardOrder, action.data)
      if (diff === undefined) {
        return state
      }

      const original = {}
      let clonedDashboardOrder = merge(original, currentDashboardOrder)
      try {
        clonedDashboardOrder = diffpatcher.patch(clonedDashboardOrder, diff)
        if (original !== clonedDashboardOrder) {
          // Apply to actual dashboard order
          return {
            ...state,
            dashboardOrders: {
              ...state.dashboardOrders,
              [`${action.userId}|${action.dashboard}`]: diffpatcher.patch(currentDashboardOrder, diff)
            }
          }
        }
      } catch (ex) {
        console.error('ERROR IN PATCH', ex) // eslint-disable-line no-console
      }

      return {
        ...state,
        dashboardOrders: {
          ...state.dashboardOrders,
          [`${action.userId}|${action.dashboard}`]: action.data
        }
      }
    }
    default:
      return state
  }
}
