import application from './application'
import user from './user'
import reports from './reports'
import lists from './lists'
import customFields from './customFields'
import contacts from './contacts'
import websocket from './websocket'
import verifiedEmailAddresses from './verifiedEmailAddresses'

export default {
  application,
  user,
  reports,
  lists,
  customFields,
  contacts,
  websocket,
  verifiedEmailAddresses
}
