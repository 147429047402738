import r2 from 'r2'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { FETCH_REPORT_DATA } from '../constants/reports'
import { fetchingReportData, setReportData } from '../actions/reports'
import { makeFetchingReportSelector } from '../selectors/reports'

const selectFetchingReport = makeFetchingReportSelector()

function * fetchReportData (action) {
  const { report, userId } = action
  const isFetchingReportData = yield select(selectFetchingReport, {
    report,
    userId
  })
  if (!isFetchingReportData) {
    yield put(fetchingReportData(report, userId))
    switch (report) {
      case 'contactsByStatus':
        yield call(fetchContactsByStatusReportData, action)
        break
      case 'contactTrends':
        yield call(fetchContactTrendsReportData, action)
        break
      case 'mostRecentIssueStatistics':
        yield call(fetchMostRecentIssueStatisticsReportData, action)
        break
      case 'scheduledSendingEvents':
        yield call(fetchScheduledSendingEventsReportData, action)
        break
      default:
        break
    }
  }
}

function * fetchContactsByStatusReportData (action) {
  try {
    const payload = yield (yield call(r2, {
      url: '/api/v2.1.jsp',
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: 'params=' + encodeURIComponent(JSON.stringify(action.userId === 'self' ? {
        action: 'Private_Report_MembersByStatusAndMethod'
      } : {
        action: 'Private_Report_MembersByStatusAndMethod',
        userId: action.userId
      }))
    })).json

    if (payload) {
      if (payload.report) {
        yield put(setReportData(action.report, payload.report, action.userId))
      } else {
        let message = payload.message || 'System error.'
        switch (payload.responseCode.id) {
          case 4:
            message = 'Authentication required.'
            break
          default:
            break
        }
        throw new Error(message)
      }
    }
  } catch (e) {
    yield put(setReportData(action.report, null, action.userId))
  }
}

function * fetchContactTrendsReportData (action) {
  try {
    const parameters = {
      dateGrouping: 'day',
      ...(action.parameters ? action.parameters : {}),
      action: 'Private_Report_MemberTrends'
    }

    const payload = yield (yield call(r2, {
      url: '/api/v2.1.jsp',
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: 'params=' + encodeURIComponent(JSON.stringify(action.userId === 'self' ? parameters : {
        ...parameters,
        userId: action.userId
      }))
    })).json

    if (payload) {
      if (payload.report) {
        yield put(setReportData(action.report, payload.report, action.userId))
      } else {
        let message = payload.message || 'System error.'
        switch (payload.responseCode.id) {
          case 4:
            message = 'Authentication required.'
            break
          default:
            break
        }
        throw new Error(message)
      }
    }
  } catch (e) {
    yield put(setReportData(action.report, null, action.userId))
  }
}

function * fetchScheduledSendingEventsReportData (action) {
  try {
    const parameters = {
      ...(action.parameters ? action.parameters : {}),
      action: 'Private_Report_ScheduledSendingEvents'
    }

    const payload = yield (yield call(r2, {
      url: '/api/v2.1.jsp',
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: 'params=' + encodeURIComponent(JSON.stringify(action.userId === 'self' ? parameters : {
        ...parameters,
        userId: action.userId
      }))
    })).json

    if (payload) {
      if (payload.success) {
        const { scheduledSendingEvents } = payload
        yield put(setReportData(action.report, scheduledSendingEvents, action.userId))
      } else {
        let message = payload.message || 'System error.'
        switch (payload.responseCode.id) {
          case 4:
            message = 'Authentication required.'
            break
          default:
            break
        }
        throw new Error(message)
      }
    }
  } catch (e) {
    yield put(setReportData(action.report, null, action.userId))
  }
}

function * fetchMostRecentIssueStatisticsReportData (action) {
  try {
    const parameters = {
      includeHourlyStatistics: false,
      includeEngagementData: false,
      includeEmailClientData: false,
      includeGeolocationData: false,
      ...(action.parameters ? action.parameters : {}),
      action: 'Private_Report_MostRecentIssue'
    }

    const payload = yield (yield call(r2, {
      url: '/api/v2.1.jsp',
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: 'params=' + encodeURIComponent(JSON.stringify(action.userId === 'self' ? parameters : {
        ...parameters,
        userId: action.userId
      }))
    })).json

    if (payload) {
      if (payload.success) {
        const { campaign, message, issueNumber, issueTotals, campaignTotals } = payload
        yield put(setReportData(action.report, {
          campaign,
          message,
          issueNumber,
          issueTotals,
          campaignTotals
        }, action.userId))
      } else {
        let message = payload.message || 'System error.'
        switch (payload.responseCode.id) {
          case 4:
            message = 'Authentication required.'
            break
          default:
            break
        }
        throw new Error(message)
      }
    }
  } catch (e) {
    yield put(setReportData(action.report, null, action.userId))
  }
}

function * reportsSaga () {
  yield takeEvery(FETCH_REPORT_DATA, fetchReportData)
}

export default reportsSaga
