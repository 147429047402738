import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { TabBarProvider } from './utilities/TabBarContext'

const MDCTabBar = ({ children, tabs, style }) => {
  const tabBarEl = useRef(null)
  const [activeTab, setActiveTab] = useState([-1, 0])
  const effectivelyActiveTab = useMemo(() => !tabs ? -1 : Math.min(activeTab[1], tabs.length - 1), [activeTab, tabs])

  useEffect(() => {
    if (tabBarEl.current && tabBarEl.current.getAttribute('role')) {
      const el = tabBarEl.current
      el.MDCTabBar = new global.mdc.tabBar.MDCTabBar(el)

      return function cleanup () {
        el.MDCTabBar.destroy()
        delete el.MDCTabBar
      }
    }
  }, [tabs])

  useEffect(() => {
    if (tabBarEl.current && tabBarEl.current.getAttribute('role')) {
      tabBarEl.current.MDCTabBar.getDefaultFoundation().activateTab(effectivelyActiveTab)
    }
  }, [effectivelyActiveTab, tabs])

  const onClick = useCallback(({ currentTarget }) => {
    setActiveTab([effectivelyActiveTab, Number(currentTarget.getAttribute('data-index'))])
  }, [effectivelyActiveTab, setActiveTab])

  const renderTab = useCallback(({ icon: Icon, label, stacked }, index) => (
    <button key={label} type='button' className={`mdc-tab${stacked ? ' mdc-tab--stacked' : ''}`} role='tab' data-index={String(index)} onClick={onClick}>
      <span className='mdc-tab__content'>
        {Icon && <Icon className='mdc-tab__icon' />}
        <span className='mdc-tab__text-label'>{label}</span>
      </span>
      <span className='mdc-tab-indicator'>
        <span className='mdc-tab-indicator__content mdc-tab-indicator__content--underline' />
      </span>
      <span className='mdc-tab__ripple' />
    </button>
  ), [onClick])

  return (
    <TabBarProvider value={[activeTab[0], effectivelyActiveTab]}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {!tabs || !tabs.length
          ? null
          : tabs.length === 1
            ? (
              <h2 ref={tabBarEl} className='tabReplacement' style={{ flex: 0 }}>{tabs[0].label}</h2>
            )
            : (
              <div ref={tabBarEl} className='mdc-tab-bar' role='tablist' style={{ flex: 0 }}>
                <div className='mdc-tab-scroller'>
                  <div className='mdc-tab-scroller__scroll-area'>
                    <div className='mdc-tab-scroller__scroll-content'>
                      {tabs.map(renderTab)}
                    </div>
                  </div>
                </div>
              </div>
            )}
        <div style={{ flex: 1, position: 'relative', overflow: 'hidden' }}>
          {children}
        </div>
      </div>
    </TabBarProvider>
  )
}

MDCTabBar.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape(),
  tabs: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.func,
    label: PropTypes.string.isRequired,
    stacked: PropTypes.bool
  }))
}

export default MDCTabBar
