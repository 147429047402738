import { createSelector } from 'reselect'

export const selectUser = state => state.user

export const selectUserProfiles = state => state.user ? state.user.profiles : null

export const selectUserDashboards = state => state.user ? state.user.dashboards : null

export const selectUserDashboardOrders = state => state.user ? state.user.dashboardOrders : null

export const selectFetchingUserProfiles = state => state.user ? state.user.fetchingProfiles : null

export const selectSavingUserProfiles = state => state.user ? state.user.savingProfiles : null

export const selectFetchingUserDashboards = state => state.user ? state.user.fetchingDashboards : null

export const makeUserProfileSelector = () => createSelector(
  [selectUserProfiles, (_, userId) => userId],
  (profiles, userId) => profiles && profiles[userId] ? profiles[userId] : null
)

export const makeFetchingUserProfileSelector = () => createSelector(
  [selectFetchingUserProfiles, (_, userId) => userId],
  (fetchingProfiles, userId) => fetchingProfiles ? !!fetchingProfiles[userId] : false
)

export const makeSavingUserProfileSelector = () => createSelector(
  [selectSavingUserProfiles, (_, id) => id],
  (savingProfiles, id) => savingProfiles ? !!savingProfiles[id] : false
)

export const makeUserDashboardSelector = () => createSelector(
  [selectUserDashboards, (_, { dashboard }) => dashboard, (_, { userId }) => userId],
  (dashboards, dashboard, userId) => {
    if (dashboards) {
      const key = `${userId}|${dashboard}`
      if (dashboards[key]) {
        return dashboards[key]
      }
    }

    return null
  }
)

export const makeUserDashboardWidgetOrderSelector = () => createSelector(
  [selectUserDashboardOrders, (_, { dashboard }) => dashboard, (_, { userId }) => userId],
  (dashboardOrders, dashboard, userId) => {
    if (dashboardOrders) {
      const key = `${userId}|${dashboard}`
      if (dashboardOrders[key]) {
        return dashboardOrders[key]
      }
    }

    return null
  }
)

export const makeFetchingUserDashboardSelector = () => createSelector(
  [selectFetchingUserDashboards, (_, { dashboard }) => dashboard, (_, { userId }) => userId],
  (fetchingDashboards, dashboard, userId) => fetchingDashboards ? !!fetchingDashboards[`${userId}|${dashboard}`] : false
)
