import {
  START_WEBSOCKET,
  STOP_WEBSOCKET,
  WEBSOCKET_DATA_RECEIVED,
  SEND_TO_WEBSOCKET
} from '../constants/websocket'

export function startWebSocket () {
  return {
    type: START_WEBSOCKET
  }
}

export function stopWebSocket () {
  return {
    type: STOP_WEBSOCKET
  }
}

export function webSocketDataReceived (dataKey, payload) {
  return {
    type: WEBSOCKET_DATA_RECEIVED,
    dataKey,
    payload
  }
}

export function sendToWebSocket (dataKey, payload) {
  return {
    type: SEND_TO_WEBSOCKET,
    dataKey,
    payload
  }
}
