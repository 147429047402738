import PropTypes from 'prop-types'
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import ListAltIcon from '@material-ui/icons/ListAlt'
import SettingsIcon from '@material-ui/icons/Settings'

import ConfigureModal from './utilities/ConfigureModal'

import MDCCircularProgress from '../../MDCComponents/CircularProgress'

import Tooltip from '../../MUIComponents/Tooltip'

import DragHandleContext from '../../UtilityComponents/DragHandleContext'

import { fetchLists } from '../../../actions/lists'

import { selectLists } from '../../../selectors/lists'

import WidgetEventHandlerContext from './utilities/WidgetEventHandlerContext'

const navigateToListsPage = event => {
  if (event) {
    event.preventDefault()
  }

  global.location.href = '/categories.jsp'

  return false
}

const columns = [
  { id: 'name', label: 'List', minWidth: 196 },
  {
    id: 'activeMemberCount',
    label: 'Active Contacts',
    minWidth: 120,
    align: 'right',
    format: value => value.toLocaleString(),
    viewContacts: true
  },
  {
    id: 'deactivatedMemberCount',
    label: 'Deactivated Contacts',
    minWidth: 148,
    align: 'right',
    format: value => value.toLocaleString(),
    viewContacts: true
  },
  {
    id: 'sortSequence',
    label: 'Order',
    minWidth: 80,
    align: 'right',
    format: value => value.toLocaleString()
  }
]

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const ListsWidget = props => {
  const { children, id, settings, title } = props

  const dispatch = useDispatch()

  const lists = useSelector(selectLists)
  useEffect(() => {
    if (!lists) {
      dispatch(fetchLists())
    }
  }, [lists, dispatch])

  const { current: widgetEventHandler } = useContext(WidgetEventHandlerContext)

  const drag = useContext(DragHandleContext)

  const removeEl = useRef(null)
  useEffect(() => {
    if (removeEl.current) {
      const el = removeEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const configureEl = useRef(null)
  useEffect(() => {
    if (configureEl.current) {
      const el = configureEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const listAltEl = useRef(null)
  useEffect(() => {
    if (listAltEl.current) {
      const el = listAltEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('sortSequence')

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage)
  }, [setPage])

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }, [setRowsPerPage, setPage])

  const handleRequestSort = useCallback((event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }, [setOrder, setOrderBy, orderBy, order])

  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const createViewContactsHandler = (property, id) => event => {
    if (event) {
      event.preventDefault()
    }

    const status = property === 'activeMemberCount' ? 'Y' : 'N'
    const criteria = {
      categoryIdList: [id],
      statusList: [status]
    }

    const link = document.createElement('a')
    link.setAttribute('href', `/view_members.jsp?criteria=${encodeURIComponent(JSON.stringify(criteria))}`)
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const [isConfigureModalOpen, setConfigureModalOpen] = useState(false)
  const closeConfigureModal = useCallback(() => setConfigureModalOpen(false), [setConfigureModalOpen])
  const saveConfiguration = useCallback((newSettings, changed) => {
    if (changed) {
      widgetEventHandler({
        type: 'configure',
        id,
        settings: newSettings
      })
    }

    setConfigureModalOpen(false)
  }, [id, setConfigureModalOpen, widgetEventHandler])

  const onConfigure = useCallback(() => {
    setConfigureModalOpen(true)
  }, [setConfigureModalOpen])

  const onRemove = useCallback(() => {
    widgetEventHandler({
      type: 'remove',
      id
    })
  }, [id, widgetEventHandler])

  const emptyRows = lists && rowsPerPage < lists.length ? rowsPerPage - Math.min(rowsPerPage, lists.length - page * rowsPerPage) : 0

  return (
    <div className='panel' style={{ willChange: 'transform' }}>
      <h2>{title}</h2>
      <div className='panelContent' style={{ height: '548px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div className='panelActions'>
          <Tooltip title='Move Widget'>
            <span ref={drag} style={{ cursor: 'move' }}><DragIndicatorIcon /></span>
          </Tooltip>
        </div>
        {children}
        <div style={{ flex: 1, margin: '1em 4px', overflowY: 'auto', willChange: 'transform' }}>
          {lists
            ? (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sortDirection={orderBy === column.id ? order : false}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={order}
                          onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(lists, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                    <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                      {columns.map(column => {
                        const value = row[column.id]
                        const columnValue = column.format && typeof value === 'number' ? column.format(value) : value
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.viewContacts
                              ? (
                                <button className='fd-link-button' onClick={createViewContactsHandler(column.id, row.id)}>
                                  {columnValue}
                                </button>
                              )
                              : columnValue}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={columns.length} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )
            : <MDCCircularProgress>Please wait...</MDCCircularProgress>}
        </div>
        {lists &&
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={lists.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page'
            }}
            nextIconButtonProps={{
              'aria-label': 'next page'
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />}
        <div className='panelBottomActions' style={{ flex: 0 }}>
          <div className='panelBottomIcons'>
            <Tooltip title='Manage Lists'>
              <button ref={listAltEl} className='mdc-icon-button' onClick={navigateToListsPage}><ListAltIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
            <Tooltip title='Configure Widget'>
              <button ref={configureEl} className='mdc-icon-button' onClick={onConfigure}><SettingsIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
            <Tooltip title='Remove Widget'>
              <button ref={removeEl} className='mdc-icon-button' onClick={onRemove}><DeleteIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
          </div>
        </div>
      </div>
      <ConfigureModal
        close={closeConfigureModal}
        isOpen={isConfigureModalOpen}
        saveConfiguration={saveConfiguration}
        settings={settings}
        title={title}
      />
    </div>
  )
}

ListsWidget.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  settings: PropTypes.shape(),
  title: PropTypes.string
}

const MemoizedListsWidget = memo(ListsWidget)

export default MemoizedListsWidget
