import {
  FETCH_USER,
  FETCHING_USER,
  SAVE_USER,
  SAVING_USER,
  SET_USER,
  FETCH_USER_DASHBOARD,
  FETCHING_USER_DASHBOARD,
  SAVE_USER_DASHBOARD,
  SET_USER_DASHBOARD,
  SET_USER_DASHBOARD_ORDER
} from '../constants/user'

export function fetchUser (id) {
  return {
    type: FETCH_USER,
    id: id || 'self'
  }
}

export function fetchingUser (id) {
  return {
    type: FETCHING_USER,
    id: id || 'self'
  }
}

export function saveUser (parameters, id) {
  return {
    type: SAVE_USER,
    parameters,
    id: id || 'self'
  }
}

export function savingUser (id, saving) {
  return {
    type: SAVING_USER,
    id,
    saving
  }
}

export function setUser (user, id) {
  return {
    type: SET_USER,
    user,
    id: id || 'self'
  }
}

export function fetchUserDashboard (dashboard, userId) {
  return {
    type: FETCH_USER_DASHBOARD,
    dashboard,
    userId: userId || 'self'
  }
}

export function fetchingUserDashboard (dashboard, userId) {
  return {
    type: FETCHING_USER_DASHBOARD,
    dashboard,
    userId: userId || 'self'
  }
}

export function saveUserDashboard (dashboard, data, userId) {
  return {
    type: SAVE_USER_DASHBOARD,
    dashboard,
    data,
    userId: userId || 'self'
  }
}

export function setUserDashboard (dashboard, data, userId) {
  return {
    type: SET_USER_DASHBOARD,
    dashboard,
    data,
    userId: userId || 'self'
  }
}

export function setUserDashboardOrder (dashboard, data, userId) {
  return {
    type: SET_USER_DASHBOARD_ORDER,
    dashboard,
    data,
    userId: userId || 'self'
  }
}
