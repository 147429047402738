import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const MDCSelect = ({ value, label, onChange, options, select2, style }) => {
  const rootEl = useRef(null)

  useEffect(() => {
    if (rootEl.current) {
      const el = rootEl.current
      el.MDCSelect = new global.mdc.select.MDCSelect(el)

      if (select2) {
        const $select = global.$('select', el)
        $select.select2({
          width: $select.outerWidth(),
          theme: 'material mdc-select__native-control'
        })
      }
      return function cleanup () {
        el.MDCSelect.destroy()
      }
    }
  }, [select2])

  const renderOption = ([value, label]) => {
    return (
      <option key={value} value={value}>{label || value}</option>
    )
  }

  return (
    <div
      ref={rootEl}
      className='mdc-select mdc-select--outlined'
      style={style || null}
    >
      <i className='mdc-select__dropdown-icon' />
      <select className='mdc-select__native-control' defaultValue={value} onChange={onChange}>
        {options.map(renderOption)}
      </select>
      <div className='mdc-notched-outline'>
        <div className='mdc-notched-outline__leading' />
        <div className='mdc-notched-outline__notch'>
          <label className='mdc-floating-label'>{label}</label>
        </div>
        <div className='mdc-notched-outline__trailing' />
      </div>
    </div>
  )
}

MDCSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  select2: PropTypes.bool,
  style: PropTypes.shape(),
  value: PropTypes.string
}

export default MDCSelect
