import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import Draggable from './Draggable'
import { SortableDropProvider } from './SortableDropContext'
import SortableEventHandlerContext from './SortableEventHandlerContext'

const canDrop = () => false

const Sortable = ({ children, className, dragHandle, hasHandle, id, style, type }) => {
  const { current: sortableEventHandler } = useContext(SortableEventHandlerContext)
  const hover = useCallback(item => {
    sortableEventHandler({
      type: 'move',
      id: item.id,
      targetId: id
    })
  }, [id, sortableEventHandler])

  const [, drop] = useDrop({
    accept: type,
    canDrop,
    hover
  })

  const end = useCallback((item, monitor) => {
    sortableEventHandler({
      type: !monitor.didDrop() ? 'reset' : 'commit'
    })
  }, [sortableEventHandler])

  return (
    <SortableDropProvider value={drop}>
      <Draggable className={className} style={style} type={type} end={end} itemType={type} itemId={id} itemContent={dragHandle} hasHandle={hasHandle}>
        {children}
      </Draggable>
    </SortableDropProvider>
  )
}

Sortable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dragHandle: PropTypes.shape(),
  hasHandle: PropTypes.bool,
  id: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  type: PropTypes.string.isRequired
}

export default Sortable
