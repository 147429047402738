import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchApplication } from '../actions/application'

import {
  selectAccessee,
  selectAccesseeActiveContacts,
  selectAccesseeEmailSendingLimit,
  selectAccesseeEmailsSentThisMonth,
  selectAccesseeMaxActiveContacts
} from '../selectors/application'

import { appFontStyle } from '../utilities/constants'

const baseOptions = {
  chart: {
    backgroundColor: 'rgba(0,0,0,0)',
    type: 'solidgauge'
  },
  pane: {
    startAngle: -120,
    endAngle: 120,
    size: '100%',
    background: {
      innerRadius: '60%',
      outerRadius: '100%',
      borderColor: '#d4d4d4',
      backgroundColor: 'rgba(0,0,0,0)',
      shape: 'arc'
    }
  },
  credits: {
    enabled: false
  },
  tooltip: {
    enabled: false
  },
  title: {
    style: {
      ...appFontStyle,
      fontSize: '1.2rem',
      fontWeight: 500
    }
  },
  exporting: {
    enabled: false
  },
  yAxis: {
    labels: {
      enabled: false
    },
    stops: [
      [0.5, '#5EC442'],
      [0.7, '#FFE100'],
      [0.8, '#F58220'],
      [0.9, '#DA2128']
    ],
    lineWidth: 0,
    minorTickInterval: null,
    tickAmount: 0,
    tickColor: 'rgba(0,0,0,0)'
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        y: -26,
        borderWidth: 0,
        useHTML: true,
        style: {
          ...appFontStyle,
          fontWeight: 500
        }
      }
    }
  }
}

const usePricingPlanLimitsGuage = ({ settings, userId }) => {
  const { desktopSize, tabletSize, phoneSize } = settings

  const dispatch = useDispatch()

  const accessee = useSelector(selectAccessee)
  const accesseeActiveContacts = useSelector(selectAccesseeActiveContacts)
  const accesseeEmailSendingLimit = useSelector(selectAccesseeEmailSendingLimit)
  const accesseeEmailsSentThisMonth = useSelector(selectAccesseeEmailsSentThisMonth)
  const accesseeMaxActiveContacts = useSelector(selectAccesseeMaxActiveContacts)

  useEffect(() => {
    if (!accessee) {
      dispatch(fetchApplication())
    }
  }, [accessee, dispatch])

  // MDC Init
  const expandEl = useRef(null)
  useEffect(() => {
    if (expandEl.current) {
      const el = expandEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const contactLimitContainerEl = useRef(null)
  const contactLimitChartInstance = useRef(null)
  const emailLimitContainerEl = useRef(null)
  const emailLimitChartInstance = useRef(null)
  const [isExpandedChartModalOpen, setExpandedChartModalOpen] = useState(false)

  const contactLimitChartOptions = useMemo(() => {
    if (accesseeMaxActiveContacts >= 0) {
      return global.Highcharts.merge(baseOptions, {
        title: {
          text: `Up To ${accesseeMaxActiveContacts.toLocaleString()} Contacts`
        },
        yAxis: {
          min: 0,
          max: accesseeMaxActiveContacts
        },
        series: [{
          data: [accesseeActiveContacts],
          dataLabels: {
            formatter: function () {
              return `<div style="text-align:center"><span style="font-size:1rem">${this.y.toLocaleString()}</span><br/><span style="font-size:0.8rem;opacity:0.4">Contacts</span></div>`
            }
          }
        }]
      })
    }
  }, [accesseeActiveContacts, accesseeMaxActiveContacts])

  const emailLimitChartOptions = useMemo(() => {
    if (accesseeEmailSendingLimit >= 0) {
      return global.Highcharts.merge(baseOptions, {
        title: {
          text: `Up To ${accesseeEmailSendingLimit} Emails Sent Per Month`
        },
        yAxis: {
          min: 0,
          max: accesseeEmailSendingLimit
        },
        series: [{
          data: [accesseeEmailsSentThisMonth],
          dataLabels: {
            format: '<div style="text-align:center"><span style="font-size:">{y}</span><br/><span style="font-size:12px;opacity:0.4">Emails Sent</span></div>'
          }
        }]
      })
    }
  }, [accesseeEmailSendingLimit, accesseeEmailsSentThisMonth])

  useEffect(() => {
    if (contactLimitChartOptions) {
      const contactLimitChart = contactLimitChartInstance.current = global.Highcharts.chart(contactLimitContainerEl.current, contactLimitChartOptions)

      return () => {
        contactLimitChart.destroy()
      }
    } else {
      contactLimitChartInstance.current = null
    }
  }, [contactLimitChartOptions, contactLimitChartInstance, contactLimitContainerEl])

  useEffect(() => {
    if (emailLimitChartOptions) {
      const emailLimitChart = emailLimitChartInstance.current = global.Highcharts.chart(emailLimitContainerEl.current, emailLimitChartOptions)

      return () => {
        emailLimitChart.destroy()
      }
    } else {
      emailLimitChartInstance.current = null
    }
  }, [emailLimitChartOptions, emailLimitChartInstance, emailLimitContainerEl])

  useEffect(() => {
    const contactLimitChart = contactLimitChartInstance.current
    if (contactLimitChart) {
      contactLimitChart.reflow()
    }

    const emailLimitChart = emailLimitChartInstance.current
    if (emailLimitChart) {
      emailLimitChart.reflow()
    }
  }, [desktopSize, tabletSize, phoneSize])

  const expandChart = useCallback(event => {
    if (event) {
      event.preventDefault()
    }

    setExpandedChartModalOpen(true)
  }, [setExpandedChartModalOpen])

  const closeExpandedChartModal = useCallback(() => {
    setExpandedChartModalOpen(false)
  }, [setExpandedChartModalOpen])

  return [contactLimitContainerEl, emailLimitContainerEl, {
    accessee,
    accesseeActiveContacts,
    accesseeEmailSendingLimit,
    accesseeEmailsSentThisMonth,
    accesseeMaxActiveContacts,
    contactLimitChartInstance,
    contactLimitChartOptions,
    closeExpandedChartModal,
    emailLimitChartInstance,
    emailLimitChartOptions,
    expandChart,
    expandEl,
    isExpandedChartModalOpen
  }]
}

export default usePricingPlanLimitsGuage
