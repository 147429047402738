import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Slide from '@material-ui/core/Slide'

import TabBarContext from '../../MDCComponents/utilities/TabBarContext'

const MDCTab = ({ children, index }) => {
  const [previouslyActiveTab, activeTab] = useContext(TabBarContext)

  return activeTab === -1 || (previouslyActiveTab === -1 && activeTab === index) ? children : previouslyActiveTab === -1 ? null : (
    <Slide direction={activeTab === index ? (previouslyActiveTab < index ? 'left' : 'right') : activeTab < index ? 'left' : 'right'} in={activeTab === index}>
      <div style={{ display: 'inline-block', width: '100%', whiteSpace: 'normal', position: 'absolute' }}>
        {children}
      </div>
    </Slide>
  )
}

MDCTab.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired
}

export default MDCTab
