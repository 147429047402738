import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchApplication } from '../actions/application'
import { fetchReportData } from '../actions/reports'

import { selectAccessee, selectTimeZones } from '../selectors/application'
import { makeReportDataSelector } from '../selectors/reports'

import { appFontStyle } from '../utilities/constants'
import { addReturnToPreviousParameter, formatPercent } from '../utilities/methods'

export const REPORT_ID = 'mostRecentIssueStatistics'

const mostRecentIssueStatisticsColors = {
  percentOpened: '#92A8CD',
  percentClicked: '#80699B',
  percentBounced: '#3D96AE',
  percentUnsubscribed: '#DB843D',
  percentSubscribed: '#57B63D',
  percentForwarded: '#B2416F',
  percentPrinted: '#B5CA92',
  percentRead: '#3DA223',
  percentSkimmed: '#75D45B',
  percentGlanced: '#9BDB8B',
  percentComplained: '#AA4643'
}

const tooltipFormatters = {
  percentOpened: ({ uniqueOpenCount, receivedCount }, { uniqueOpenMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${uniqueOpenCount} opened<br>out of<br>${receivedCount} received${uniqueOpenMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`,
  percentClicked: ({ uniqueClickCount, receivedCount }, { clickMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${uniqueClickCount} clicked<br>out of<br>${receivedCount} received${clickMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`,
  percentForwarded: ({ uniqueForwardCount, receivedCount }, { uniqueForwardMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${uniqueForwardCount} forwarded<br>out of<br>${receivedCount} received${uniqueForwardMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`,
  percentPrinted: ({ uniquePrintCount, receivedCount }, { uniquePrintMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${uniquePrintCount} printed<br>out of<br>${receivedCount} received${uniquePrintMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`,
  percentRead: ({ uniqueReadCount, uniqueOpenCount }, { uniqueReadMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${uniqueReadCount} read<br>out of<br>${uniqueOpenCount} opened${uniqueReadMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`,
  percentSkimmed: ({ uniqueSkimCount, uniqueOpenCount }, { uniqueSkimmedMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${uniqueSkimCount} skimmed<br>out of<br>${uniqueOpenCount} opened${uniqueSkimmedMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`,
  percentGlanced: ({ uniqueGlanceCount, uniqueOpenCount }, { uniqueGlancedMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${uniqueGlanceCount} glanced<br>out of<br>${uniqueOpenCount} opened${uniqueGlancedMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`,
  percentSubscribed: ({ subscribeCount, receivedCount }, { subscribeMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${subscribeCount} subscribed<br>out of<br>${receivedCount} received${subscribeMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`,
  percentBounced: ({ uniqueBounceCount, sentCount }, { uniqueBounceMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${uniqueBounceCount} bounced<br>out of<br>${sentCount} sent${uniqueBounceMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`,
  percentUnsubscribed: ({ unsubscribeCount, receivedCount }, { unsubscribeMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${unsubscribeCount} unsubscribed<br>out of<br>${receivedCount} received${unsubscribeMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`,
  percentComplained: ({ complaintCount, receivedCount }, { complaintMemberURL }, { color, name, y }) => `<span style="color:${color}"><strong>${name}</strong></span><br>${formatPercent(y, 2)}<br>${complaintCount} complained<br>out of<br>${receivedCount} received${complaintMemberURL ? '<br><br>Click chart to<br>view contacts' : ''}`
}

const filtersByType = {
  normalScheduled: campaign => campaign.type === 'normal' && (!!campaign.mostRecentScheduledSending || !!campaign.draftVariationMostRecentSending),
  normalAuto: campaign => campaign.type === 'normal' && (!!campaign.mostRecentAutoSending || (!campaign.mostRecentScheduledSending && !campaign.draftVariationMostRecentSending)),
  ecard: campaign => {
    switch (campaign.type) {
      case 'birthday':
      case 'anniversary':
      case 'welcome':
      case 'date':
        return true
      default:
        return false
    }
  },
  coupon: campaign => campaign.type === 'coupon',
  referral: campaign => campaign.type === 'referral',
  triggered: campaign => campaign.type === 'triggered'
}

const campaignTypePreferredOrder = ['normalScheduled', 'normalAuto', 'ecard', 'coupon', 'referral', 'triggered']
const getCampaignType = campaign => campaignTypePreferredOrder.find(type => filtersByType[type](campaign))

const getSendingIssuePathList = (campaign, message, variationIndex) => {
  const campaignId = String(campaign.id).padStart(8, '0')
  const issueNumber = message.issueNumber

  if (message.issueType === 'ab') {
    if (variationIndex !== undefined && variationIndex !== null) {
      if (variationIndex >= 0) {
        return [campaignId + '/' + issueNumber + '/variations/' + variationIndex + '/content/newsletter.html']
      }

      return [campaignId + '/' + issueNumber + '/newsletter.html']
    }

    // Combine issue paths for all variations and winner
    const sendingIssuePathList = message.variations.map((variation, variationIndex) => campaignId + '/' + issueNumber + '/variations/' + variationIndex + '/content/newsletter.html')
    sendingIssuePathList.push(campaignId + '/' + issueNumber + '/newsletter.html')

    return sendingIssuePathList
  }

  return [campaignId + '/' + issueNumber + '/newsletter.html']
}

const useMostRecentIssueStatisticsChart = ({ dialog, settings: { desktopSize, tabletSize, phoneSize }, userId }) => {
  const dispatch = useDispatch()

  const accessee = useSelector(selectAccessee)
  const timeZones = useSelector(selectTimeZones)
  useEffect(() => {
    if (!accessee) {
      dispatch(fetchApplication())
    }
  }, [accessee, dispatch])

  const selectMostRecentIssueStatisticsReportData = useMemo(makeReportDataSelector)
  const mostRecentIssueStatisticsReportData = useSelector(state => selectMostRecentIssueStatisticsReportData(state, {
    report: REPORT_ID,
    userId
  }))

  useEffect(() => {
    if (!mostRecentIssueStatisticsReportData) {
      dispatch(fetchReportData(REPORT_ID, userId, {
        includeEngagementData: true,
        includeEmailClientData: true,
        includeGeolocationData: true
      }))
    }
  }, [mostRecentIssueStatisticsReportData, dispatch, userId])

  // MDC Init
  const refreshEl = useRef(null)
  const expandEl = useRef(null)
  const printEl = useRef(null)
  const downloadEl = useRef(null)
  useEffect(() => {
    if (refreshEl.current) {
      const el = refreshEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })
  useEffect(() => {
    if (expandEl.current) {
      const el = expandEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })
  useEffect(() => {
    if (printEl.current) {
      const el = printEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })
  useEffect(() => {
    if (downloadEl.current) {
      const el = downloadEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const containerEl = useRef(null)
  const chartInstance = useRef(null)
  const [isExpandedChartModalOpen, setExpandedChartModalOpen] = useState(false)

  const mostRecentIssueStatisticsChartOptions = useMemo(() => {
    if (accessee && mostRecentIssueStatisticsReportData) {
      const { data: { campaign, issueTotals, message } } = mostRecentIssueStatisticsReportData
      if (!campaign || !message || !issueTotals) {
        // TODO: Handle the case where the user has no sendings!
        chartInstance.current = null
        return
      }
      const variationIndex = null // TODO: How do we get this variable here!?

      let totals = null

      const campaignType = getCampaignType(campaign)
      if (issueTotals) {
        const isAB = message.issueType === 'ab' && campaignType !== 'normalAuto'
        const isABVariationOrWinner = isAB && variationIndex != null
        // const isABVariation = isABVariationOrWinner && variationIndex >= 0
        // const isABWinner = isABVariationOrWinner && variationIndex === -1
        // const isABWaitingForWinner = isAB && !message.publishedDate

        switch (campaignType) {
          case 'normalAuto':
            totals = issueTotals.autoSendingTotals || {}
            // totalsByHour = issueTotals.autoSendingTotalsByHour
            break
          case 'normalScheduled':
            if (isABVariationOrWinner) {
              if (issueTotals.abTotals) {
                totals = issueTotals.abTotals[variationIndex] || {}
              }

              if (issueTotals.abTotalsByHour) {
                // totalsByHour = issueTotals.abTotalsByHour[variationIndex]
              }
            } else {
              totals = issueTotals.scheduledSendingTotals || {}
              // totalsByHour = issueTotals.scheduledSendingTotalsByHour
            }
            break

          default:
            if (isABVariationOrWinner) {
              if (issueTotals.abTotals) {
                totals = issueTotals.abTotals[variationIndex] || {}
              }
              if (issueTotals.abTotalsByHour) {
                // totalsByHour = issueTotals.abTotalsByHour[variationIndex]
              }
            } else {
              totals = issueTotals.allSendingTotals || {}
              // totalsByHour = issueTotals.allSendingTotalsByHour
            }
            break
        }
      }

      const urls = {}
      const criteria = {}
      if (totals) {
        totals = {
          sentCount: 0,
          receivedCount: 0,
          uniqueBounceCount: 0,
          uniqueClickCount: 0,
          softBounceCount: 0,
          hardBounceCount: 0,
          uniqueOpenCount: 0,
          openCount: 0,
          nonOpenCount: 0,
          clickCount: 0,
          complaintCount: 0,
          forwardCount: 0,
          printCount: 0,
          readCount: 0,
          skimCount: 0,
          glanceCount: 0,
          ...totals
        }
        // Build member and event URLs
        const baseCriteria = {}

        baseCriteria.sendingIssuePathList = getSendingIssuePathList(campaign, message, variationIndex)

        switch (campaignType) {
          case 'normalScheduled':
            baseCriteria.sendingAutoSendingList = ['N']
            break
          case 'normalAuto':
            baseCriteria.sendingAutoSendingList = ['Y']
            break
          default:
            break
        }

        if (campaignType !== 'referral') {
          if (totals.hasMemberSendingData) {
            criteria.sentMemberCriteria = {
              ...baseCriteria,
              sendingActivity: 'sentTo'
            }
            urls.sentMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.sentMemberCriteria))

            criteria.receivedMemberCriteria = {
              ...baseCriteria,
              sendingActivity: 'received'
            }
            urls.receivedMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.receivedMemberCriteria))

            criteria.forwardMemberCriteria = {
              ...baseCriteria,
              sendingActivity: 'forwarded'
            }
            urls.forwardMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.forwardMemberCriteria))

            criteria.printMemberCriteria = {
              ...baseCriteria,
              sendingActivity: 'printed'
            }
            urls.printMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.printMemberCriteria))

            criteria.nonOpenMemberCriteria = {
              ...baseCriteria,
              sendingActivity: 'notOpened'
            }
            urls.nonOpenMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.nonOpenMemberCriteria))
          }

          criteria.uniqueBounceMemberCriteria = {
            ...baseCriteria,
            sendingActivity: ['bounced', 'notOpened'],
            bounceTypeList: ['SOFT', 'HARD']
          }
          urls.uniqueBounceMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.uniqueBounceMemberCriteria))

          criteria.softBounceMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'bounced',
            bounceTypeList: ['SOFT']
          }
          urls.softBounceMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.softBounceMemberCriteria))

          criteria.hardBounceMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'bounced',
            bounceTypeList: ['HARD']
          }
          urls.hardBounceMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.hardBounceMemberCriteria))

          criteria.openMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'opened'
          }
          urls.uniqueOpenMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.openMemberCriteria))

          criteria.readMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'read'
          }
          urls.uniqueReadMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.readMemberCriteria))

          criteria.skimmedMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'skimmed'
          }
          urls.uniqueSkimmedMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.skimmedMemberCriteria))

          criteria.glancedMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'glanced'
          }
          urls.uniqueGlancedMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.glancedMemberCriteria))

          criteria.unknownEngagementMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'opened',
            noOpenDuration: true
          }
          urls.uniqueUnknownEngagementMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.unknownEngagementMemberCriteria))

          criteria.desktopMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'opened',
            openDeviceType: 0
          }
          urls.uniqueDesktopMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.desktopMemberCriteria))

          criteria.tabletMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'opened',
            openDeviceType: 1
          }
          urls.uniqueTabletMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.tabletMemberCriteria))

          criteria.mobileMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'opened',
            openDeviceType: 2
          }
          urls.uniqueMobileMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.mobileMemberCriteria))

          criteria.otherMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'opened',
            openDeviceType: null
          }
          urls.uniqueOtherMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.otherMemberCriteria))

          criteria.clickMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'clicked'
          }
          urls.clickMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.clickMemberCriteria))

          criteria.clickToOpenMemberCriteria = {
            ...baseCriteria,
            sendingActivity: ['clicked', 'opened']
          }
          urls.clickToOpenMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.clickToOpenMemberCriteria))

          criteria.unsubscribeMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'unsubscribed',
            unsubscribeMethodList: ['unsubscribed']
          }
          urls.unsubscribeMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.unsubscribeMemberCriteria))

          criteria.complaintMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'complained'
          }
          urls.complaintMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.complaintMemberCriteria))
        } else {
          criteria.subscribeMemberCriteria = {
            ...baseCriteria,
            sendingActivity: 'referred'
          }
          urls.subscribeMemberURL = '/view_members.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.subscribeMemberCriteria))
        }

        criteria.openEventCriteria = {
          ...baseCriteria
        }
        urls.openEventURL = '/opens.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.openEventCriteria))

        criteria.clickEventCriteria = {
          ...baseCriteria
        }
        urls.clickEventURL = '/clicks_link_summary.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.clickEventCriteria))

        criteria.bounceEventCriteria = {
          ...baseCriteria,
          bounceTypeList: ['SOFT', 'HARD']
        }
        urls.bounceEventURL = '/bounces.jsp?criteria=' + encodeURIComponent(JSON.stringify(criteria.bounceEventCriteria))

        const {
          receivedCount,
          sentCount,
          uniqueOpenCount,
          uniqueClickCount,
          uniqueForwardCount,
          uniquePrintCount,
          // uniqueReadCount,
          // uniqueSkimCount,
          // uniqueGlanceCount,
          subscribeCount,
          uniqueBounceCount,
          unsubscribeCount,
          complaintCount
        } = totals

        const data = {
          percentOpened: receivedCount ? uniqueOpenCount * 100 / receivedCount : 0,
          percentClicked: receivedCount ? uniqueClickCount * 100 / receivedCount : 0,
          percentForwarded: receivedCount ? uniqueForwardCount * 100 / receivedCount : 0,
          percentPrinted: receivedCount ? uniquePrintCount * 100 / receivedCount : 0,
          // percentRead: uniqueOpenCount ? uniqueReadCount * 100 / uniqueOpenCount : 0,
          // percentSkimmed: uniqueOpenCount ? uniqueSkimCount * 100 / uniqueOpenCount : 0,
          // percentGlanced: uniqueOpenCount ? uniqueGlanceCount * 100 / uniqueOpenCount : 0,
          percentSubscribed: receivedCount ? subscribeCount * 100 / receivedCount : 0,
          percentBounced: sentCount ? uniqueBounceCount * 100 / sentCount : 0,
          percentUnsubscribed: receivedCount ? unsubscribeCount * 100 / receivedCount : 0,
          percentComplained: receivedCount ? complaintCount * 100 / receivedCount : 0
        }
        const {
          percentOpened,
          percentClicked,
          percentForwarded,
          percentPrinted,
          // percentRead,
          // percentGlanced,
          // percentSkimmed,
          percentSubscribed,
          percentBounced,
          percentUnsubscribed,
          percentComplained
        } = data

        const dataMax = Object.values(data).reduce((accumulator, percent) => Math.max(accumulator, percent), 0)

        const chart = {
          backgroundColor: 'rgba(0,0,0,0)',
          style: {
            ...appFontStyle
          },
          type: 'column',
          events: {
            exportData: event => {
            }
          }
        }

        if (!dialog) {
          chart.height = 481
        }

        return {
          chart,
          colors: [
            mostRecentIssueStatisticsColors.percentOpened,
            mostRecentIssueStatisticsColors.percentClicked,
            mostRecentIssueStatisticsColors.percentBounced,
            mostRecentIssueStatisticsColors[campaignType !== 'referral' ? 'percentUnsubscribed' : 'percentSubscribed']
          ],
          credits: {
            enabled: false
          },
          plotOptions: {
            column: {
              animation: true,
              borderWidth: 0,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return formatPercent(this.y, 0)
                },
                style: {
                  ...appFontStyle,
                  fontWeight: 500
                },
                y: -4
              },
              minPointLength: 1
            },
            series: {
              events: {
                legendItemClick: function (e) {
                  var series = this
                  var index = this.options.legendIndex
                  series.select()
                  const checkboxEl = chartInstance.current.container.querySelectorAll('.mdc-checkbox')[index]
                  if (checkboxEl) {
                    setTimeout(() => {
                      checkboxEl.MDCCheckbox.checked = series.visible
                    }, 1)
                  }
                },
                checkboxClick: function (event) {
                  var series = this
                  var index = this.options.legendIndex
                  if (series.visible) {
                    series.hide()
                  } else {
                    series.show()
                  }
                  const checkboxEl = chartInstance.current.container.querySelectorAll('.mdc-checkbox')[index]
                  if (checkboxEl) {
                    setTimeout(() => {
                      checkboxEl.MDCCheckbox.checked = series.visible
                    }, 1)
                  }
                }
              },
              showCheckbox: true
            }
          },
          rangeSelector: {
            enabled: false
          },
          scrollbar: {
            enabled: false
          },
          exporting: {
            enabled: false,
            filename: 'ContactsTrends_' + moment().tz(accessee.timeZone).format('YYYY-MM-DD_HHmmss'),
            url: '/highcharts-export.jsp'
          },
          labels: {
            style: {
              ...appFontStyle
            }
          },
          legend: {
            borderWidth: 1,
            borderRadius: 2,
            borderColor: '#d4d4d4',
            enabled: true,
            itemDistance: 40,
            itemCheckboxStyle: {
              width: 60,
              height: 60
            },
            itemStyle: {
              ...appFontStyle,
              fontWeight: 500
            },
            itemMarginBottom: 11,
            itemMarginTop: 11
          },
          series: [{
            name: 'Opened',
            legendIndex: 0,
            selected: true,
            visible: true,
            color: mostRecentIssueStatisticsColors.percentOpened,
            data: [{
              dataKey: 'percentOpened',
              y: percentOpened,
              name: 'Opened',
              color: mostRecentIssueStatisticsColors.percentOpened,
              events: {
                click: event => {
                  if (urls.uniqueOpenMemberURL) {
                    const link = document.createElement('a')
                    link.setAttribute('href', addReturnToPreviousParameter(urls.uniqueOpenMemberURL))
                    link.style.display = 'none'
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                  }
                }
              }
            }]
          }, {
            name: 'Clicked',
            legendIndex: 1,
            selected: true,
            visible: true,
            color: mostRecentIssueStatisticsColors.percentClicked,
            data: [{
              dataKey: 'percentClicked',
              y: percentClicked,
              name: 'Clicked',
              color: mostRecentIssueStatisticsColors.percentClicked,
              events: {
                click: event => {
                  if (urls.clickMemberURL) {
                    const link = document.createElement('a')
                    link.setAttribute('href', addReturnToPreviousParameter(urls.clickMemberURL))
                    link.style.display = 'none'
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                  }
                }
              }
            }]
          }, {
            name: 'Forwarded',
            legendIndex: 2,
            selected: true,
            visible: true,
            color: mostRecentIssueStatisticsColors.percentForwarded,
            data: [{
              dataKey: 'percentForwarded',
              y: percentForwarded,
              name: 'Forwarded',
              color: mostRecentIssueStatisticsColors.percentForwarded,
              events: {
                click: event => {
                  if (urls.uniqueForwardMemberURL) {
                    const link = document.createElement('a')
                    link.setAttribute('href', addReturnToPreviousParameter(urls.uniqueForwardMemberURL))
                    link.style.display = 'none'
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                  }
                }
              }
            }]
          }, {
            name: 'Printed',
            legendIndex: 3,
            selected: true,
            visible: true,
            color: mostRecentIssueStatisticsColors.percentPrinted,
            data: [{
              dataKey: 'percentPrinted',
              y: percentPrinted,
              name: 'Printed',
              color: mostRecentIssueStatisticsColors.percentPrinted,
              events: {
                click: event => {
                  if (urls.uniquePrintMemberURL) {
                    const link = document.createElement('a')
                    link.setAttribute('href', addReturnToPreviousParameter(urls.uniquePrintMemberURL))
                    link.style.display = 'none'
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                  }
                }
              }
            }]
          }, {
            name: 'Bounced',
            legendIndex: 4,
            selected: true,
            visible: true,
            color: mostRecentIssueStatisticsColors.percentBounced,
            data: [{
              dataKey: 'percentBounced',
              y: percentBounced,
              name: 'Bounced',
              color: mostRecentIssueStatisticsColors.percentBounced,
              events: {
                click: event => {
                  if (urls.uniqueBounceMemberURL) {
                    const link = document.createElement('a')
                    link.setAttribute('href', addReturnToPreviousParameter(urls.uniqueBounceMemberURL))
                    link.style.display = 'none'
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                  }
                }
              }
            }]
          }, campaignType !== 'referral' ? {
            name: 'Unsubscribed',
            legendIndex: 4,
            selected: true,
            visible: true,
            color: mostRecentIssueStatisticsColors.percentUnsubscribed,
            data: [{
              dataKey: 'percentUnsubscribed',
              y: percentUnsubscribed,
              name: 'Unsubscribed',
              color: mostRecentIssueStatisticsColors.percentUnsubscribed,
              events: {
                click: event => {
                  if (urls.unsubscribeMemberURL) {
                    const link = document.createElement('a')
                    link.setAttribute('href', addReturnToPreviousParameter(urls.unsubscribeMemberURL))
                    link.style.display = 'none'
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                  }
                }
              }
            }]
          } : {
            name: 'Subscribed',
            legendIndex: 5,
            selected: true,
            visible: true,
            color: mostRecentIssueStatisticsColors.percentSubscribed,
            data: [{
              dataKey: 'percentSubscribed',
              y: percentSubscribed,
              name: 'Subscribed',
              color: mostRecentIssueStatisticsColors.percentSubscribed,
              events: {
                click: event => {
                  if (urls.subscribeMemberURL) {
                    const link = document.createElement('a')
                    link.setAttribute('href', addReturnToPreviousParameter(urls.subscribeMemberURL))
                    link.style.display = 'none'
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                  }
                }
              }
            }]
          }, {
            name: 'Complained',
            legendIndex: 6,
            selected: true,
            visible: true,
            color: mostRecentIssueStatisticsColors.percentComplained,
            data: [{
              dataKey: 'percentComplained',
              y: percentComplained,
              name: 'Complained',
              color: mostRecentIssueStatisticsColors.percentComplained,
              events: {
                click: event => {
                  if (urls.complainMemberURL) {
                    const link = document.createElement('a')
                    link.setAttribute('href', addReturnToPreviousParameter(urls.complainMemberURL))
                    link.style.display = 'none'
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                  }
                }
              }
            }]
          }],
          title: {
            text: `${campaign.name} - Sent to ${sentCount} Contact${sentCount !== 1 ? 's' : ''} on ${moment(message.publishedDate).format('MM/DD/YYYY h:mm A')} ${timeZones[accessee.timeZone].replace(/ /g, '\xA0')}`,
            style: {
              ...appFontStyle,
              fontSize: '1.2rem',
              fontWeight: 500
            }
          },
          tooltip: {
            followPointer: true,
            formatter: function () {
              return tooltipFormatters[this.point.dataKey](totals, urls, this.point)
            },
            style: {
              ...appFontStyle
            },
            useHTML: true
          },
          xAxis: {
            labels: {
              formatter: () => ''
            },
            tickWidth: 0
          },
          yAxis: {
            labels: {
              formatter: function () {
                return !this.isLast || dataMax < 97 ? this.value + '%' : ''
              },
              style: {
                ...appFontStyle
              }
            },
            max: dataMax < 97 ? 20 - (dataMax % 20) + dataMax : 120,
            title: {
              text: null
            },
            tickAmount: dataMax < 97 ? 6 : 7
          }
        }
      }
    }
  }, [dialog, accessee, mostRecentIssueStatisticsReportData, timeZones])

  useEffect(() => {
    if (mostRecentIssueStatisticsChartOptions) {
      const mostRecentIssueStatisticsChart = chartInstance.current = global.Highcharts.chart(containerEl.current, mostRecentIssueStatisticsChartOptions)

      return () => {
        mostRecentIssueStatisticsChart.destroy()
      }
    } else {
      chartInstance.current = null
    }
  }, [mostRecentIssueStatisticsChartOptions, chartInstance, containerEl])

  useEffect(() => {
    const mostRecentIssueStatisticsChart = chartInstance.current
    if (mostRecentIssueStatisticsChart) {
      mostRecentIssueStatisticsChart.reflow()
    }
  }, [desktopSize, tabletSize, phoneSize])

  const expandChart = useCallback(event => {
    if (event) {
      event.preventDefault()
    }

    setExpandedChartModalOpen(true)
  }, [setExpandedChartModalOpen])

  const printChart = event => {
    if (event) {
      event.preventDefault()
    }

    if (!chartInstance.current) {
      return false
    }

    if (dialog) {
      chartInstance.current.setSize(780, null, false)
      chartInstance.current.print()
      chartInstance.current.setSize(chartInstance.current.chartWidth, chartInstance.current.chartHeight, false)
    } else {
      chartInstance.current.print()
    }
  }

  const downloadChart = event => {
    if (event) {
      event.preventDefault()
    }

    if (!chartInstance.current) {
      return false
    }

    const exportOptions = {
      url: '/highcharts-export.jsp'
    }

    const exportChartOptions = {
      chart: {
        backgroundColor: 'white'
      }
    }

    if (dialog) {
      exportChartOptions.chart.width = 650
    }

    switch (event.currentTarget.dataset.format) {
      case 'csv':
        chartInstance.current.downloadCSV()
        break
      case 'pdf':
        exportOptions.type = 'application/pdf'
        chartInstance.current.exportChart(exportOptions, exportChartOptions)
        break
      case 'png':
      default:
        exportOptions.type = 'image/png'
        chartInstance.current.exportChart(exportOptions, exportChartOptions)
        break
    }
  }

  const refreshData = useCallback(event => {
    if (event) {
      event.preventDefault()
    }

    dispatch(fetchReportData(REPORT_ID))
  }, [dispatch])

  const closeExpandedChartModal = useCallback(() => {
    setExpandedChartModalOpen(false)
  }, [setExpandedChartModalOpen])

  return [containerEl, {
    accessee,
    chartInstance,
    closeExpandedChartModal,
    downloadChart,
    downloadEl,
    expandChart,
    expandEl,
    isExpandedChartModalOpen,
    mostRecentIssueStatisticsChartOptions,
    mostRecentIssueStatisticsReportData,
    printChart,
    printEl,
    refreshData,
    refreshEl
  }]
}

export default useMostRecentIssueStatisticsChart
