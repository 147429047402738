import r2 from 'r2'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { FETCH_USER, SAVE_USER, FETCH_USER_DASHBOARD, SAVE_USER_DASHBOARD } from '../constants/user'
import { fetchingUser, fetchingUserDashboard, savingUser, setUser, setUserDashboard, setUserDashboardOrder } from '../actions/user'
import { selectAccesseeId } from '../selectors/application'
import { makeFetchingUserProfileSelector, makeFetchingUserDashboardSelector, makeSavingUserProfileSelector } from '../selectors/user'

const selectFetchingUser = makeFetchingUserProfileSelector()
const selectFetchingUserDashboard = makeFetchingUserDashboardSelector()
const selectSavingUserProfile = makeSavingUserProfileSelector()

function * fetchUser (action) {
  const isFetchingUser = yield select(selectFetchingUser, action.id)

  if (!isFetchingUser) {
    yield put(fetchingUser(action.id))
    try {
      const payload = yield (yield call(r2, {
        url: '/api/v2.jsp',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: 'params=' + encodeURIComponent(JSON.stringify(action.id === 'self' ? {
          action: 'User_List'
        } : {
          action: 'User_List',
          id: action.id
        }))
      })).json

      if (payload) {
        if (payload.users && payload.users.length) {
          yield put(setUser(payload.users[0], action.id))
        } else {
          let message = payload.message || 'System error.'
          switch (payload.responseCode.id) {
            case 4:
              message = 'Authentication required.'
              break
            default:
              break
          }
          throw new Error(message)
        }
      }
    } catch (e) {
      yield put(setUser(null, action.id))
    }
  }
}

function * fetchUserDashboard (action) {
  const isFetchingUserDashboard = yield select(selectFetchingUserDashboard, {
    dashboard: action.dashboard,
    userId: action.userId
  })

  if (!isFetchingUserDashboard) {
    yield put(fetchingUserDashboard(action.dashboard, action.userId))
    try {
      const payload = yield (yield call(r2, {
        url: '/api/v2.jsp',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: 'params=' + encodeURIComponent(JSON.stringify(action.userId === 'self' ? {
          action: 'User_Dashboard_List',
          type: action.dashboard
        } : {
          action: 'User_Dashboard_List',
          type: action.dashboard,
          userId: action.userId
        }))
      })).json

      if (payload) {
        if (payload.userDashboards && payload.userDashboards.length) {
          yield put(setUserDashboard(action.dashboard, payload.userDashboards[0].data, action.userId))
          yield put(setUserDashboardOrder(action.dashboard, payload.userDashboards[0].data ? payload.userDashboards[0].data.map(widget => widget.id) : null, action.userId))
        } else {
          let message = payload.message || 'System error.'
          switch (payload.responseCode.id) {
            case 4:
              message = 'Authentication required.'
              break
            default:
              break
          }
          throw new Error(message)
        }
      }
    } catch (e) {
      yield put(setUserDashboard(action.dashboard, null, action.userId))
    }
  }
}

function * saveUser (action) {
  const isSavingUserProfile = yield select(selectSavingUserProfile, {
    id: action.id
  })

  if (!isSavingUserProfile) {
    yield put(savingUser(action.id, true))
    try {
      const id = yield select(selectAccesseeId)

      const parameters = {
        ...(action.parameters ? action.parameters : {}),
        id,
        action: 'User_Update'
      }

      const payload = yield (yield call(r2, {
        url: '/api/v2.jsp',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: 'params=' + encodeURIComponent(JSON.stringify(action.id === 'self' ? parameters : {
          ...parameters,
          id: action.id
        }))
      })).json

      if (payload) {
        if (payload.user) {
          yield put(setUser(payload.user, action.id))
        } else {
          let message = payload.message || 'System error.'
          switch (payload.responseCode.id) {
            case 4:
              message = 'Authentication required.'
              break
            default:
              break
          }
          throw new Error(message)
        }
      }
    } catch (e) {
      yield put(setUser(null, action.id))
    }
    yield put(savingUser(action.id, false))
  }
}

function * saveUserDashboard (action) {
  try {
    const payload = yield (yield call(r2, {
      url: '/api/v2.jsp',
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      body: 'params=' + encodeURIComponent(JSON.stringify(action.userId === 'self' ? {
        action: 'User_Dashboard_Update',
        type: action.dashboard,
        data: action.data
      } : {
        action: 'User_Dashboard_Update',
        type: action.dashboard,
        data: action.data,
        userId: action.userId
      }))
    })).json

    if (payload) {
      if (payload.userDashboard) {
        yield put(setUserDashboard(action.dashboard, payload.userDashboard.data, action.userId))
      } else {
        let message = payload.message || 'System error.'
        switch (payload.responseCode.id) {
          case 4:
            message = 'Authentication required.'
            break
          default:
            break
        }
        throw new Error(message)
      }
    }
  } catch (e) {
    yield put(setUserDashboard(action.dashboard, null, action.userId))
  }
}

function * userSaga () {
  yield takeEvery(FETCH_USER, fetchUser)
  yield takeEvery(SAVE_USER, saveUser)
  yield takeEvery(FETCH_USER_DASHBOARD, fetchUserDashboard)
  yield takeEvery(SAVE_USER_DASHBOARD, saveUserDashboard)
}

export default userSaga
