import r2 from 'r2'
import { call, takeEvery } from 'redux-saga/effects'

import { ADD_CONTACT } from '../constants/contacts'

function * addContact (action) {
  try {
    const payload = yield (yield call(r2, {
      url: '/api/v2.jsp',
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      body: 'params=' + encodeURIComponent(JSON.stringify({
        ...action.contact,
        action: 'Member_Add',
        skipCaptcha: true
      }))
    })).json

    if (payload) {
      if (payload.member) {
        if (action.callback) {
          action.callback(null, payload.member)
        }
      } else {
        let message = payload.message || 'System error.'
        switch (payload.responseCode.id) {
          case 4:
            message = 'Authentication required.'
            break
          default:
            break
        }
        throw new Error(message)
      }
    }
  } catch (e) {
    if (action.callback) {
      action.callback(e)
    }
  }
}

function * contactsSaga () {
  yield takeEvery(ADD_CONTACT, addContact)
}

export default contactsSaga
