import PropTypes from 'prop-types'
import React, { memo, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'

import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import SettingsIcon from '@material-ui/icons/Settings'

import ConfigureModal from './utilities/ConfigureModal'

import CustomFieldDisplay from '../../CustomFieldDisplay'

import MDCButton from '../../MDCComponents/Button'
import MDCCheckbox from '../../MDCComponents/Checkbox'
import MDCCircularProgress from '../../MDCComponents/CircularProgress'
import MDCSelect from '../../MDCComponents/Select'
import MDCTextField from '../../MDCComponents/TextField'

import Tooltip from '../../MUIComponents/Tooltip'

import DragHandleContext from '../../UtilityComponents/DragHandleContext'

import { addContact } from '../../../actions/contacts'
import { fetchCustomFields } from '../../../actions/customFields'
import { fetchLists } from '../../../actions/lists'

import { selectCustomFields } from '../../../selectors/customFields'
import { selectLists } from '../../../selectors/lists'

import WidgetEventHandlerContext from './utilities/WidgetEventHandlerContext'

const navigateToMultipleContactAddPage = event => {
  if (event) {
    event.preventDefault()
  }

  global.location.href = '/member_add_multiple.jsp'

  return false
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '66.67%',
    flexShrink: 0
  },
  expansionPanelDetails: {
    flexWrap: 'wrap',
    paddingTop: 0
  },
  listsExpansionPanelDetails: {
    paddingTop: 0,
    flexDirection: 'column'
  }
}))

const EMPTY_OBJECT = {}

const initialState = {
  expanded: 'basicFieldsPanel',
  firstName: '',
  lastName: '',
  emailAddress: '',
  birthdayMonth: '',
  birthdayDayOfMonth: '',
  birthdayYear: '',
  anniversaryMonth: '',
  anniversaryDayOfMonth: '',
  anniversaryYear: '',
  company: '',
  address: '',
  address2: '',
  city: '',
  stateProvince: '',
  zip: '',
  phone: '',
  mobilePhone: '',
  fax: '',
  gender: '',
  listIds: EMPTY_OBJECT,
  customFieldValues: EMPTY_OBJECT
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setField': {
      const { field, value } = action
      let changed = false
      switch (field) {
        case 'listIds': {
          const oldListIds = Object.keys(state.listIds)
          const newListIds = Object.keys(value)
          changed = oldListIds.length !== newListIds.length || oldListIds.some(id => !newListIds.includes(id))
          break
        }
        case 'customFieldValues': {
          const oldCustomFieldIds = Object.keys(state.customFieldValues)
          const newCustomFieldIds = Object.keys(value)
          changed = oldCustomFieldIds.length !== newCustomFieldIds.length || oldCustomFieldIds.some(id => !newCustomFieldIds.includes(id) || value[id] !== state.customFieldValues[id])
          break
        }
        default: {
          changed = state[field] !== value
          break
        }
      }

      if (changed) {
        return {
          ...state,
          [field]: value
        }
      }
      return state
    }
    case 'resetForm':
      return initialState
    default:
      return state
  }
}

const AddContactWidget = props => {
  const { children, id, settings, title } = props

  const dispatch = useDispatch()

  const lists = useSelector(selectLists)
  useEffect(() => {
    if (!lists) {
      dispatch(fetchLists())
    }
  }, [lists, dispatch])

  const customFields = useSelector(selectCustomFields)
  useEffect(() => {
    if (!customFields) {
      dispatch(fetchCustomFields())
    }
  }, [customFields, dispatch])

  const { current: widgetEventHandler } = useContext(WidgetEventHandlerContext)

  const drag = useContext(DragHandleContext)

  const removeEl = useRef(null)
  useEffect(() => {
    if (removeEl.current) {
      const el = removeEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const configureEl = useRef(null)
  useEffect(() => {
    if (configureEl.current) {
      const el = configureEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const groupAddEl = useRef(null)
  useEffect(() => {
    if (groupAddEl.current) {
      const el = groupAddEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const emailAddressEl = useRef(null)

  const handleExpandedChange = panel => (event, isExpanded) => {
    reactDispatch({
      type: 'setField',
      field: 'expanded',
      value: isExpanded ? panel : null
    })
  }

  const [{
    expanded,
    firstName,
    lastName,
    emailAddress,
    birthdayMonth,
    birthdayDayOfMonth,
    birthdayYear,
    anniversaryMonth,
    anniversaryDayOfMonth,
    anniversaryYear,
    company,
    address,
    address2,
    city,
    stateProvince,
    zip,
    phone,
    mobilePhone,
    fax,
    gender,
    listIds,
    customFieldValues
  }, reactDispatch] = useReducer(reducer, initialState)

  const onFirstNameChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'firstName',
      value
    })
  }, [reactDispatch])

  const onLastNameChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'lastName',
      value
    })
  }, [reactDispatch])

  const onEmailAddressChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'emailAddress',
      value
    })
  }, [reactDispatch])

  const onBirthdayMonthChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'birthdayMonth',
      value
    })
  }, [reactDispatch])

  const onBirthdayDayOfMonthChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'birthdayDayOfMonth',
      value
    })
  }, [reactDispatch])

  const onBirthdayYearChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'birthdayYear',
      value
    })
  }, [reactDispatch])

  const onAnniversaryMonthChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'anniversaryMonth',
      value
    })
  }, [reactDispatch])

  const onAnniversaryDayOfMonthChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'anniversaryDayOfMonth',
      value
    })
  }, [reactDispatch])

  const onAnniversaryYearChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'anniversaryYear',
      value
    })
  }, [reactDispatch])

  const onCompanyChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'company',
      value
    })
  }, [reactDispatch])

  const onAddressChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'address',
      value
    })
  }, [reactDispatch])

  const onAddress2Change = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'address2',
      value
    })
  }, [reactDispatch])

  const onCityChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'city',
      value
    })
  }, [reactDispatch])

  const onStateProvinceChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'stateProvince',
      value
    })
  }, [reactDispatch])

  const onZipChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'zip',
      value
    })
  }, [reactDispatch])

  const onPhoneChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'phone',
      value
    })
  }, [reactDispatch])

  const onMobilePhoneChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'mobilePhone',
      value
    })
  }, [reactDispatch])

  const onFaxChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'fax',
      value
    })
  }, [reactDispatch])

  const onGenderChange = useCallback(({ target: { value } }) => {
    reactDispatch({
      type: 'setField',
      field: 'gender',
      value
    })
  }, [reactDispatch])

  const onCustomFieldChange = useCallback((id, value) => {
    reactDispatch({
      type: 'setField',
      field: 'customFieldValues',
      value: {
        ...customFieldValues,
        [id]: value
      }
    })
  }, [customFieldValues, reactDispatch])

  const onListCheckboxChange = useCallback(({ currentTarget: { value, checked } }) => {
    if (checked) {
      if (listIds[value] === undefined) {
        reactDispatch({
          type: 'setField',
          field: 'listIds',
          value: {
            ...listIds,
            [value]: true
          }
        })
      }
    } else if (listIds[value] !== undefined) {
      const newListIds = {
        ...listIds
      }
      delete newListIds[value]
      reactDispatch({
        type: 'setField',
        field: 'listIds',
        value: newListIds
      })
    }
  }, [listIds, reactDispatch])

  const onToggleAllLists = useCallback(({ currentTarget: { checked } }) => {
    if (checked) {
      reactDispatch({
        type: 'setField',
        field: 'listIds',
        value: lists.reduce((accumulator, { id }) => {
          const value = String(id)
          accumulator[value] = true
          return accumulator
        }, {})
      })
    } else {
      reactDispatch({
        type: 'setField',
        field: 'listIds',
        value: EMPTY_OBJECT
      })
    }
  }, [lists, reactDispatch])

  const renderList = useCallback(({ id, name }) => {
    const value = String(id)
    const effectiveId = `listIds-${value}`
    return (
      <div key={effectiveId}>
        <MDCCheckbox
          name='listIds'
          value={value}
          checked={listIds[value] !== undefined}
          onChange={onListCheckboxChange}
          label={name}
        />
      </div>
    )
  }, [listIds, onListCheckboxChange])

  const renderCustomField = useCallback(({ dataType, name, options, id }, index) => {
    const effectiveId = String(id)
    return (
      <div key={`customFields-${id}`}>
        <CustomFieldDisplay
          type={dataType}
          name={name}
          options={options}
          id={effectiveId}
          value={customFieldValues[effectiveId] || ''}
          onChange={onCustomFieldChange}
          style={index === 0 ? null : { marginTop: '1em' }}
        />
      </div>
    )
  }, [customFieldValues, onCustomFieldChange])

  const handleAddContact = useCallback(event => {
    if (event) {
      event.preventDefault()
    }

    if (!emailAddress || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
      global.appShowSnackbarMessage(!emailAddress ? 'Email Address is required.' : 'Invalid Email Address.')
      if (expanded !== 'basicFieldsPanel') {
        reactDispatch({
          type: 'setField',
          field: 'expanded',
          value: 'basicFieldsPanel'
        })
      }

      if (emailAddressEl.current) {
        emailAddressEl.current.MDCTextField.focus()
      }
      return
    }

    const selectedLists = Object.keys(listIds).map(id => Number(id))
    if (!selectedLists.length) {
      global.appShowSnackbarMessage('Please select at least one list.')
      if (expanded !== 'listsPanel') {
        reactDispatch({
          type: 'setField',
          field: 'expanded',
          value: 'listsPanel'
        })
      }
      return
    }

    const newContact = {
      email: emailAddress,
      'categoryId[]': selectedLists
    }

    if (firstName) {
      newContact.firstName = firstName
    }

    if (lastName) {
      newContact.lastName = lastName
    }

    if (birthdayYear || birthdayMonth || birthdayDayOfMonth) {
      newContact.birthDate = `${birthdayYear || '0000'}-${birthdayMonth || '00'}-${birthdayDayOfMonth || '00'}`
    }

    if (anniversaryYear || anniversaryMonth || anniversaryDayOfMonth) {
      newContact.anniversaryDate = `${anniversaryYear || '0000'}-${anniversaryMonth || '00'}-${anniversaryDayOfMonth || '00'}`
    }

    if (company) {
      newContact.company = company
    }

    if (address) {
      newContact.address = address
    }

    if (address2) {
      newContact.address2 = address2
    }

    if (city) {
      newContact.city = city
    }

    if (stateProvince) {
      newContact.state = stateProvince
    }

    if (zip) {
      newContact.zip = zip
    }

    if (phone) {
      newContact.phone = phone
    }

    if (mobilePhone) {
      newContact.mobilePhone = mobilePhone
    }

    if (fax) {
      newContact.fax = fax
    }

    if (gender) {
      newContact.gender = gender
    }

    const customFieldIds = Object.keys(customFieldValues)
    if (customFieldIds.length) {
      newContact['customField[]'] = customFieldIds.map(id => ({
        id: Number(id),
        value: customFieldValues[id]
      }))
    }

    dispatch(addContact(newContact, (err, contact) => {
      if (err) {
        global.appShowSnackbarMessage(err.message)
        return
      }

      global.appShowSnackbarMessage('Contact Added', {
        stacked: true,
        action: {
          text: 'Edit the new contact',
          func: () => {
            global.location.href = `/member.jsp?id=${contact.id}`
          }
        }
      })

      reactDispatch({
        type: 'resetForm'
      })
    }))
  }, [
    expanded,
    dispatch,
    firstName,
    lastName,
    emailAddress,
    birthdayMonth,
    birthdayDayOfMonth,
    birthdayYear,
    anniversaryMonth,
    anniversaryDayOfMonth,
    anniversaryYear,
    company,
    address,
    address2,
    city,
    stateProvince,
    zip,
    phone,
    mobilePhone,
    fax,
    gender,
    customFieldValues,
    listIds,
    reactDispatch
  ])

  const [isConfigureModalOpen, setConfigureModalOpen] = useState(false)
  const closeConfigureModal = useCallback(() => setConfigureModalOpen(false), [setConfigureModalOpen])
  const saveConfiguration = useCallback((newSettings, changed) => {
    if (changed) {
      widgetEventHandler({
        type: 'configure',
        id,
        settings: newSettings
      })
    }

    setConfigureModalOpen(false)
  }, [id, setConfigureModalOpen, widgetEventHandler])

  const classes = useStyles()

  const onConfigure = useCallback(() => {
    setConfigureModalOpen(true)
  }, [setConfigureModalOpen])

  const onRemove = useCallback(() => {
    widgetEventHandler({
      type: 'remove',
      id
    })
  }, [id, widgetEventHandler])

  const numListIds = Object.keys(listIds).length
  return (
    <div className='panel' style={{ willChange: 'transform' }}>
      <h2>{title}</h2>
      <div className='panelContent' style={{ height: '548px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div className='panelActions'>
          <Tooltip title='Move Widget'>
            <span ref={drag} style={{ cursor: 'move' }}><DragIndicatorIcon /></span>
          </Tooltip>
        </div>
        {children}
        <div style={{ flex: 1, padding: '1em 4px', overflowY: 'auto', willChange: 'transform' }}>
          <ExpansionPanel
            expanded={expanded === 'basicFieldsPanel'}
            onChange={handleExpandedChange('basicFieldsPanel')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='basicFieldsPanelbh-content'
              id='basicFieldsPanelbh-header'
            >
              <Typography className={classes.heading}>
                Basic Fields
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <MDCTextField value={firstName} label='First Name' onChange={onFirstNameChange} />
              <MDCTextField value={lastName} label='Last Name' onChange={onLastNameChange} style={{ margin: '1em 0 0' }} />
              <MDCTextField ref={emailAddressEl} type='email' value={emailAddress} label='Email Address' onChange={onEmailAddressChange} style={{ margin: '1em 0 0' }} required />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'additionalFieldsPanel'}
            onChange={handleExpandedChange('additionalFieldsPanel')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='additionalContactFieldsPanelbh-content'
              id='additionalContactFieldsPanelbh-header'
            >
              <Typography className={classes.heading}>
                Additional Fields
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <div>
                <h6 className='mdc-typography--subtitle2'>Birthday</h6>
                <div className='fd-date'>
                  <MDCSelect
                    label='Month'
                    value={birthdayMonth}
                    options={[
                      ['', ''],
                      ['01', 'January'],
                      ['02', 'February'],
                      ['03', 'March'],
                      ['04', 'April'],
                      ['05', 'May'],
                      ['06', 'June'],
                      ['07', 'July'],
                      ['08', 'August'],
                      ['09', 'September'],
                      ['10', 'October'],
                      ['11', 'November'],
                      ['12', 'December']
                    ]}
                    onChange={onBirthdayMonthChange}
                  />
                  <MDCSelect
                    label='Day'
                    value={birthdayDayOfMonth}
                    options={[
                      ['', ''],
                      ['01', '01'],
                      ['02', '02'],
                      ['03', '03'],
                      ['04', '04'],
                      ['05', '05'],
                      ['06', '06'],
                      ['07', '07'],
                      ['08', '08'],
                      ['09', '09'],
                      ['10', '10'],
                      ['11', '11'],
                      ['12', '12'],
                      ['13', '13'],
                      ['14', '14'],
                      ['15', '15'],
                      ['16', '16'],
                      ['17', '17'],
                      ['18', '18'],
                      ['19', '19'],
                      ['20', '20'],
                      ['21', '21'],
                      ['22', '22'],
                      ['23', '23'],
                      ['24', '24'],
                      ['25', '25'],
                      ['26', '26'],
                      ['27', '27'],
                      ['28', '28'],
                      ['29', '29'],
                      ['30', '30'],
                      ['31', '31']
                    ]}
                    onChange={onBirthdayDayOfMonthChange}
                  />
                  <MDCSelect
                    label='Year'
                    value={birthdayYear}
                    options={[
                      ['', ''],
                      ['2022', '2022'],
                      ['2021', '2021'],
                      ['2020', '2020'],
                      ['2019', '2019'],
                      ['2018', '2018'],
                      ['2017', '2017'],
                      ['2016', '2016'],
                      ['2015', '2015'],
                      ['2014', '2014'],
                      ['2013', '2013'],
                      ['2012', '2012'],
                      ['2011', '2011'],
                      ['2010', '2010'],
                      ['2009', '2009'],
                      ['2008', '2008'],
                      ['2007', '2007'],
                      ['2006', '2006'],
                      ['2005', '2005'],
                      ['2004', '2004'],
                      ['2003', '2003'],
                      ['2002', '2002'],
                      ['2001', '2001'],
                      ['2000', '2000'],
                      ['1999', '1999'],
                      ['1998', '1998'],
                      ['1997', '1997'],
                      ['1996', '1996'],
                      ['1995', '1995'],
                      ['1994', '1994'],
                      ['1993', '1993'],
                      ['1992', '1992'],
                      ['1991', '1991'],
                      ['1990', '1990'],
                      ['1989', '1989'],
                      ['1988', '1988'],
                      ['1987', '1987'],
                      ['1986', '1986'],
                      ['1985', '1985'],
                      ['1984', '1984'],
                      ['1983', '1983'],
                      ['1982', '1982'],
                      ['1981', '1981'],
                      ['1980', '1980'],
                      ['1979', '1979'],
                      ['1978', '1978'],
                      ['1977', '1977'],
                      ['1976', '1976'],
                      ['1975', '1975'],
                      ['1974', '1974'],
                      ['1973', '1973'],
                      ['1972', '1972'],
                      ['1971', '1971'],
                      ['1970', '1970'],
                      ['1969', '1969'],
                      ['1968', '1968'],
                      ['1967', '1967'],
                      ['1966', '1966'],
                      ['1965', '1965'],
                      ['1964', '1964'],
                      ['1963', '1963'],
                      ['1962', '1962'],
                      ['1961', '1961'],
                      ['1960', '1960'],
                      ['1959', '1959'],
                      ['1958', '1958'],
                      ['1957', '1957'],
                      ['1956', '1956'],
                      ['1955', '1955'],
                      ['1954', '1954'],
                      ['1953', '1953'],
                      ['1952', '1952'],
                      ['1951', '1951'],
                      ['1950', '1950'],
                      ['1949', '1949'],
                      ['1948', '1948'],
                      ['1947', '1947'],
                      ['1946', '1946'],
                      ['1945', '1945'],
                      ['1944', '1944'],
                      ['1943', '1943'],
                      ['1942', '1942'],
                      ['1941', '1941'],
                      ['1940', '1940'],
                      ['1939', '1939'],
                      ['1938', '1938'],
                      ['1937', '1937'],
                      ['1936', '1936'],
                      ['1935', '1935'],
                      ['1934', '1934'],
                      ['1933', '1933'],
                      ['1932', '1932'],
                      ['1931', '1931'],
                      ['1930', '1930'],
                      ['1929', '1929'],
                      ['1928', '1928'],
                      ['1927', '1927'],
                      ['1926', '1926'],
                      ['1925', '1925'],
                      ['1924', '1924'],
                      ['1923', '1923'],
                      ['1922', '1922'],
                      ['1921', '1921'],
                      ['1920', '1920'],
                      ['1919', '1919'],
                      ['1918', '1918'],
                      ['1917', '1917'],
                      ['1916', '1916'],
                      ['1915', '1915'],
                      ['1914', '1914'],
                      ['1913', '1913'],
                      ['1912', '1912'],
                      ['1911', '1911'],
                      ['1910', '1910'],
                      ['1909', '1909'],
                      ['1908', '1908'],
                      ['1907', '1907'],
                      ['1906', '1906'],
                      ['1905', '1905']
                    ]}
                    onChange={onBirthdayYearChange}
                  />
                </div>
              </div>
              <div>
                <h6 className='mdc-typography--subtitle2'>Anniversary</h6>
                <div className='fd-date'>
                  <MDCSelect
                    label='Month'
                    value={anniversaryMonth}
                    options={[
                      ['', ''],
                      ['01', 'January'],
                      ['02', 'February'],
                      ['03', 'March'],
                      ['04', 'April'],
                      ['05', 'May'],
                      ['06', 'June'],
                      ['07', 'July'],
                      ['08', 'August'],
                      ['09', 'September'],
                      ['10', 'October'],
                      ['11', 'November'],
                      ['12', 'December']
                    ]}
                    onChange={onAnniversaryMonthChange}
                  />
                  <MDCSelect
                    label='Day'
                    value={anniversaryDayOfMonth}
                    options={[
                      ['', ''],
                      ['01', '01'],
                      ['02', '02'],
                      ['03', '03'],
                      ['04', '04'],
                      ['05', '05'],
                      ['06', '06'],
                      ['07', '07'],
                      ['08', '08'],
                      ['09', '09'],
                      ['10', '10'],
                      ['11', '11'],
                      ['12', '12'],
                      ['13', '13'],
                      ['14', '14'],
                      ['15', '15'],
                      ['16', '16'],
                      ['17', '17'],
                      ['18', '18'],
                      ['19', '19'],
                      ['20', '20'],
                      ['21', '21'],
                      ['22', '22'],
                      ['23', '23'],
                      ['24', '24'],
                      ['25', '25'],
                      ['26', '26'],
                      ['27', '27'],
                      ['28', '28'],
                      ['29', '29'],
                      ['30', '30'],
                      ['31', '31']
                    ]}
                    onChange={onAnniversaryDayOfMonthChange}
                  />
                  <MDCSelect
                    label='Year'
                    value={anniversaryYear}
                    options={[
                      ['', ''],
                      ['2022', '2022'],
                      ['2021', '2021'],
                      ['2020', '2020'],
                      ['2019', '2019'],
                      ['2018', '2018'],
                      ['2017', '2017'],
                      ['2016', '2016'],
                      ['2015', '2015'],
                      ['2014', '2014'],
                      ['2013', '2013'],
                      ['2012', '2012'],
                      ['2011', '2011'],
                      ['2010', '2010'],
                      ['2009', '2009'],
                      ['2008', '2008'],
                      ['2007', '2007'],
                      ['2006', '2006'],
                      ['2005', '2005'],
                      ['2004', '2004'],
                      ['2003', '2003'],
                      ['2002', '2002'],
                      ['2001', '2001'],
                      ['2000', '2000'],
                      ['1999', '1999'],
                      ['1998', '1998'],
                      ['1997', '1997'],
                      ['1996', '1996'],
                      ['1995', '1995'],
                      ['1994', '1994'],
                      ['1993', '1993'],
                      ['1992', '1992'],
                      ['1991', '1991'],
                      ['1990', '1990'],
                      ['1989', '1989'],
                      ['1988', '1988'],
                      ['1987', '1987'],
                      ['1986', '1986'],
                      ['1985', '1985'],
                      ['1984', '1984'],
                      ['1983', '1983'],
                      ['1982', '1982'],
                      ['1981', '1981'],
                      ['1980', '1980'],
                      ['1979', '1979'],
                      ['1978', '1978'],
                      ['1977', '1977'],
                      ['1976', '1976'],
                      ['1975', '1975'],
                      ['1974', '1974'],
                      ['1973', '1973'],
                      ['1972', '1972'],
                      ['1971', '1971'],
                      ['1970', '1970'],
                      ['1969', '1969'],
                      ['1968', '1968'],
                      ['1967', '1967'],
                      ['1966', '1966'],
                      ['1965', '1965'],
                      ['1964', '1964'],
                      ['1963', '1963'],
                      ['1962', '1962'],
                      ['1961', '1961'],
                      ['1960', '1960'],
                      ['1959', '1959'],
                      ['1958', '1958'],
                      ['1957', '1957'],
                      ['1956', '1956'],
                      ['1955', '1955'],
                      ['1954', '1954'],
                      ['1953', '1953'],
                      ['1952', '1952'],
                      ['1951', '1951'],
                      ['1950', '1950'],
                      ['1949', '1949'],
                      ['1948', '1948'],
                      ['1947', '1947'],
                      ['1946', '1946'],
                      ['1945', '1945'],
                      ['1944', '1944'],
                      ['1943', '1943'],
                      ['1942', '1942'],
                      ['1941', '1941'],
                      ['1940', '1940'],
                      ['1939', '1939'],
                      ['1938', '1938'],
                      ['1937', '1937'],
                      ['1936', '1936'],
                      ['1935', '1935'],
                      ['1934', '1934'],
                      ['1933', '1933'],
                      ['1932', '1932'],
                      ['1931', '1931'],
                      ['1930', '1930'],
                      ['1929', '1929'],
                      ['1928', '1928'],
                      ['1927', '1927'],
                      ['1926', '1926'],
                      ['1925', '1925'],
                      ['1924', '1924'],
                      ['1923', '1923'],
                      ['1922', '1922'],
                      ['1921', '1921'],
                      ['1920', '1920'],
                      ['1919', '1919'],
                      ['1918', '1918'],
                      ['1917', '1917'],
                      ['1916', '1916'],
                      ['1915', '1915'],
                      ['1914', '1914'],
                      ['1913', '1913'],
                      ['1912', '1912'],
                      ['1911', '1911'],
                      ['1910', '1910'],
                      ['1909', '1909'],
                      ['1908', '1908'],
                      ['1907', '1907'],
                      ['1906', '1906'],
                      ['1905', '1905']
                    ]}
                    onChange={onAnniversaryYearChange}
                  />
                </div>
              </div>
              <MDCTextField value={company} label='Company' onChange={onCompanyChange} style={{ margin: '1em 0 0' }} />
              <MDCTextField value={address} label='Address' onChange={onAddressChange} style={{ margin: '1em 0 0' }} />
              <MDCTextField value={address2} label='Address 2' onChange={onAddress2Change} style={{ margin: '1em 0 0' }} />
              <MDCTextField value={city} label='City' onChange={onCityChange} style={{ margin: '1em 0 0' }} />
              <MDCTextField value={stateProvince} label='State' onChange={onStateProvinceChange} style={{ margin: '1em 0 0' }} />
              <MDCTextField value={zip} label='Postal Code' onChange={onZipChange} style={{ margin: '1em 0 0' }} />
              <MDCTextField value={phone} label='Phone' onChange={onPhoneChange} style={{ margin: '1em 0 0' }} />
              <MDCTextField value={mobilePhone} label='Mobile' onChange={onMobilePhoneChange} style={{ margin: '1em 0 0' }} />
              <MDCTextField value={fax} label='Fax' onChange={onFaxChange} style={{ margin: '1em 0 0' }} />
              <MDCSelect
                label='Gender'
                value={gender}
                options={[
                  ['', ''],
                  ['male', 'Male'],
                  ['female', 'Female']
                ]}
                onChange={onGenderChange}
                style={{ width: '100%', margin: '1em 0 0' }}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'customFieldsPanel'}
            onChange={handleExpandedChange('customFieldsPanel')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='customFieldsPanelbh-content'
              id='customFieldsPanelbh-header'
            >
              <Typography className={classes.heading}>
                Custom Fields
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <div>
                {customFields
                  ? customFields.length
                    ? customFields.map(renderCustomField)
                    : 'You have not created any custom fields.'
                  : <MDCCircularProgress>Please wait...</MDCCircularProgress>}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'listsPanel'}
            onChange={handleExpandedChange('listsPanel')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='listsPanelbh-content'
              id='listsPanelbh-header'
            >
              <Typography className={classes.heading}>
                Lists
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.listsExpansionPanelDetails}>
              {lists
                ? (
                  <>
                    <div>
                      <MDCCheckbox
                        id='listIds-all'
                        checked={numListIds === lists.length}
                        indeterminate={numListIds > 0 && numListIds < lists.length}
                        onChange={onToggleAllLists}
                        label='All'
                        title='Toggle all lists'
                      />
                    </div>
                    {lists.map(renderList)}
                  </>
                )
                : <MDCCircularProgress>Please wait...</MDCCircularProgress>}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <div className='panelBottomActions' style={{ flex: 0 }}>
          <div className='panelBottomButtons'>
            <MDCButton label='Add' onClick={handleAddContact} />
          </div>
          <div className='panelBottomIcons'>
            <Tooltip title='Add Multiple Contacts'>
              <button ref={groupAddEl} className='mdc-icon-button' onClick={navigateToMultipleContactAddPage}><GroupAddIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
            <Tooltip title='Configure Widget'>
              <button ref={configureEl} className='mdc-icon-button' onClick={onConfigure}><SettingsIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
            <Tooltip title='Remove Widget'>
              <button ref={removeEl} className='mdc-icon-button' onClick={onRemove}><DeleteIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
          </div>
        </div>
      </div>
      <ConfigureModal
        close={closeConfigureModal}
        isOpen={isConfigureModalOpen}
        saveConfiguration={saveConfiguration}
        settings={settings}
        title={title}
      />
    </div>
  )
}

AddContactWidget.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  settings: PropTypes.shape(),
  title: PropTypes.string
}

const MemoizedAddContactWidget = memo(AddContactWidget)

export default MemoizedAddContactWidget
