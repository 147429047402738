import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import Tooltip from '../MUIComponents/Tooltip'

const MDCRadio = ({ checked, id, label, name, onChange, style, title, value }) => {
  const rootEl = useRef(null)
  const radioEl = useRef(null)

  useEffect(() => {
    if (rootEl.current && radioEl.current) {
      const el = radioEl.current
      const formFieldEl = rootEl.current

      formFieldEl.MDCFormField = new global.mdc.formField.MDCFormField(formFieldEl)
      el.MDCRadio = formFieldEl.MDCFormField.input = new global.mdc.radio.MDCRadio(el)

      return function cleanup () {
        el.MDCRadio.destroy()
        formFieldEl.MDCFormField.destroy()

        delete el.MDCRadio
        delete formFieldEl.MDCFormField
      }
    }
  }, [])

  const effectiveId = id !== undefined ? id : name !== undefined && value !== undefined ? `${name}-${value}` : (name || value)
  const content = (
    <div className='mdc-form-field' style={style || null}>
      <div ref={radioEl} className='mdc-radio'>
        <input type='radio' className='mdc-radio__native-control' id={effectiveId} name={name} value={value} onChange={onChange} checked={checked} />
        <div className='mdc-radio__background'>
          <div className='mdc-radio__outer-circle' />
          <div className='mdc-radio__inner-circle' />
        </div>
      </div>
      <label htmlFor={effectiveId}>{label}</label>
    </div>
  )
  return title ? (
    <Tooltip title={title}>
      {content}
    </Tooltip>
  ) : content
}

MDCRadio.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.shape(),
  title: PropTypes.string,
  value: PropTypes.string
}

export default MDCRadio
