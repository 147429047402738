import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import MDCCircularProgress from './CircularProgress'

const MDCButton = ({ disabled, label, style, type, onClick }) => {
  const rootEl = useRef(null)

  useEffect(() => {
    const el = rootEl.current
    if (el) {
      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      return () => {
        el.MDCRipple.destroy()
        delete el.MDCRipple
      }
    }
  }, [])

  let rootClass = 'mdc-button'
  switch (type) {
    case 'raised':
      rootClass += ' mdc-button--raised'
      break
    default:
      break
  }

  return (
    <>
      <button
        ref={rootEl}
        className={rootClass}
        style={{ ...(style || {}), ...(disabled ? { width: 'auto' } : {}) }}
        disabled={disabled}
        onClick={onClick}
      >
        <span className='mdc-button__label'>{`${label}${disabled ? 'ing' : ''}`}</span>
      </button>
      {disabled ? <div style={{ margin: '1em 1em 0' }}><MDCCircularProgress /></div> : null}
    </>
  )
}

MDCButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  type: PropTypes.string,
  onClick: PropTypes.func
}

export default MDCButton
