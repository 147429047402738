import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import Tooltip from '../MUIComponents/Tooltip'

const MDCMenu = ({ buttonIcon: Icon, buttonRef, buttonTitle, children }) => {
  const rootEl = useRef(null)
  const buttonEl = useRef(null)

  useEffect(() => {
    const el = rootEl.current
    if (buttonRef) {
      buttonRef.current = buttonEl.current
    }
    const button = buttonEl.current

    const onClick = () => {
      if (!el.MDCMenu.open) {
        el.MDCMenu.open = true
      }
    }

    el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
    el.MDCMenu = new global.mdc.menu.MDCMenu(el)
    el.MDCMenu.setAnchorElement(button.parentNode)
    el.MDCMenu.setAnchorCorner(global.mdc.menu.Corner.BOTTOM_LEFT)
    el.MDCMenu.setIsHoisted(true)

    button.addEventListener('click', onClick, { passive: true })

    return () => {
      el.MDCRipple.destroy()
      delete el.MDCRipple

      el.MDCMenu.open = false
      el.MDCMenu.destroy()
      delete el.MDCMenu

      button.removeEventListener('click', onClick)
    }
  }, [buttonRef])

  const portal = createPortal(
    <div ref={rootEl} className='mdc-menu mdc-menu-surface'>
      <nav className='mdc-list' role='menu' aria-hidden='true' aria-orientation='vertical' tabIndex='-1'>
        {children}
      </nav>
    </div>,
    document.body
  )

  return (
    <>
      <Tooltip title={buttonTitle}>
        <button ref={buttonEl} className='mdc-icon-button'><Icon className='mdc-icon-button__icon' /></button>
      </Tooltip>
      {portal}
    </>
  )
}

MDCMenu.propTypes = {
  buttonIcon: PropTypes.shape(),
  buttonRef: PropTypes.any,
  buttonTitle: PropTypes.string,
  children: PropTypes.node
}

export default MDCMenu
