import { combineReducers } from 'redux'
import application from './application'
import user from './user'
import reports from './reports'
import lists from './lists'
import customFields from './customFields'
import verifiedEmailAddresses from './verifiedEmailAddresses'

const createRootReducer = () => combineReducers({
  application,
  user,
  reports,
  lists,
  customFields,
  verifiedEmailAddresses
})

export default createRootReducer
