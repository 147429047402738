import PropTypes from 'prop-types'
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import SettingsIcon from '@material-ui/icons/Settings'

import ConfigureModal from './utilities/ConfigureModal'

import MDCButton from '../../MDCComponents/Button'
import MDCCircularProgress from '../../MDCComponents/CircularProgress'

import Tooltip from '../../MUIComponents/Tooltip'

import AnimatedInfinity from '../../SVGComponents/AnimatedInfinity'

import DragHandleContext from '../../UtilityComponents/DragHandleContext'

import usePricingPlanLimitsGuage from '../../../hooks/usePricingPlanLimitsGuage'

import { appFontStyle } from '../../../utilities/constants'

import WidgetEventHandlerContext from './utilities/WidgetEventHandlerContext'

const navigateToUpgradePage = event => {
  if (event) {
    event.preventDefault()
  }

  global.location.href = '/client_admin_billing.jsp?function=upgrade'

  return false
}

const PricingPlanWidget = props => {
  const { children, dialog, id, settings, title, userId } = props

  const { current: widgetEventHandler } = useContext(WidgetEventHandlerContext)

  const drag = useContext(DragHandleContext)

  const [contactLimitsContainerEl, emailLimitsContainerEl, {
    accessee,
    accesseeEmailSendingLimit,
    accesseeMaxActiveContacts,
    isExpandedChartModalOpen,
    closeExpandedChartModal,
    expandChart,
    expandEl
  }] = usePricingPlanLimitsGuage({
    dialog,
    settings,
    userId
  })

  const removeEl = useRef(null)
  useEffect(() => {
    if (removeEl.current) {
      const el = removeEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const configureEl = useRef(null)
  useEffect(() => {
    if (configureEl.current) {
      const el = configureEl.current

      el.MDCRipple = new global.mdc.ripple.MDCRipple(el)
      el.MDCRipple.unbounded = true
      return function cleanup () {
        el.MDCRipple.destroy()
      }
    }
  })

  const [isConfigureModalOpen, setConfigureModalOpen] = useState(false)
  const closeConfigureModal = useCallback(() => setConfigureModalOpen(false), [setConfigureModalOpen])
  const saveConfiguration = useCallback((newSettings, changed) => {
    if (changed) {
      widgetEventHandler({
        type: 'configure',
        id,
        settings: newSettings
      })
    }

    setConfigureModalOpen(false)
  }, [id, setConfigureModalOpen, widgetEventHandler])

  const onConfigure = useCallback(() => {
    setConfigureModalOpen(true)
  }, [setConfigureModalOpen])

  const onRemove = useCallback(() => {
    widgetEventHandler({
      type: 'remove',
      id
    })
  }, [id, widgetEventHandler])

  return (
    <div className='panel' style={{ willChange: 'transform', ...(dialog ? { display: 'flex', flexDirection: 'column', width: '100%', height: '100%' } : {}) }}>
      <h2>{title}</h2>
      <div className='panelContent' style={{ minHeight: '548px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div className='panelActions' style={dialog ? { top: 0, marginBottom: 0 } : null}>
          {!dialog &&
            <Tooltip title='Move Widget'>
              <span ref={drag} style={{ cursor: 'move' }}><DragIndicatorIcon /></span>
            </Tooltip>}
        </div>
        {children}
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {!accessee &&
            <div style={{ flex: 1 }}>
              <MDCCircularProgress>Please wait...</MDCCircularProgress>
            </div>}
          {accessee &&
            <>
              <div ref={contactLimitsContainerEl} style={accesseeMaxActiveContacts >= 0 ? { flex: '1 1 402px', minHeight: '237px' } : { flex: '1 1 402px', minHeight: '237px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'center', paddingTop: '7px' }}>
                {accesseeMaxActiveContacts < 0 &&
                  <>
                    <span style={{
                      ...appFontStyle,
                      flex: '0 0',
                      fontSize: '1.2rem',
                      fontWeight: 500
                    }}
                    >
                        Unlimited Contacts
                    </span>
                    <div style={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    >
                      <AnimatedInfinity
                        delay={dialog ? 225 : 0}
                        width='60%'
                        height='60%'
                        stroke='#5EC442'
                      />
                    </div>
                  </>}
              </div>
              <div ref={emailLimitsContainerEl} style={accesseeEmailSendingLimit >= 0 ? { flex: '1 1 402px', minHeight: '237px' } : { flex: '1 1 402px', minHeight: '237px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'center', paddingTop: '7px' }}>
                {accesseeEmailSendingLimit < 0 &&
                  <>
                    <span style={{
                      ...appFontStyle,
                      flex: '0 0',
                      fontSize: '1.2rem',
                      fontWeight: 500
                    }}
                    >
                        Unlimited Emails Sent Per Month
                    </span>
                    <div style={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    >
                      <AnimatedInfinity
                        delay={dialog ? 225 : 0}
                        width='60%'
                        height='60%'
                        stroke='#5EC442'
                      />
                    </div>
                  </>}
              </div>
            </>}
        </div>
        <div className='panelBottomActions' style={{ flex: 0 }}>
          <div className='panelBottomButtons'>
            <MDCButton label='Upgrade' onClick={navigateToUpgradePage} />
          </div>
          <div className='panelBottomIcons'>
            {accessee && !dialog &&
              <Tooltip title='Expand'>
                <button ref={expandEl} className='mdc-icon-button' onClick={expandChart}><FullscreenIcon className='mdc-icon-button__icon' /></button>
              </Tooltip>}
            <Tooltip title='Configure Widget'>
              <button ref={configureEl} className='mdc-icon-button' onClick={onConfigure}><SettingsIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
            <Tooltip title='Remove Widget'>
              <button ref={removeEl} className='mdc-icon-button' onClick={onRemove}><DeleteIcon className='mdc-icon-button__icon' /></button>
            </Tooltip>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth='xl'
        onClose={closeExpandedChartModal}
        open={isExpandedChartModalOpen}
        PaperProps={{
          style: {
            width: '100%',
            height: '100%'
          }
        }}
      >
        <Tooltip title='Close'>
          <div style={{ position: 'absolute', top: '8px', right: '8px', zIndex: 1, cursor: 'pointer' }} onClick={closeExpandedChartModal}><CloseIcon /></div>
        </Tooltip>
        <MemoizedPricingPlanWidget
          {...props}
          dialog
        />
      </Dialog>
      <ConfigureModal
        close={closeConfigureModal}
        isOpen={isConfigureModalOpen}
        saveConfiguration={saveConfiguration}
        settings={settings}
        title={title}
      />
    </div>
  )
}

PricingPlanWidget.propTypes = {
  children: PropTypes.node,
  dialog: PropTypes.bool,
  id: PropTypes.string,
  settings: PropTypes.shape(),
  title: PropTypes.string,
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
}

const MemoizedPricingPlanWidget = memo(PricingPlanWidget)

export default MemoizedPricingPlanWidget
