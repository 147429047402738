import {
  FETCH_LISTS,
  FETCHING_LISTS,
  SET_LISTS
} from '../constants/lists'

export function fetchLists () {
  return {
    type: FETCH_LISTS
  }
}

export function fetchingLists () {
  return {
    type: FETCHING_LISTS
  }
}

export function setLists (lists) {
  return {
    type: SET_LISTS,
    lists
  }
}
