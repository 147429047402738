export const FETCH_USER = '@@firedrum/FETCH_USER'
export const FETCHING_USER = '@@firedrum/FETCHING_USER'
export const SAVE_USER = '@@firedrum/SAVE_USER'
export const SAVING_USER = '@@firedrum/SAVING_USER'
export const SET_USER = '@@firedrum/SET_USER'

export const FETCH_USER_DASHBOARD = '@@firedrum/FETCH_USER_DASHBOARD'
export const FETCHING_USER_DASHBOARD = '@@firedrum/FETCHING_USER_DASHBOARD'
export const SAVE_USER_DASHBOARD = '@@firedrum/SAVE_USER_DASHBOARD'
export const SET_USER_DASHBOARD = '@@firedrum/SET_USER_DASHBOARD'
export const SET_USER_DASHBOARD_ORDER = '@@firedrum/SET_USER_DASHBOARD_ORDER'
